import React, { useState, useEffect, useRef } from 'react';
import { Users, FileQuestion, Target, Clock, Pin, UserPlus, ChevronDown, ChevronRight, Search, PinOff, FolderPlus } from 'lucide-react';
import { useRecoilState } from 'recoil';
//import { logEventsState } from '../../store/atoms';
import PromptBar from './PromptBar';
import Sidebar_v2 from '../sidebar/Sidebar_v2';

import { pinnedQueriesState, tableLoadingState } from '../../store/atoms';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { logEvent } from '../../cloudwatchLogger';
import config from '../../config';
import useLambda from '../hooks/useLambda';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import { useMediaQuery } from '@mui/material';
import { conversationIdState } from '../../store/atoms';

// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

  const TargetedSearch = ({setSelectedQuery}) => {
    //const [selectedQuery, setSelectedQuery] = useState('');
    const pinnedQueries = useRecoilValue(pinnedQueriesState);
    const setPinnedQueries = useSetRecoilState(pinnedQueriesState);
    const [managedQueries, setManagedQueries] = useState([]);
    const [tenantGroup, setTenantGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const [jwtToken, setJwtToken] = useState('');
    const [sub, setSub] = useState('');
    const { decodeToken, getJWT } = useLambda();
    const pageRef = useRef(null);
    const [tableLoading, setTableLoading] = useRecoilState(tableLoadingState);
    const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isTenantAdmin, setIsTenantAdmin] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    const [conversationId, setConversationId] = useRecoilState(conversationIdState);

    
    useEffect(() => {
      const fetchTokenAndSub = async () => {
        try {
          const token = await getJWT();
          setJwtToken(token);
          const decodedToken = decodeToken(token);
          const tenantgroup = decodedToken['cognito:groups'];
          const cognitoRoles = decodedToken['cognito:roles'];
          let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
          if (cognitoRoles) {
            setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
            setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
          }
          setTenantGroup(filteredGroups);
          setSub(decodedToken['sub']);
        } catch (error) {
          console.error('Failed to fetch token:', error);
          logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
        }
      };
  
      fetchTokenAndSub();
      setConversationId('');
    }, []);
  
    useEffect(() => {
      const fetchSavedQueries = async () => {
        if (!sub) return;
        try {
          const lambda = new AWS.Lambda();
          const data = await invokeLambda('getSavedQueries', { 'created_by':sub }, lambda, lambdaFunctionName);
          setManagedQueries(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching saved queries:', error);
          setLoading(false);
        }
      };
  
      const fetchUserSavedQueries = async () => {
        //console.log("inside fetch user saved queries");
        if (!tenantGroup) return;
        try {
          //console.log(`tenant group inside fetch user group is : ${tenantGroup}`);
          const lambda = new AWS.Lambda();
          const data = await invokeLambda('getUserSavedQueries', { 'groupname':tenantGroup[0] }, lambda, lambdaFunctionName);
          setManagedQueries(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching saved queries:', error);
          setLoading(false);
        }
      };
  
      if (isSuperAdmin || isTenantAdmin) {
        fetchSavedQueries();
      } else if (tenantGroup.length > 0) {
        fetchUserSavedQueries();
      }
    }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);
  
// Function to handle dynamic transformation
const transformDatabaseResults = (dbResults) => {
  const transformedResults = dbResults.map((result, index) => ({
    text: result.query_name,
    isPinned: true,
    id: result.id
  }));
  setPinnedQueries(transformedResults);
};
//console.log('pinned queries are: '+JSON.stringify(pinnedQueries));

  const handleQueryClick = (query) => {
    setSelectedQuery(query);
    logEvent({ eventType: 'querySelected', details: `Selected query: ${query}` });
  };

  const handlePinToggle = (id) => {
    const queryToToggle = pinnedQueries.find(query => query.id === id);
    if (!queryToToggle) return;
    if (queryToToggle.isPinned) {
      // Unpin the query and call delete function
      deleteQuery(id);
    }
  }

  const deleteQuery = async (id) => {
    // console.log(`Deleting query with id: ${id}`);
  
    const updatedQueries = pinnedQueries.filter((query) => query.id !== id);
    setPinnedQueries(updatedQueries);
    const lambda = new AWS.Lambda();
    const data = await invokeLambda('deletePrompts',{ 'id':id }, lambda, lambdaFunctionName);
        // setAvailableQueries(data);
    //console.log('print all prompts'+ JSON.stringify(data));
  
    setTimeout(() => {
      logEvent({ eventType: 'queryUnpinned', details: `Unpinned query with id: ${id}` });
    }, 0);
  };

  const scrollToBottom = () => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  //console.log('managed queries are: '+managedQueries);

  const pinnedQueryCards = pinnedQueries.filter(query => query.isPinned);

  useEffect(() => {
    const fetchPrompts = async () => {
      //console.log("this is sub:" + sub)
      if (!sub) return;
      try {
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getPrompts',{ 'created_by':sub }, lambda, lambdaFunctionName);
        // setAvailableQueries(data);
        //console.log('print all prompts'+ JSON.stringify(data));
        transformDatabaseResults(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching queries:', error);
        setLoading(false);
      }
    };
    fetchPrompts()
    }, [sub]);

    function SuggestionCard({ data, handleQueryClick, handlePinToggle }) {
      return (
        <div
          className="flex items-start gap-[40px] self-stretch w-[323px] h-[88px] p-5 bg-[#F2F2F2] rounded-[14px] border border-solid border-[#D9D9D9] shadow-[2px_2px_8px_0px_rgba(0,0,0,0.10)] cursor-pointer transition-all duration-300 hover:bg-[#FDF7E7] hover:border-[#EBAE12] group"
          onClick={() => handleQueryClick(data.text)}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePinToggle(data.id);
            }}
            className="focus:outline-none p-0 w-6 h-6 flex items-center justify-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 21L8 16" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <p className="font-inter text-[#8E8E8E] group-hover:text-black flex-grow">
            {data.text}
          </p>
        </div>
      );
    }
  
    function EmptyQueryCard({ type }) {
      return (
        <div 
          className="flex items-start gap-[23px] flex-1 self-stretch"
          style={{
            display: 'flex',
            padding: '21px',
            borderRadius: '14px',
            background: 'var(--Foundation-Primary-black-Light, #F2F2F2)'
          }}
        >
          <div 
            className="flex"
            style={{
              width: '24px',
              height: '24px',
              padding: '2px'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 21L8 16" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <div 
            className="flex-1 text-[#8E8E8E]"
            style={{
              fontFamily: 'Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '120%',
              padding: '20 23px'
            }}
          >
            {type === 'pinned' ? 'No pinned query' : 'No managed query'}
          </div>
        </div>
      );
    }

    if(tableLoading) {
      return (
        <div className="flex">
          <div className='relative'>
            <img
              src="update_table_loader.svg"
              alt="Loading..."
              style={{
                width: '869px',
                height: '434px'
              }}
            />
          </div>
        </div>
      );
    };
  
    return (
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex flex-col items-start gap-5 self-stretch">
            <p className="text-black font-inter text-[1.25rem] font-bold leading-[120%] self-stretch">Pinned query</p>
            <div className="flex items-start gap-10 self-stretch">
              {pinnedQueries.length === 0 ? ( 
                <div className="flex p-5 items-start gap-[1.4375rem] flex-1 self-stretch rounded-[0.875rem] bg-[#F2F2F2]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 21L8 16" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p className="flex-1 flex-shrink-0 text-[#8E8E8E] font-inter text-[1.25rem] font-normal leading-[120%]">No pinned query</p>
                </div>
              ) : ( 
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full">
                  {pinnedQueries.map((query) => (
                    <div 
                      key={query.id} 
                      className="flex p-5 items-start gap-[1.4375rem] flex-1 self-stretch rounded-[0.875rem] bg-[#F2F2F2] cursor-pointer hover:bg-[#FDF7E7] group"
                      onClick={() => setSelectedQuery(query.text)}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinToggle(query.id);
                        }}
                        className="group focus:outline-none p-0 w-6 h-6 flex items-center justify-center"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M3 21L8 16" className="stroke-[#8E8E8E] group-hover:stroke-[#141B34]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" className="stroke-[#8E8E8E] group-hover:stroke-[#141B34]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>     
                      </button>
                      <p className="flex-1 text-[#8E8E8E] font-inter text-[1.25rem] font-normal leading-[120%] group-hover:text-black">{query.text}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start gap-5 self-stretch">
            <p className="text-black font-inter text-[1.25rem] font-bold leading-[120%] self-stretch">Managed Queries</p>
            <div className="flex items-start gap-10 self-stretch">
              {managedQueries.length === 0 ? ( 
                <div className="flex p-5 items-start gap-[1.4375rem] flex-1 self-stretch rounded-[0.875rem] bg-[#F2F2F2]">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 21L8 16" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p className="flex-1 flex-shrink-0 text-[#8E8E8E] font-inter text-[1.25rem] font-normal leading-[120%]">No managed query</p>
                </div>
              ) : ( 
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full max-w-full overflow-hidden">
                  {managedQueries.map((query) => (
                    <div 
                      key={query.id} 
                      className="flex p-5 items-start gap-[1.4375rem] self-stretch rounded-[0.875rem] bg-[#F2F2F2] max-w-full truncate cursor-pointer hover:bg-[#FDF7E7] group"
                      onClick={() => setSelectedQuery(query.query_name)}
                    >
                      {/* <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // handlePinToggle(query.id);
                        }}
                        className="group focus:outline-none p-0 w-6 h-6 flex items-center justify-center"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M3 21L8 16" className="stroke-[#8E8E8E] group-hover:stroke-[#141B34]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M13.2585 18.8714C9.51516 18.0215 5.97844 14.4848 5.12853 10.7415C4.99399 10.1489 4.92672 9.85266 5.12161 9.37197C5.3165 8.89129 5.55457 8.74255 6.03071 8.44509C7.10705 7.77265 8.27254 7.55888 9.48209 7.66586C11.1793 7.81598 12.0279 7.89104 12.4512 7.67048C12.8746 7.44991 13.1622 6.93417 13.7376 5.90269L14.4664 4.59604C14.9465 3.73528 15.1866 3.3049 15.7513 3.10202C16.316 2.89913 16.6558 3.02199 17.3355 3.26771C18.9249 3.84236 20.1576 5.07505 20.7323 6.66449C20.978 7.34417 21.1009 7.68401 20.898 8.2487C20.6951 8.8134 20.2647 9.05346 19.4039 9.53358L18.0672 10.2792C17.0376 10.8534 16.5229 11.1406 16.3024 11.568C16.0819 11.9955 16.162 12.8256 16.3221 14.4859C16.4399 15.7068 16.2369 16.88 15.5555 17.9697C15.2577 18.4458 15.1088 18.6839 14.6283 18.8786C14.1477 19.0733 13.8513 19.006 13.2585 18.8714Z" className="stroke-[#8E8E8E] group-hover:stroke-[#141B34]" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>     
                      </button> */}
                      <p className="flex-1 text-[#8E8E8E] font-inter text-[1.25rem] font-normal leading-[120%] overflow-hidden text-ellipsis whitespace-nowrap group-hover:text-black">
                    {query.query_name}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>      
  );
};
  
export default TargetedSearch;
