import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { Upload, FileDown, Download, X, Search, Users, FileQuestion, Target, Heart, Bell, Sun, HelpCircle, Settings, LogOut, Folder, ArrowUp, FileText, Menu } from 'lucide-react';
import * as XLSX from 'xlsx';
import { ClipLoader } from 'react-spinners';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Modal, Tooltip, Checkbox, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import useLambda from '../hooks/useLambda';
import { importNotificationsState } from '../../store/atoms';
import { fetchAuthSession } from 'aws-amplify/auth';
import { logEvent } from '../../cloudwatchLogger';
import AWS from 'aws-sdk';
import {
  jwtTokenState, 
  rolesState,
  tenantGroupState,
  isSuperAdminState,
  isTenantAdminRoleState
} from '../../store/atoms';

import WelcomeHeader from './WelcomeHeader';

const FolderWithArrow = ({ size, color }) => (
  <div className="relative inline-block">
    <Folder size={size} color={color} />
    <ArrowUp 
      size={size * 0.3} 
      color={color} 
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
  </div>
);

const SuccessMessage = ({ message, onClose }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 9999,
        display: 'flex',
        width: '90%', // Changed from fixed 600px to relative width
        maxWidth: '600px', // Added maxWidth for larger screens
        padding: '12px', // Slightly reduced base padding
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        borderRadius: '13px',
        border: '2px solid var(--Foundation-Alert-Green-Normal, #40C5AF)',
        background: 'var(--Foundation-Alert-Green-Light, #ECF9F7)',
        boxShadow: '2px 2px 8px 0px rgba(64, 197, 175, 0.10)',
        fontSize: window.innerWidth < 768 ? '14px' : '16px', // Responsive font size
        margin: '0 auto', // Center horizontally
        wordBreak: 'break-word', // Prevent text overflow
      }}
    >
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        width: '100%', 
        alignItems: 'center',
        padding: window.innerWidth < 768 ? '0 4px' : '0 8px' // Responsive padding
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px',
          fontSize: window.innerWidth < 768 ? '14px' : '16px' // Responsive font size
        }}>
          <img 
            src="/importsuccess.png" 
            alt="Success" 
            style={{ 
              width: window.innerWidth < 768 ? '20px' : '24px', 
              height: window.innerWidth < 768 ? '20px' : '24px' 
            }} 
          />
          <span style={{ fontWeight: 'bold' }}>File Imported Successfully</span>
        </div>
        <button 
          onClick={onClose} 
          style={{ 
            background: 'none', 
            border: 'none', 
            cursor: 'pointer',
            padding: '4px'
          }}
        >
          <X size={window.innerWidth < 768 ? 14 : 16} />
        </button>
      </div>
      <div style={{ 
        padding: window.innerWidth < 768 ? '0 4px' : '0 8px',
        width: '100%'
      }}>
        {message}
      </div>
    </div>
  );
};

const ImportPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showCsvEditor, setShowCsvEditor] = useState(false);
  const [notification, setNotification] = useState("");
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const fileInputRef = useRef(null);
  const [importNotification,setImportNotifications] = useRecoilState(importNotificationsState);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadTitle, setUploadTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [emailOptions, setEmailOptions] = useState({
    resubscribe: false,
    unsubscribe: false
  });
  const [mobileOptions, setMobileOptions] = useState({
    subscribeNew: false,
    unsubscribe: false
  });
  const [overwriteData, setOverwriteData] = useState(false);
  const [confirmOptIn, setConfirmOptIn] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const location = useLocation();
  const { configureLambda, decodeToken, getJWT } = useLambda();
  const [isOpen, setIsOpen] = useState(true);
  const cancelImportRef = useRef(false);
  const [successMessage, setSuccessMessage] = useState("");

  const roles = useRecoilValue(rolesState);
  const tenantGroup = useRecoilValue(tenantGroupState);
  const isSuperAdmin = useRecoilValue(isSuperAdminState);
  const isTenantAdminRole = useRecoilValue(isTenantAdminRoleState);
  const [isHovered, setIsHovered] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const updateNotifications = (success, header, message, timestamp) => {
    setImportNotifications((prevNotifications) => {
      const newNotification = {
        id: prevNotifications.length + 1,
        success,
        title: header,
        description: message,
        timestamp
      };
      const updatedNotifications = [...prevNotifications, newNotification];
      
      // Store in local storage
      localStorage.setItem('importNotifications', JSON.stringify(updatedNotifications));
      
      return updatedNotifications;
    });
  };

  // useEffect(() => {
  //   if (location.state?.success) {
  //     setSuccessMessage(location.state.message);
  //     if (location.state.uploadedFile) {
  //       setUploadedFiles(prevFiles => [...prevFiles, location.state.uploadedFile]);
  //     }
  //   }
  // }, [location]);

  // const handleCloseSuccessMessage = () => {
  //   setSuccessMessage("");
  // };


  const requiredHeaders = [
    'First Name', 'Last Name', 'Personal Email', 'Home City', 'Middle Name', 'Home Street Address','Home State/Province', 
    'Home Zip/Postal', 'Country', 'Employer', 'Occupation', 'Job Title', 'Home Phone',
    'Date Received', 'Type of Contact', 'Designation', 'Period', 'State House District',
    'State Senate District', 'Amount'
    // , 'Contact Type (Remedy PAC)'
  ];

  // const requiredHeaders = [
  //   'First Name', 'Last Name', 
  //   'Home Street Address', 'Home Zip/Postal', 
  //   'Contact Name', 'Amount'
  // ];


  const sanitizeFilename = (filename) => {
    return filename.replace(/\s+/g, '_').toLowerCase();
  };

  const convertToCSV = (data) => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    return XLSX.utils.sheet_to_csv(ws);
  };


  const getFormattedDateTimeUTC = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  // useEffect(() => {
  //   const fetchRoles = async () => {
  //     try {
  //       const jwtToken = await getJWT();
  //       const decodedToken = decodeToken(jwtToken);
  //       const cognitoRoles = Array.isArray(decodedToken['cognito:roles']) ? decodedToken['cognito:roles'] : [];
  //       setRoles(cognitoRoles);
  //       const tenantgroup = decodedToken['cognito:groups'];
  //       //console.log('tenant groups: '+tenantgroup);
  //       let filteredGroups = [];
  //       if (tenantgroup.includes("Tenants")) {
  //         filteredGroups = tenantgroup.filter(group => group !== 'Tenants');
  //       } else if (tenantgroup) {
  //         filteredGroups = [...tenantgroup];
  //       }
  //       setTenantGroup(filteredGroups);
  //     } catch (error) {
  //       console.error('Failed to fetch roles:', error);
  //     }
  //   };

  //   fetchRoles();
  // }, []);

//   const s3 = new AWS.S3({
//     accessKeyId: 'AKIA2EJNKTWOV7X2F4VC',
//     secretAccessKey: '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',
//     // region: 'us-east-1',
//     region: 'us-east-2',
//     correctClockSkew: true,
//     maxRetries: 3,
//     httpOptions: {
//       timeout: 900000  // 15 minutes
//     } 
//   });


  const handleImport = () => {
    fileInputRef.current.click();
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/import2.csv';
    link.download = 'import2.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showNotification('Template downloaded successfully');
  };

  const resetImportState = () => {
    setSelectedFile(null);
    setUploadTitle("");
    setCsvFile(null);
    setHeaders([]);
    setData([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCancelImport = () => {
    setShowCsvEditor(false);
    resetImportState();
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 5000);
  };

  const validateHeaders = (headers) => {
    const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
    const extraHeaders = headers.filter(header => !requiredHeaders.includes(header));
    return { missingHeaders, extraHeaders };
  };

  // const isSuperAdmin = roles.includes('arn:aws:iam::696416640413:role/superAdminRole');
  // const isTenantAdminRole = roles.includes('arn:aws:iam::696416640413:role/tenantAdminRole');
  // const isUserRole = roles.includes('arn:aws:iam::696416640413:role/userRole');

  const handleFileUpload = async (file) => {
    if (!file) return;
  
    const allowedExtensions = /(\.csv|\.xls|\.xlsx|\.xml|\.gsheet)$/i;
    if (!allowedExtensions.exec(file.name)) {
      alert('Please select a CSV, Excel, XML, or Google Sheets file.');
      resetImportState();
      return;
    }
  
    setIsLoading(true);
  
    try {
      let workbook;
      if (file.name.endsWith('.gsheet')) {
        // Handle Google Sheets
        const response = await fetch(`https://docs.google.com/spreadsheets/d/${file.id}/export?format=xlsx`);
        const arrayBuffer = await response.arrayBuffer();
        workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      } else {
        const arrayBuffer = await file.arrayBuffer();
        workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
      }
  
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      const importedHeaders = jsonData[0];
      const { missingHeaders, extraHeaders } = validateHeaders(importedHeaders);
  
      setHeaders(importedHeaders);
      setData(jsonData.slice(1));
      setCsvFile(new File([XLSX.utils.sheet_to_csv(worksheet)], file.name.replace(/\.[^/.]+$/, ".csv"), { type: 'text/csv' }));
      setShowCsvEditor(true);
      showNotification('File imported successfully - import page');
  
    
  
      if (missingHeaders.length > 0 || extraHeaders.length > 0) {
        let message = "Please review the headers:\n";
        if (missingHeaders.length > 0) {
          message += `Missing: ${missingHeaders.join(', ')}\n`;
        }
        if (extraHeaders.length > 0) {
          message += `Extra: ${extraHeaders.join(', ')}`;
        }
        alert(message);
      }
    } catch (error) {
      console.error('Error processing file:', error);
      alert('An error occurred while processing the file. Please try again.');
      resetImportState();
    } finally {
      setIsLoading(false);
    }
  };


  const handleDirectImport = (fileName, headers, data) => {
    // Implement your direct import logic here
    //console.log("Directly importing file:", fileName);
    //console.log("Headers:", headers);
    //console.log("Data:", data);

    // Simulating a successful import
    const importSuccessful = true;  // Replace this with your actual import logic

    if (importSuccessful) {
      updateNotifications(true,'Import Notification', `File "${fileName}" imported successfully`, new Date().getTime());
      alert(`Import successful! File "${fileName}" has been imported.`);
      // setUploadedFiles(prevFiles => [...prevFiles, {
      //   name: fileName,
      //   dateUploaded: new Date().toLocaleDateString(),
      //   progress: 100,
      //   total: `${(data.length * 0.0001).toFixed(2)} MB`, // Estimating file size
      //   email: '-',
      //   mobile: '-'
      // }]);
    } else {
      updateNotifications(false,'Import Notification', `Import failed for file "${fileName}". Please check your file and try again.`, new Date().getTime());
      alert(`Import failed for file "${fileName}". Please try again.`);
    }

    resetImportState();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setFileSelected(true);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileSelection(files[0]);
    }
  };

  const handleHeaderChange = (index, newHeader) => {
    const updatedHeaders = [...headers];
    updatedHeaders[index] = newHeader;
    setHeaders(updatedHeaders);
  };

  const handleAddColumn = () => {
    setHeaders([...headers, '']);
  };

  const handleDeleteColumn = (index) => {
    const updatedHeaders = headers.filter((_, i) => i !== index);
    const updatedData = data.map(row => row.filter((_, i) => i !== index));
    setHeaders(updatedHeaders);
    setData(updatedData);
  };
      // <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      //   <Table>
      //     <TableHead>
      //       <TableRow>
      //         <TableCell>File Name</TableCell>
      //         <TableCell>File Title</TableCell>
      //         <TableCell>Date Uploaded</TableCell>
      //         <TableCell>Progress</TableCell>
      //         <TableCell>Total</TableCell>
      //         {/* <TableCell>Email</TableCell>
      //         <TableCell>Mobile</TableCell> */}
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       {uploadedFiles.map((file, index) => (
      //         <TableRow key={index}>
      //           <TableCell>{file.name}</TableCell>
      //           <TableCell>{file.title}</TableCell>
      //           <TableCell>{file.dateUploaded}</TableCell>
      //           <TableCell>{file.progress}%</TableCell>
      //           <TableCell>{file.total}</TableCell>
      //           {/* <TableCell>{file.email}</TableCell>
      //           <TableCell>{file.mobile}</TableCell> */}
      //         </TableRow>
      //       ))}
      //     </TableBody>
      //   </Table>
      // </TableContainer>
  
  const FileUploadInfo = ({ uploadedFiles }) => {
    return (
      <>
      <div className="flex items-center gap-5 self-stretch py-2.5 px-5 border-b border-[#D9D9D9] bg-white">
        <p className="w-[12.1875rem] text-[#8E8E8E] font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">File Name</p>
        <p className="w-[10.1875rem] text-[#8E8E8E] font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">File Title</p>
        <p className="w-[9.75rem] text-[#8E8E8E] font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">Date Uploaded</p>
        <p className="w-[6.75rem] text-[#8E8E8E] font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">Progress</p>
        <p className="text-[#8E8E8E] font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">Total</p>
      </div>
      
        
            {uploadedFiles.map((file, index) => (
              <div className="flex flex-col items-start self-stretch">
              <div className="flex flex-col items-start gap-2.5 self-stretch p-2.5 
              border-b border-[#D9D9D9] bg-white">
              <div className=" flex justify-between items-center self-stretch p-2.5">
                <p className="w-[12.1875rem] text-black text-base font-normal leading-[120%]">{file.name}</p>
                <p className="w-[10.1875rem] text-black text-base font-normal leading-[120%]">{file.title}</p>
                <p className="w-[9.75rem] text-black text-base font-normal leading-[120%]">{file.dateUploaded}</p>
                <p className="w-[6.75rem] text-black text-base font-normal leading-[120%]">{file.progress}%</p>
                <p className="text-black text-base font-normal leading-[120%]">{file.total}</p>
              </div>
              </div>
              </div>
            ))}
      </>
          );
  };


  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  useEffect(() => {
    // Check localStorage when component mounts
    const storedFileSelected = localStorage.getItem('fileSelected') === 'true';
    setFileSelected(storedFileSelected);

    // Cleanup function to clear localStorage when component unmounts
    return () => {
      localStorage.removeItem('fileSelected');
    };
  }, []);

  const handleFileSelection = (file) => {
    if (file) {
      setSelectedFile(file);
      setFileSelected(true);
      localStorage.setItem('fileSelected', 'true');
    }
  };

  const [isUploading, setIsUploading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(4);

  // const handleUploadButtonClick = () => {
  //   setIsUploading(true);
  //   setUploadProgress(0);
  //   setTimeRemaining(4);
  // };

  const handleUploadButtonClick = () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
    // if (!uploadTitle) {
    //   alert("Please enter an upload title.");
    //   return;
    // }
    setIsUploading(true);
    setUploadProgress(0);
    setTimeRemaining(4);
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const headers = jsonData[0];
        const data = jsonData.slice(1);

        // Navigate to MatchFieldsPage with necessary data
        navigate('/match-fields', {
          state: {
            headers,
            data,
            requiredHeaders,
            uploadTitle,
            fileName: selectedFile.name,
            tenantGroup
          }
        });
      } catch (error) {
        console.error('Error processing file:', error);
        alert('An error occurred while processing the file. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  useEffect(() => {
    let intervalId;
    if (isUploading && uploadProgress < 100) {
      intervalId = setInterval(() => {
        setUploadProgress(prev => {
          const newProgress = prev + 5;
          if (newProgress >= 100) {
            setIsUploading(false);
            clearInterval(intervalId);
            return 100;
          }
          return newProgress;
        });
        setTimeRemaining(prev => Math.max(0, prev - 0.2));
      }, 200);
    }
    return () => clearInterval(intervalId);
  }, [isUploading, uploadProgress]);

  const handlePDFDownload = (pdfPath) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = pdfPath;
    link.target = '_blank';
    link.download = pdfPath.split('/').pop(); // Set the download attribute to the file name

    // Append to the document body
    document.body.appendChild(link);

    // Trigger the click event
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (location.state?.success) {
      // Set the success message directly, ensuring it's formatted correctly
      setSuccessMessage(location.state.message); // This should include <br /> or be formatted as an array
      if (location.state.uploadedFile) {
        setUploadedFiles(prevFiles => {
          const newFile = location.state.uploadedFile;
          const fileExists = prevFiles.some(file => 
            file.name === newFile.name && file.dateUploaded === newFile.dateUploaded
          );
          if (!fileExists) {
            return [...prevFiles, newFile];
          }
          return prevFiles;
        });
      }
      // Clear the success state from the location object
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);
  
  
  useEffect(() => {
    if (successMessage) {
      setShowSuccessMessage(true);
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    setSuccessMessage('');
  };

  const handleFileInputChange = (e) => {
    // Handle file selection logic here
    setFileSelected(e.target.files.length > 0);
  };

  const clearFileSelection = () => {
    setSelectedFile(null);
    setFileSelected(false);
    localStorage.removeItem('fileSelected');
  };

  return (
    <div className="h-screen bg-white overflow-hidden">
      <div className="h-full flex">
        {/* Main content */}
        <div className="flex-1 flex flex-col w-full">   
          <div className="flex-1 w-full">
            <div className="w-full">
              <WelcomeHeader />
            </div>
            
            <main className="h-[calc(100vh-theme(spacing.16))] p-10 flex flex-col gap-10 overflow-y-auto">
              <div className="flex justify-between items-center">
                <div className="flex flex-col items-start gap-1.5">
                  <h2 className="text-xl md:text-2xl font-inter font-bold">Import File</h2>
                  <p className="text-sm md:text-base">Please upload file below to create column in database</p>
                </div>
              </div>
            
              <section className="flex flex-col items-start gap-5">
                  <div className="flex flex-col items-start gap-5 w-[50rem] py-5 border-t border-[#D9D9D9] bg-white">
                    {/* File Upload heading */}
                    <div className="flex flex-col items-start gap-5 w-[50rem]">
                      <div className="flex flex-col items-start gap-2.5 self-stretch">
                        <p className="self-stretch text-black font-inter text-base font-bold leading-tight">File Upload</p>
                        <p className="self-stretch font-['Inter'] text-[0.8125rem] font-normal leading-[120%]" style={{ color: 'var(--foundation-primary-black-light-active, #8E8E8E)' }}>
                          <span>
                            Upload a CSV, XLS, or XLSX file to add to the donor database, with the first row indicating your column names. You will be asked to match your columns to the relevant fields in the database.
                          </span>
                          <br />
                          <br />
                          <span>
                            Important Note: Only the columns that have a mapping will be moved into Findraiser's database. Please make sure you create a relevant mapping for any column that you want imported. Please contact your admin if you cannot find a relevant mapping for a column.
                          </span>
                        </p>
                      </div>    
                    </div>

                    {/*File Upload/Dragging box rounded-lg p-4 md:p-8 text-center cursor-pointer */}
                    <div className={`
                      flex flex-col justify-center items-center w-[50rem] p-6 gap-3 rounded-[0.4375rem]
                      border ${isDragging ? 'border-solid border-[rgba(242,202,100,255)]' : 'border-dashed border-[#EBAE12]'}
                      transition-all duration-300 
                      ${isDragging || isHovered || fileSelected ? 'bg-[#FDF7E7]' : 'bg-white'}
                    `}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      onClick={() => fileInputRef.current.click()}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <img 
                        src="/upload.png"
                        alt="Upload icon"
                        className="w-[2.625rem] h-[2.625rem]"
                      />
                      <div className="flex flex-col items-center gap-2 self-stretch">
                        <div className="flex justify-center items-start gap-1 self-stretch">
                          <p className="text-black font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">
                            Drag your file(s) to start uploading
                          </p>
                        </div>

                        <div className="flex items-center w-[12.5625rem] gap-3">
                          <div className="h-[0.06069rem] flex-1 flex-shrink-0 bg-[#8E8E8E]"></div>
                          <span className="text-center font-['Inter'] text-[0.8125rem] font-normal leading-[120%]"
                                style={{ color: 'var(--foundation-primary-black-light-active, #8E8E8E)' }}
                          > 
                            OR
                          </span>
                          <div className="h-[0.06069rem] flex-1 flex-shrink-0 bg-[#8E8E8E]"></div>
                        </div>

                        <button 
                          className="flex py-1.5 px-3 justify-center items-center gap-1 
                                    bg-white text-[#EBAE12] font-['Inter'] font-bold text-base leading-[120%] 
                                    rounded-[0.4375rem] border border-[#EBAE12] 
                                    hover:bg-[#EBAE12] hover:text-white transition duration-300 
                                    focus:outline-none focus:ring-2 focus:ring-yellow-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            fileInputRef.current.click();
                          }}
                        >
                          Browse files
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                          className="hidden"
                          multiple
                        />
                      </div>
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileSelection(e.target.files[0])}
                      accept=".csv,.xls,.xlsx"
                    />

                    <p className="w-[50rem] h-5 overflow-hidden text-[#8E8E8E] truncate
                                font-['Inter'] text-[0.8125rem] font-normal leading-[120%]">
                          Supported file formats: .csv, .xls, .xlsx
                    </p>

                    {selectedFile && (
                      <div className="flex justify-between items-center self-stretch p-4 rounded-[0.4375rem] border border-[#D9D9D9] bg-white">
                        <div className="flex items-end gap-[1.0625rem]">
                          <img 
                            src="/file-icon.png"
                            alt="File icon"
                            className="w-[36px] h-[36px] mr-3 mt-1"
                          />
                          <div className="w-full">
                            {isUploading ? (
                              <>
                                <div className="flex items-center justify-between mb-1">
                                  <span className="text-sm font-inter font-bold">Uploading...</span>
                                </div>
                                <div className="text-gray-500 font-inter text-xs mb-2">
                                  {uploadProgress}% • {Math.ceil(timeRemaining)} seconds remaining
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-1.5">
                                  <div 
                                    className="bg-[rgba(99,207,190,255)] h-1.5 rounded-full" 
                                    style={{width: `${uploadProgress}%`}}
                                  ></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <p className="font-bold text-sm">{selectedFile.name}</p>
                                <p className="text-xs text-gray-500">{(selectedFile.size / 1024 / 1024).toFixed(2)} MB</p>
                              </>
                            )}
                          </div>
                        </div>
                        <button 
                          onClick={() => {
                            setIsUploading(false);
                            setSelectedFile(null);
                          }} 
                          className="flex items-center justify-center h-5 w-5 text-gray-600 hover:text-gray-800 font-bold ml-2"
                        >
                          <X size={16} strokeWidth={2.5} />
                        </button>
                      </div>
                    )}

                    <div className="flex justify-end items-start gap-4 self-stretch">
                      <div className="flex items-start">
                        <button
                       
                          className={`
                            font-bold flex justify-center items-center py-2.5 px-3.5  gap-1 rounded-[0.4375rem] transition duration-300 text-base leading-[120%]
                            ${selectedFile && !isUploading
                              ? 'bg-[#ebae12] text-white hover:bg-white hover:text-[#ebae12] border-2 border-[#ebae12]' 
                              : 'bg-[rgb(204,204,204)] text-[rgba(142,142,142,255)] cursor-not-allowed border border-[rgb(204,204,204)]'}
                          `}
                          onClick={handleUploadButtonClick}
                          disabled={!selectedFile || isUploading}
                        >
                          <Upload className="mr-2" size={14} />
                          Upload and Match Field
                        </button>
                      </div>
                    </div>

                    {uploadedFiles.length > 0 && (
                      <div className="flex flex-col w-[50rem] py-5 items-start gap-5 border-t border-[#D9D9D9]">
                        <div className="flex flex-col items-start gap-2.5 self-stretch">
                          <p className="text-black font-['Inter'] text-base font-bold leading-[120%] self-stretch">
                            Uploaded Files
                          </p>
                        </div>
                        <div className="flex flex-col items-start w-[50rem]">
                          <FileUploadInfo uploadedFiles={uploadedFiles} />
                        </div>
                      </div>
                    )}
                  </div>
                {isLoading && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <ClipLoader color="#ffffff" size={50} />
                  </div>
                )}
              </section> 
          </main>
        </div>
      </div>
    </div>
    {showSuccessMessage && (
      <SuccessMessage 
        message={successMessage} 
        onClose={handleCloseSuccessMessage}
      />
    )}
  </div>
);
};

export default ImportPage;