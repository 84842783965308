import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignIn } from '@aws-amplify/auth';
import { Lock, Eye, EyeOff } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import {
  getCurrentUser,
  signIn,
  setUpTOTP,
  verifyTOTPSetup,
  fetchUserAttributes,
  updateUserAttribute,
  fetchMFAPreference,
  updateMFAPreference
} from 'aws-amplify/auth';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

// Custom Alert component
const Alert = ({ children, type = 'success' }) => (
  <div className={`w-full p-4 rounded-[13px] ${type === 'success' ? 'bg-[#ECF9F7] border-[#34D399]' : 'bg-[#FEF2F2] border-[#F87171]'} border flex items-start space-x-2 mb-4`}>
    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${type === 'success' ? 'text-[#34D399]' : 'text-[#F87171]'} flex-shrink-0`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {type === 'success' ? (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      ) : (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      )}
    </svg>
    <div className={`${type === 'success' ? 'text-[#065F46]' : 'text-[#991B1B]'} text-sm`}>{children}</div>
  </div>
);

const TOTPModal = ({ qrCode, onVerify, onClose }) => {
  const [userCode, setUserCode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('usercode is: '+userCode);
    onVerify(userCode);
  };

  return (
    <>
      <div className="self-stretch text-center mb-8">
          <h1 className="text-[61px] font-inter font-black leading-[120%] mb-2 text-black" style={{ fontFamily: 'Inter, sans-serif' }}>
            MFA!
          </h1>
          <p className="text-[16px] font-inter font-normal leading-[120%] text-black mt-2" style={{ fontFamily: 'Inter, sans-serif' }}>
            Logging in for the first time? 
            Please setup your MFA.
          </p>
        </div>
        <h2>Scan QR Code</h2>
        <QRCodeSVG value={qrCode} size={200} />
        {/* <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={userCode}
            onChange={(e) => setUserCode(e.target.value)}
            placeholder="Enter code from authenticator app"
          />
          <button type="submit">Verify</button>
        </form> */}
        <form onSubmit={handleSubmit} className="w-full space-y-4">
            <div className="flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white">
              <Lock size={24} />
              <input
                type="text"
                placeholder="Enter code from authenticator app"
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
                className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                style={{
                  fontFamily: 'Inter, sans-serif',
                  color: userCode ? 'black' : '#D9D9D9'
                }}
              />
              </div>
              <button
                type="submit"
                className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Verify
              </button>
            </form>
    </>
        
  );
};
const ChangePassword = ({ setIsAuthenticated }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);
  const [stage, setStage] = useState('request');
  const [error, setError] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [showTOTPModal, setShowTOTPModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const newUser = location.state?.newUser;
  const [user, setUser] = useState({});

  
  async function handleFirstTimeLogin(user) {
    try {
      
      // Fetch user attributes to check custom attribute for MFA setup
      const userAttributes = await fetchUserAttributes();
      // console.log('this is userAttributes: '+JSON.stringify(userAttributes));
      const mfaSetupCompleted = userAttributes['custom:mfa_setup_completed'];
      
      // Check if MFA is already set up
      const mfaPreference = await fetchMFAPreference();
      const isMFAConfigured = mfaPreference.TOTP === true;
  
      if (!isMFAConfigured || !mfaSetupCompleted || mfaSetupCompleted !== 'true') {
        // Set up TOTP for MFA
        const totpSecret = await setUpTOTP(user);
        // console.log('this is totp secret: '+JSON.stringify(totpSecret));
  
        // Generate the QR code link for authenticator apps
        const qrCode = `otpauth://totp/FindRaiser:${user.username}?secret=${totpSecret.sharedSecret}&issuer=FindRaiser`;
        setQrCode(qrCode);
        // console.log('QR Code URL:', qrCode);
        setShowTOTPModal(true);
      }
    } catch (error) {
      console.error('Error during first-time MFA setup:', error);
    }
  }

  const verifyTOTPSetupHandler = async (userCode) => {
    try {
      if (!userCode) {
      throw new Error("TOTP code is required.");
    }
    // console.log('userCode in verify is : '+userCode);
      await verifyTOTPSetup({ code: userCode }); // Assuming newUser is available here
      // console.log('TOTP setup successful!');
      await updateMFAPreference({ totp: 'ENABLED', totp: 'PREFERRED' });
      await updateUserAttribute({ 
        userAttribute: {
          attributeKey: 'custom:mfa_setup_completed',
          value: 'true' 
      }
    });
      // console.log('MFA setup marked as completed in user attributes.');
      setShowTOTPModal(false); // Close the modal after successful verification
      setIsAuthenticated(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Error verifying TOTP setup:', error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const user = await confirmSignIn({ challengeResponse: newPassword });
      // console.log('user is : '+JSON.stringify(user));
      setShowSuccessAlert(true);
      if (user?.nextStep?.signInStep === 'DONE') {
        // Fetch the full user details
        // const currentUser = await getCurrentUser();
        // setUser(currentUser);
        // console.log('Fetched user:', currentUser);
        setShowSuccessAlert(true);
        // Handle MFA setup
        // await handleFirstTimeLogin(currentUser);
        navigate('/mfaSetup');
      } else {
        throw new Error('Sign-in process not completed.');
      }
      // setTimeout(() => {
      //   navigate('/');
      // }, 3000);
      // setTimeout(() => {
      //   navigate('/mfaSetup');
      // }, 3000);  
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.message || 'An error occurred while changing the password');
    }
  };
  return (
    // <div className="flex h-screen overflow-hidden">
    <div className="flex flex-col md:flex-row w-full">
      
      {/* Left side - Change Password form */}
      {/* <div className="w-1/2 bg-gray-100 flex items-center justify-center">
        <div className="flex flex-col items-center w-[364px]"> */}
       
       {/* OLD UI CODE */}
       
        {/* <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
       
        <div className="flex flex-col items-center w-full max-w-[364px] px-4">
        
        {!showTOTPModal ? (
          <>
            <div className="self-stretch text-center mb-8">
              <h1 className="text-[61px] font-inter font-black leading-[120%] mb-2 text-black" style={{ fontFamily: 'Inter, sans-serif' }}>
                Change Password!
              </h1>
              <p className="text-[16px] font-inter font-normal leading-[120%] text-black mt-2" style={{ fontFamily: 'Inter, sans-serif' }}>
                Logging in for the first time? 
                Please update your password.
              </p>
            </div>
            
            {showSuccessAlert && (
              <Alert>
                Password updated! Your password is successfully updated. Please login again with new password.
              </Alert>
            )}
            
            {error && <Alert type="error">{error}</Alert>}
            <form onSubmit={handleSubmit} className="w-full space-y-4">
              <div className="flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white">
                <Lock size={24} />
                <input
                  type={showPassword ? "password" : "text"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    color: newPassword ? 'black' : '#D9D9D9'
                  }}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="focus:outline-none"
                >
                  {showPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                </button>
              </div>
              <div className="flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white">
                <Lock size={24} />
                <input
                  type={showConfirmPassword ? "password" : "text"}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    color: confirmPassword ? 'black' : '#D9D9D9'
                  }}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="focus:outline-none"
                >
                  {showConfirmPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                </button>
              </div>
              <button
                type="submit"
                className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Change Password
              </button>
            </form>

            <br />
            
            <button
              onClick={() => navigate('/signin')}
              className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-white text-[#EBAE12] font-bold text-[16px] hover:bg-[#FDF7E7] transition duration-300 border border-[#EBAE12]"
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              Login
            </button>
          </>
        ) : (
          <TOTPModal qrCode={qrCode} onVerify={verifyTOTPSetupHandler} onClose={() => setShowTOTPModal(false)} />
        )}

        </div>
      </div> */}

      {/* OLD UI CODE WITH MFA ENDS HERE */}


      <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="flex w-[22.75rem] flex-col items-center gap-[3.75rem] flex-shrink-0">
          {/* Main Container */}
          <div className="flex flex-col items-start gap-[0.625rem] self-stretch">
            <p className="text-black font-inter text-[3.8125rem] font-black leading-[120%]">
              Change Password!
            </p>
            <div className="flex w-[22.75rem] h-[3rem] justify-center items-center">
              <p className="text-black font-inter text-xl font-normal leading-[1.5rem] w-[22.75rem] flex-shrink-0">
                Logging in for the first time? 
                Please update your password.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[1.25rem] self-stretch">
            <form onSubmit={handleSubmit} className="flex flex-col items-center gap-[3.75rem] w-[22.75rem] flex-shrink-0">
              <div className="flex flex-col gap-[1.25rem] w-full">
                {error && <Alert type="error">{error}</Alert>}
                <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid ${error ? 'border-2 border-[#EF6950]' : isNewPasswordFocused ? 'border-black' : 'border-transparent'}`}>
                  <Lock size={24} />
                  <input
                    type={showPassword ? "password" : "text"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onFocus={() => setIsNewPasswordFocused(true)}
                    onBlur={() => setIsNewPasswordFocused(false)}
                    required
                    className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isNewPasswordFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: error ? '#EF6950' : 'black',
                    }}
                    
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none"
                  >
                    {showPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
                </div>
                <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid ${error ? 'border-2 border-[#EF6950]' : isConfirmPasswordFocused ? 'border-black' : 'border-transparent'}`}>
                  <Lock size={24} />
                  <input
                    type={showConfirmPassword ? "password" : "text"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onFocus={() => setIsConfirmPasswordFocused(true)}
                    onBlur={() => setIsConfirmPasswordFocused(false)}
                    required
                    className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isConfirmPasswordFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: error ? '#EF6950' : 'black',
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="focus:outline-none"
                  >
                    {showConfirmPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
                </div>

              </div>
              <button
                type="submit"
                className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Change Password
              </button>
            </form>
            <button
              onClick={() => navigate('/signin')}
              className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-white text-[#EBAE12] font-bold text-[16px] hover:bg-[#FDF7E7] transition duration-300 border border-[#EBAE12]"
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              Login
            </button>
          </div>

        </div>
      </div>
     


      <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative overflow-hidden">
        {/* Logo container */}
        <div className="flex w-[23rem] flex-col items-start gap-5 absolute left-[6.5rem] top-[5rem]">
          <img
            src="Frame8.png"
            alt="FINDRAISER Logo"
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Branding image container */}
        <div className="absolute bottom-0 right-0 w-[250px] h-[250px]">
          <img
            src="Branding.png"
            alt="FINDRAISER Branding"
            className="w-full h-full object-contain md:scale-90 sm:scale-75 lg:scale-100"
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;