import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes, fetchAuthSession, signOut, getCurrentUser, updateUserAttribute } from '@aws-amplify/auth';
import { awsExports } from "./aws-exports";
import { MyProvider } from "./Context/MyContext";
import { Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


import { useMediaQuery } from '@mui/material';

import SignIn from './components/Home/SignIn';
import Sparkles from './components/Home/Sparkles'
import ForgotPassword from './components/Home/ForgotPassword';
import Main from './components/Main';
//import SignUp from './components/Home/SignUp';
import AdminDashboard from './components/Home/AdminDashboard';
import Dashboard from './components/Home/Dashboard';
import UserManagement from './components/Home/UserManagement';
import MySavedQueries from './components/Home/MySavedQueries';
import UnderConstruction from './components/Home/UnderConstruction';
import QueryManagement from './components/Home/QueryManagement';
import TargetPage from "./components/Home/TargetPage";
import Notifications from './components/Home/Notifications';
import HelpPage from './components/Home/HelpPage';
import Setting from './components/Home/Setting';
import ImportPage from './components/Home/ImportPage'; 
import ChangePassword from './components/Home/ChangePassword';
import MatchFieldsPage from './components/Home/MatchFieldsPage';
import TokenExpirationManager from './TokenExpirationManager';
import Sidebar_v2 from './components/sidebar/Sidebar_v2';
import MFASetupFlow from './components/Home/MFASetupFlow';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import useLambda from './components/hooks/useLambda';

//import { expState } from './store/atoms';

import {
  jwtTokenState, 
  rolesState,
  subState,
  tenantGroupState,
  userEmailState,
  isSuperAdminState,
  isTenantAdminRoleState,
  expState,
  firstNameState,
  displayRoleState
} from './store/atoms';


Amplify.configure(awsExports);

function AppContent({isAuthenticated, setIsAuthenticated}) {
  const location = useLocation();
  //const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const showSidebar = isAuthenticated && 
    !['/signin', '/forgotPassword', '/changePassword','/mfaSetup'].includes(location.pathname);


  return (
    <div className={`flex h-screen ${isMobile ? 'flex-col' : ''}`}>
      {showSidebar && (
        <Sidebar_v2 isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>
      )}
      <div className={`flex-1  ${isAuthenticated && isMobile && !isCollapsed ? 'hidden' : ''}`}>
        <Routes>
          <Route path="/signin" element={
            isAuthenticated ? <Navigate to="/" replace /> : <SignIn setIsAuthenticated={setIsAuthenticated} />
          } />
          <Route path="/forgotPassword" element={<ForgotPassword setIsAuthenticated={setIsAuthenticated}/>} />
          <Route path="/changePassword" element={<ChangePassword setIsAuthenticated={setIsAuthenticated} />} />
          {/* <Route path="/mfaSetup" element={isAuthenticated ? <MFASetupFlow setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/signin" replace />} />*/}
          <Route path="/mfaSetup" element={<MFASetupFlow setIsAuthenticated={setIsAuthenticated} /> } />
          {/* Protected routes */}
          <Route path="/" element={isAuthenticated ? <Main /> : <Navigate to="/signin" replace />} />
          {/* <Route path="/admin" element={isAuthenticated ? <AdminDashboard /> : <Navigate to="/signin" replace />} /> */}
          <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/signin" replace />} />
          {/* ... other protected routes ... */}
          <Route path="/userManagement" element={
            isAuthenticated ? <UserManagement /> : <Navigate to="/signin" replace />
          } />
          <Route path="/mySavedQueries" element={
            isAuthenticated ? <MySavedQueries /> : <Navigate to="/signin" replace />
          } />
          <Route path="/underConstruction" element={
            isAuthenticated ? <UnderConstruction /> : <Navigate to="/signin" replace />
          } />
          <Route path="/queryManagement" element={
            isAuthenticated ? <QueryManagement /> : <Navigate to="/signin" replace />
          } />
          <Route path="/targetPage" element= {
          isAuthenticated ? <TargetPage /> : <Navigate to="/signin" replace />
          } />
          <Route path="/notifications" element= {
          isAuthenticated ? <Notifications /> : <Navigate to="/signin" replace />
          } />
          <Route path="/help" element= {
          isAuthenticated ? <HelpPage /> : <Navigate to="/signin" replace />
          } />
          <Route path="/settings" element= {
          isAuthenticated ? <Setting /> : <Navigate to="/signin" replace />
          } />
          <Route path="/import" element={
            isAuthenticated ? <ImportPage /> : <Navigate to="/signin" replace />
          } />
          <Route path="/match-fields" element={
            isAuthenticated ? <MatchFieldsPage /> : <Navigate to="/signin" replace />
          } />
           <Route path="/sparkles" element={
            isAuthenticated ? <Sparkles /> : <Navigate to="/signin" replace />
          } />
          <Route path="/sidebar" element={<Sidebar_v2 />}/>
          {/* Catch-all route for undefined paths */}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}

          {/* Catch-all route for undefined paths */}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </div>
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  //const exp = useRecoilValue(expState);

  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
    const [roles, setRoles] = useRecoilState(rolesState);
    const [sub, setSub] = useRecoilState(subState);
    const [tenantGroup, setTenantGroup] = useRecoilState(tenantGroupState);
    const [userEmail, setUserEmail] = useRecoilState(userEmailState);
    const [isSuperAdmin, setisSuperAdmin] = useRecoilState(isSuperAdminState);
    const [isTenantAdminRole, setisTenantAdminRole] = useRecoilState(isTenantAdminRoleState);
    const [exp, setExp] = useRecoilState(expState);
    const [firstName, setFirstName] = useRecoilState(firstNameState);
    const [displayRole, setDisplayRole] = useRecoilState(displayRoleState);
    const { configureLambda, decodeToken, getJWT } = useLambda();

    async function updateLastLogin() {
      try {
        const currentUser = await getCurrentUser();
        if (!currentUser) {
          console.log('No authenticated user found');
          return;
        }
    
        
        const loginTime = localStorage.getItem('loginTime');
        console.log('Setting login time to:', loginTime);
    
            // Try with explicit attributes object format
            const result = await updateUserAttribute({
              userAttribute: {
                attributeKey: 'custom:loginTime',
                value: loginTime
              }
            });
    
        console.log('Last login updated successfully', result);
      } catch (error) {
        console.error('Error updating last login:', error);
        if (error instanceof Error) {
          console.error('Error type:', error.constructor.name);
          console.error('Error message:', error.message);
          console.error('Full error:', error);
        }
      }
    }
    


  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const session = await fetchAuthSession();
        if (!session) {
          throw new Error("No authenticated session");
        }
        const jwtToken = await getJWT();
        //console.log('jwt is inside app.js: '+jwtToken);
        setJwtToken(jwtToken);
        const decodedToken = decodeToken(jwtToken);
        const cognitoRoles = Array.isArray(decodedToken['cognito:roles']) ? decodedToken['cognito:roles'] : [];
        if(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole')) {
          setDisplayRole('Global Admin');
        } else if(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole')) {
          setDisplayRole('Administrator');
        }
        const groups = decodedToken['cognito:groups'];
        const expTime = decodedToken['exp'];
        //console.log('tenant groups: '+groups);
        let filteredGroups = [];
        if (groups.includes("Tenants")) {
          filteredGroups = groups.filter(group => group !== 'Tenants');
        } else if (groups) {
          filteredGroups = [...groups];
        }
        setTenantGroup(filteredGroups);
        setRoles(cognitoRoles);
        setisSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
        setisTenantAdminRole(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
        setExp(expTime);

      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };

    fetchRoles();
    //console.log('data fetched and stored');

    const fetchUserName = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setFirstName(userAttributes.given_name);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
  
    fetchUserName();
  }, []);

  useEffect(() => {
    checkAuthState();
  }, []);

  const logoutUser = async () => {
    try {
      await updateLastLogin();
      // await updateUserAttribute({ 
      //   userAttribute: {
      //     attributeKey: 'custom:mfa_setup_completed',
      //     value: 'false' 
      //   }
      // });
      await signOut();
      window.location.href = '/signin';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    if (!exp) return;

   // const jwtPayload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = exp * 1000;
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;

    const logoutTimer = setTimeout(() => {
      logoutUser();
    }, timeUntilExpiration);

    return () => clearTimeout(logoutTimer);
  }, [exp]);


  async function checkAuthState() {
    try {
      const session = await fetchAuthSession();
      //console.log("Session found:", session);
      
      // Check if the session has valid tokens
      if (session && session.tokens && session.tokens.accessToken) {
        //console.log("Valid session found");
        //console.log('Setting isAuthenticated to true');
        setIsAuthenticated(true);
      } else {
        //console.log("Session found but no valid tokens");
        //console.log('Setting isAuthenticated to false');
        setIsAuthenticated(false);
      }
    } catch (error) {
      //console.log("Error checking authentication:", error);
      //console.log('Setting isAuthenticated to false');
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }

  // async function checkAuthState() {
  //     try {
  //       const session = await fetchAuthSession();
  //       if (session && session.tokens && session.tokens.accessToken) {
  //         const user = await getCurrentUser();
  //         const userAttributes = await fetchUserAttributes(user);
          
  //         if (userAttributes['custom:mfa_setup_completed'] === 'true') {
  //           console.log('MFA verified, setting authenticated to true');
  //           setIsAuthenticated(true);
  //         } else {
  //           console.log('MFA not verified, setting authenticated to false');
  //           setIsAuthenticated(false);
  //         }
  //       } else {  
  //         console.log('No valid session, setting authenticated to false');
  //         setIsAuthenticated(false);
  //       }
  //     } catch (error) {
  //       console.error('Error checking auth status:', error);
  //       setIsAuthenticated(false);
  //     }
  //   }


  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  return (
    <Router>
      <TokenExpirationManager>
        <MyProvider>
          <AppContent isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
        </MyProvider>
      </TokenExpirationManager>
    </Router>
    
  );
  
}

export default App;
