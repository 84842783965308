import React, { useState, useEffect, useRef } from 'react';
import { signIn, signOut, fetchAuthSession, AuthError, getCurrentUser, 
  updateUserAttribute,fetchUserAttributes, fetchMFAPreference, confirmSignIn, fetchDevices } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../../cloudwatchLogger';
import { AlertCircle, Eye, EyeOff } from 'lucide-react';
import {mfaActiveState} from '../../store/atoms';
import { useRecoilState } from 'recoil';


const SignIn = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState('');
  const [error, setError] = useState('');
  const [newUser, setNewUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginStage, setLoginStage] = useState('initial');
  const passwordInputRef = useRef(null);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isMfaActivated, setIsMfaActivated] = useRecoilState(mfaActiveState);
  const navigate = useNavigate();

  // async function checkAuthStatus() {
  //   try {
  //     const session = await fetchAuthSession();
  //     if (session && session.tokens && session.tokens.accessToken) {
      //   const user = await getCurrentUser();
      //   const userAttributes = await fetchUserAttributes(user);
        
      //   if (userAttributes['custom:mfa_setup_completed'] === 'true') {
      //     console.log('MFA verified, setting authenticated to true');
      //     setIsAuthenticated(true);
      //   } else {
      //     console.log('MFA not verified, setting authenticated to false');
      //     setIsAuthenticated(false);
      //   }
      // } else {  
  //       console.log('No valid session, setting authenticated to false');
  //       setIsAuthenticated(false);
  //     }
  //   } catch (error) {
  //     console.error('Error checking auth status:', error);
  //     setIsAuthenticated(false);
  //   }
  // }

  


  useEffect(() => {
    async function checkAuthStatus() {
      try {
        const session = await fetchAuthSession();
        if (session && session.tokens && session.tokens.accessToken) {
          
          console.log('set authenticated to true inside signin');
          setIsAuthenticated(true);
        } else {  
          console.log('set authenticated to false inside signin');
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    }
     checkAuthStatus();

    const storedEmail = localStorage.getItem('rememberedEmail');
    const storedPassword = localStorage.getItem('rememberedPassword');
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, [navigate, setIsAuthenticated]);

  const handlePasswordChange = (e) => {
    const input = e.target;
    const newValue = input.value;
    const selection = window.getSelection().toString();

    if (selection) {
      // Overwrite when highlighted
      setPassword(newValue);
    } else {
      setPassword(newValue); // Handles typical change
    }
  };

  async function updateLastLogin() {
    try {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        // console.log('No authenticated user found');
        return;
      }
  
      // Get the current timestamp
      const timestamp = new Date().toISOString();
      // console.log('Setting login time to:', timestamp);
  
      // Store the login time in localStorage
      localStorage.setItem('loginTime', timestamp);
  
      // console.log('Last login time stored in localStorage successfully');
    } catch (error) {
      console.error('Error storing last login time:', error);
      if (error instanceof Error) {
        console.error('Error type:', error.constructor.name);
        console.error('Error message:', error.message);
        console.error('Full error:', error);
      }
    }
  }

  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('');
  //   setLoginStage('loading');

  //   try {
  //     // Trim whitespaces from email and password
  //     const trimmedEmail = email.trim();
  //     const trimmedPassword = password.trim();

  //     if (!trimmedEmail || !trimmedPassword) {
  //       setError('Username and password cannot be empty or contain only spaces.');
  //       setLoginStage('initial');
  //       return;
  //     }

  //     await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate network delay
  //     const { isSignedIn, nextStep } = await signIn({ username: trimmedEmail, password: trimmedPassword });

  //     setLoginStage('success');
  //     await new Promise(resolve => setTimeout(resolve, 500)); // Show success state briefly

  //     if (isSignedIn) {
  //       logEvent({ action: 'SignInConfirmationRequested', message: 'User signed in' });
  //       await updateLastLogin();
  //       setIsAuthenticated(true);

  //       if (rememberMe) {
  //         localStorage.setItem('rememberedEmail', trimmedEmail);
  //         localStorage.setItem('rememberedPassword', trimmedPassword);
  //       } else {
  //         localStorage.removeItem('rememberedEmail');
  //         localStorage.removeItem('rememberedPassword');
  //       }
  //       navigate('/');
  //     } else {
  //       if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
  //         setNewUser({ username: trimmedEmail, password: trimmedPassword });
  //         navigate('/changePassword', { state: { newUser: { username: trimmedEmail, password: trimmedPassword } } });
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error signing in:', error);
  //     setError(error instanceof AuthError ? error.message : 'An unknown error occurred');
  //     setLoginStage('initial');
  //   }
  // };

  // async function handleRegularLogin(trimmedEmail, trimmedPassword) {
  //   try {
  //     // const user = await signIn(username, password);
  //     // console.log('User signed in:', user);
  
  //     // Check for MFA challenge
  //      // Check for MFA challenge
  //       const mfaCode = prompt('Enter the MFA code from your authenticator app:');
  //       if (!mfaCode) {
  //         console.error('MFA code is required.');
  //         return;
  //       }

  //       // console.log('MFA required. Confirming with TOTP code...');
     
  //       const confirmsignRes = await confirmSignIn({
  //         challengeResponse: mfaCode
  //       });
  //       // console.log('MFA confirmed, login successful! : '+JSON.stringify(confirmsignRes));
  //       if (confirmsignRes['isSignedIn']) {
  //         logEvent({ action: 'SignInConfirmationRequested', message: 'User signed in' });
  //         await updateLastLogin();
  //         setIsAuthenticated(true);
    
  //         if (rememberMe) {
  //           localStorage.setItem('rememberedEmail', trimmedEmail);
  //           localStorage.setItem('rememberedPassword', trimmedPassword);
  //         } else {
  //           localStorage.removeItem('rememberedEmail');
  //           localStorage.removeItem('rememberedPassword');
  //         }
    
  //         navigate('/');
  
  //       }
  //   } catch (error) {
  //     console.error('Error during regular login with MFA:', error);
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoginStage('loading');
  
    try {
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
  
      if (!trimmedEmail || !trimmedPassword) {
        setError('Username and password cannot be empty or contain only spaces.');
        setLoginStage('initial');
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      const { isSignedIn, nextStep } = await signIn({ username: trimmedEmail, password: trimmedPassword });
      // console.log('is signed in: '+isSignedIn+ ' and nextStep is: '+JSON.stringify(nextStep));
  
      setLoginStage('success');
      await new Promise(resolve => setTimeout(resolve, 500));
      
  
      if (isSignedIn) {
        //logEvent({ action: 'SignInConfirmationRequested', message: 'User signed in' });
        //await updateLastLogin();
        //setIsAuthenticated(true);
        const devices = await fetchDevices();
        const isDeviceRemembered = devices.some((device) => device.remembered);
  
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', trimmedEmail);
          localStorage.setItem('rememberedPassword', trimmedPassword);
        } else {
          localStorage.removeItem('rememberedEmail');
          localStorage.removeItem('rememberedPassword');
        }

        if (isDeviceRemembered) {
          logEvent({ action: 'SignInBypassMFA', message: 'Trusted device, bypassing MFA' });
          await updateLastLogin();
          setIsAuthenticated(true);
          navigate('/'); 
          return;
        } else {
          // navigate('/');
          console.log('redirecting to mfaSetup from signin');
          navigate('/mfaSetup');
        }

      }
      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          setNewUser({ username: trimmedEmail, password: trimmedPassword });
          navigate('/changePassword', { state: { newUser: { username: trimmedEmail, password: trimmedPassword } } });
        
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE' || nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        //handleRegularLogin(trimmedEmail, trimmedPassword)
        setIsMfaActivated(true);
        navigate('/mfaSetup');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      setError(error instanceof AuthError ? error.message : 'An unknown error occurred');
      setLoginStage('initial');
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const ErrorAlert = ({ message }) => (
    <div className="flex flex-col items-start w-[22.75rem] p-4 gap-2.5 rounded-[0.8125rem] border-2 border-[#EF6950] bg-[#FDF0EE] shadow-[2px_2px_8px_0px_rgba(239,105,80,0.1)]">
      <div className="flex items-center gap-2.5">
        <AlertCircle className="w-5 h-5 text-[#EF6950] mt-0.5" />
        <p className="text-[#EF6950] font-inter text-base font-bold leading-[120%] self-stretch">Need attention!</p>
      </div>
      <p className="text-[#EF6950] font-inter text-base font-normal leading-[120%] self-stretch">
        {message}
      </p>
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center w-[22.75rem] gap-[3.75rem] flex-shrink: 0">

          
          <div className="flex flex-col justify-center items-center gap-2.5 self-stretch">
            <p className="text-black font-inter text-[3.8125rem] font-black leading-[120%] self-stretch">
              Welcome
            </p>
            <p className="text-black font-inter text-xl font-normal leading-[120%] self-stretch">
              Log in below to get started
            </p>
          </div>
           

            <form onSubmit={handleSubmit} className="w-full flex flex-col gap-[1.25rem]">
              {error && <ErrorAlert message={error} />}
              <div className={`flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white transition-all duration-300 border border-solid  ${isEmailFocused ? 'border-black' : 'border-transparent'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                <input
                  type="text"
                  placeholder="Username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                  required
                  className={`w-full font-inter text-[16px] font-normal leading-[120%] focus:outline-none ${isEmailFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'}`}
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    color: 'black',
                  }}
                />
              </div>

              <div className={`flex items-center gap-[14px] p-[14px] self-stretch rounded-[14px] bg-white transition-all duration-300 border border-solid ${error ? 'border-2 border-[#EF6950]' : isPasswordFocused ? 'border-black' : 'border-transparent'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                <input
                  ref={passwordInputRef}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  required
                  className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isPasswordFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    color: error ? '#EF6950' : 'black',
                  }}
                />
                <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none"
                  >
                    {showPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
              </div>
              <div className="flex flex-col gap-[3.75rem]">
                <div className="flex justify-end">
                  <a href="/forgotPassword" className="text-[16px] font-normal leading-[120%] text-black font-inter hover:underline" style={{ fontFamily: 'Inter, sans-serif' }}>Forgot password?</a>
                </div>
                
                <button
                  type="submit"
                  className={`flex w-[22.6875rem] py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative ${
                    loginStage !== 'initial' ? 'cursor-not-allowed' : ''
                  }`}
                  style={{ fontFamily: 'Inter, sans-serif' }}
                  onClick={handleSubmit}
                  disabled={loginStage !== 'initial'}
                >
                  <span className="flex items-center justify-center">
                    {loginStage === 'success' ? (
                      <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    ) : (
                      "Login"
                    )}
                    {loginStage === 'loading' && (
                      <svg className="animate-spin-fast ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <g>
                          <line x1="12" y1="2" x2="12" y2="6" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="12" y1="18" x2="12" y2="22" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="2" y1="12" x2="6" y2="12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="18" y1="12" x2="22" y2="12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </g>
                      </svg>
                    )}
                  </span>
                </button>
              </div>

              <div className="flex items-center justify-start gap-2.5">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    className="w-[19px] h-[19px] flex-shrink-0 appearance-none bg-gray-300 border-2 border-gray-400 rounded-sm checked:bg-gray-300 checked:border-gray-400 focus:outline-none transition duration-200"
                  />
                  <svg
                    className={`absolute left-0 top-0 w-[19px] h-[19px] pointer-events-none ${rememberMe ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <label htmlFor="rememberMe" className="text-[16px] font-inter font-normal leading-[120%] text-black" style={{ fontFamily: 'Inter, sans-serif' }}>
                  Remember this account
                </label>
              </div>
            </form>
        </div>
      </div>

      {/* <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative">
        <div
          style={{
            display: 'flex',
            width: '368px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
            marginBottom: '400px', // Adjust to move it higher
            marginRight: '100px', // Adjust to move it more to the left
            zIndex: 10, // Ensure it's on top of the Branding image
          }}
        >
          <img
            src="Frame8.png"
            alt="FINDRAISER Branding"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </div>

        <div
          className="fixed bottom-0 right-0"
          style={{
            width: '418.002px',
            height: '420.002px',
            flexShrink: 0,
            transform: 'scale(1)', // Add scaling
            zIndex: 5, // Branding should stay behind Frame8.png
          }}
        >
          <img
            src="Branding.png"
            alt="FINDRAISER Branding"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              transform: 'scale(0.9)', // Scale it down by 10% initially
            }}
            className="md:scale-100 sm:scale-75 lg:scale-125" // Scale based on screen size
          />
        </div>
      </div> */}
      <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative overflow-hidden">
        {/* Logo container */}
        <div className="flex w-[23rem] flex-col items-start gap-5 absolute left-[6.5rem] top-[5rem]">
          <img
            src="Frame8.png"
            alt="FINDRAISER Logo"
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Branding image container */}
        <div className="absolute bottom-0 right-0 w-[250px] h-[250px]">
          <img
            src="Branding.png"
            alt="FINDRAISER Branding"
            className="w-full h-full object-contain md:scale-90 sm:scale-75 lg:scale-100"
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default SignIn;