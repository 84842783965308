/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import {CheckCircle} from 'lucide-react';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import { useRecoilState } from 'recoil';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {
  jwtTokenState, 
  rolesState,
  subState,
  tenantGroupState,
  userEmailState,
  isSuperAdminState,
  isTenantAdminRoleState,
  importNotificationsState
} from '../../store/atoms';
import useLambda from '../hooks/useLambda';
//import Tooltip from '@mui/material/Tooltip';


const InputField = ({ label, placeholder, type = 'text', value, onChange, disabled, className }) => {
  return (
    <div className="relative mb-4">
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          className={`
            w-full px-3 pt-5 pb-2 text-sm border rounded-md
            focus:outline-none focus-within:ring-1 focus-within:ring-black focus-within:border-black
            transition-all duration-200
            ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''}
            ${className}
          `}
          aria-label={label}
        />
        <label
          className={`
            absolute text-xs text-gray-500 left-3 top-1
            transition-all duration-200
            ${disabled ? 'text-gray-400' : ''}
          `}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

// const SelectField = ({ label, options, value, onChange, className}) => {
//   return (
//     <div className="relative mb-4 px-2.5">
//       <div className={`relative border border-gray-300 rounded-[7px] focus-within:ring-1 focus-within:ring-black focus-within:border-black ${className}`}>
//         <select
//           value={value}
//           onChange={onChange}
//           className="w-full px-3 pt-6 pb-2 text-sm appearance-none focus:outline-none bg-transparent"
//         >
//           <option value="" disabled hidden>Select a Administrator</option>
//           {options.map((option) => (
//             <option key={option} value={option}>
//               {option}
//             </option>
//           ))}
//         </select>
//         <label className="absolute text-xs text-gray-500 top-2 left-3 transition-all duration-300 pointer-events-none">
//           {label}
//         </label>
//         <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
//           <svg className="w-4 h-4 fill-current text-gray-500" viewBox="0 0 20 20">
//             <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" fillRule="evenodd"></path>
//           </svg>
//         </div>
//       </div>
//     </div>
//   );
// };



const CustomTooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  
  return (
    <div 
      className="relative inline-block w-full"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="absolute z-20 p-2 text-sm text-white bg-black rounded shadow-lg -top-8 left-1/2 transform -translate-x-1/2">
          {text}
        </div>
      )}
    </div>
  );
};

const SelectField = ({ label, options, value, onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="relative mb-4 px-2.5">
      <div 
        className={`relative border border-gray-300 rounded-[7px] focus-within:ring-1 focus-within:ring-black focus-within:border-black ${className}`}
      >
        <div
          className="w-full px-3 pt-6 pb-2 text-sm bg-transparent cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {value || 'Select an Administrator'}
        </div>
        
        <label className="absolute text-xs text-gray-500 top-2 left-3 transition-all duration-300 pointer-events-none">
          {label}
        </label>
        
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg className="w-4 h-4 fill-current text-gray-500" viewBox="0 0 20 20">
            <path 
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
              clipRule="evenodd" 
              fillRule="evenodd"
            />
          </svg>
        </div>
        
        {isOpen && (
          <div className="absolute z-10 w-full left-0 top-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {options.map((option) => (
              option.isDisabled ? (
                <CustomTooltip key={option.groupName} text="Administrator status unconfirmed">
                  <div 
                    className="px-3 py-2 text-sm text-gray-400 cursor-not-allowed hover:bg-gray-50"
                  >
                    {option.groupName}
                  </div>
                </CustomTooltip>
              ) : (
                <div
                  key={option.groupName}
                  className="px-3 py-2 text-sm cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    onChange({ target: { value: option.groupName } });
                    setIsOpen(false);
                  }}
                >
                  {option.groupName}
                </div>
              )
            ))}
          </div>
        )}
      </div>
    </div>
  );
};



const TabButton = ({ label, selected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`
      flex flex-col justify-center items-center
      h-10 px-3
      rounded-[7px]
      transition-all duration-150 ease-in-out
      text-center
      font-inter text-base leading-tight
      ${
        selected
          ? 'bg-[#F2F2F2] text-black font-bold border border-[#D9D9D9]'
          : 'bg-white text-[#8E8E8E] font-normal border-transparent'
      }
      ${
        !selected
          ? 'hover:text-black hover:font-bold hover:bg-white'
          : ''
      }
    `}
  >
    {label}
  </button>
);

const SubmitButton = ({ onClick, disabled }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`w-full sm:w-auto px-4 py-2 text-white font-bold rounded-md ${
      disabled
        ? 'bg-gray-500 cursor-not-allowed'
        : 'bg-amber-500 hover:text-black'
    }`}
  >
    Submit
  </button>
);




function AddUserForm({ isSuperAdmin, roles, sub, tenantGroup, isReadOnly, onFormTypeChange  }) {
  const [activeTab, setActiveTab] = useState('addUser');
  const [teamName, setTeamName] = useState('');
  const [teamSize, setTeamSize] = useState(1);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [userGroupNames, setUserGroupNames] = useState([]);
  const [errors, setErrors] = useState({});
  //const [lambdaFunctionName, setLambdaFunctionName] = useState('fr-tst-fe-tenantmgmt');
  const [lambdaFunctionName] = useState(awsExports.Lambda.userManagement);
  const { configureLambda, decodeToken, getJWT } = useLambda();
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  // const [formTitle, setFormTitle] = useState('Add New Tenant');

  const [importNotification,setImportNotifications] = useRecoilState(importNotificationsState);

// Helper function to capitalize first letter
const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Modified input handlers for firstName and lastName
const handleFirstNameChange = (e) => {
  const value = e.target.value;
  setFirstName(capitalizeFirstLetter(value));
};

const handleLastNameChange = (e) => {
  const value = e.target.value;
  setLastName(capitalizeFirstLetter(value));
};

const validateForm = () => {
  const newErrors = {};
  
  // Validate first name and last name presence
  if (!firstName) {
    newErrors.firstName = true;
  }

  if (!lastName) {
    newErrors.lastName = true;
  }

  // Rest of your existing validation logic
  if (isSuperAdmin && !teamName && activeTab === 'createTenant') {
    newErrors.teamName = true;
  }
  
  if (activeTab === 'createTenant' && (teamSize < 1 || teamSize > 50)) {
    newErrors.teamSize = true;
  }
  
  if (!email) {
    newErrors.email = true;
  }
  
  if (!confirmEmail) {
    newErrors.confirmEmail = true;
  }
  
  if (email !== confirmEmail) {
    newErrors.confirmEmail = true;
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await callLambdaFunction();
        resetForm(); // Reset the form after successful submission
        updateNotifications(true,'User Management',`User Added: ${email}`, new Date().getTime());
        window.location.reload();
      } catch (error) {
        updateNotifications(false,'User Management',`Error adding user: ${email}`, new Date().getTime());
        console.error('Error submitting form:', error);
        setMessage(`Error: ${error.message}`);
      }
    }
  };

  const createS3FolderIfNotExists = async (tenantName) => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: awsExports.S3.accessKeyId,
        secretAccessKey: awsExports.S3.secretAccessKey,
        region: awsExports.S3.region,
      });
  
      const basePath = `Tenants/${tenantName}/`;
      const foldersToCreate = ['ClientUpload/', 'FEC/', 'NGP-VAN/'];
  
      // Create the tenant folder
      const createTenantFolderParams = {
        Bucket: awsExports.S3.bucket,
        Key: basePath,
        Body: ''
      };
      await s3.putObject(createTenantFolderParams).promise();
      //console.log(`Created tenant folder: ${basePath}`);
  
      // Create subfolders
      for (const folder of foldersToCreate) {
        const folderPath = `${basePath}${folder}`;
  
        const createFolderParams = {
          Bucket: awsExports.S3.bucket,
          Key: folderPath,
          Body: ''
        };
        await s3.putObject(createFolderParams).promise();
        //console.log(`Created folder: ${folderPath}`);
      }
    } catch (error) {
      console.error('Error creating S3 folders:', error);
      throw error;
    }
  };

  const callLambdaFunction = async () => {
    try {
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'createUserAndGroup',
        activeTab,
        roles,
        'adminEmail': email,
        'adminUsername': confirmEmail,
        firstName,
        lastName,
        sub,
        tenantGroup,
        'tenantLimit': teamSize.toString(),
      };

      if (teamName !== null && teamName !== '') {
        payload.groupName = teamName;
      }

      const stringifiedPayload = JSON.stringify(payload);
      //console.log(`PAYLOAD IS : ${stringifiedPayload}`);
      const params = {
        //FunctionName: 'createTenant',
        FunctionName: lambdaFunctionName,
        Payload: stringifiedPayload,
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const bodyData = JSON.parse(data.body);

      // If a new tenant group is created by a super admin, create the S3 folder
      if (isSuperAdmin && activeTab === 'createTenant' && teamName) {
        await createS3FolderIfNotExists(teamName);
      }
      setMessage(bodyData.message || 'Lambda function called successfully');
      //logEvent({ eventType: 'createdUserAndGroup', details: `User and group created. Message: ${bodyData.message}` });
    } catch (error) {
      // console.log(`error creating tenant: ${error.message}`);
      setMessage(`Error creating tenant: ${error.message}`);
      //logEvent({ eventType: 'errorCreatingUserAndGroup', details: `Error creating user and group: ${error.message}` });
    }
  };

  const fetchGroupsForDropdown = async () => {
    try {
      await configureLambda(jwtToken);
      const lambda = new AWS.Lambda();
      const payload = {
        'operation': 'fetchGroups',
        activeTab,
      };
      const params = {
        //FunctionName: 'createTenant',
        FunctionName: lambdaFunctionName,
        Payload: JSON.stringify(payload),
      };

      const result = await lambda.invoke(params).promise();
      const data = JSON.parse(result.Payload);
      const groupNames = JSON.parse(data.body);
      //console.log('group names are : '+groupNames);
      setUserGroupNames(groupNames);
      //logEvent({ eventType: 'fetchedGroupsForDropdown', details: `Fetched groups for dropdown for tab: ${activeTab}` });
    } catch (error) {
      console.error('Error fetching groups:', error);
      //logEvent({ eventType: 'fetchGroupsForDropdownError', details: `Error fetching groups for dropdown: ${error.message}` });
    }
  };

  useEffect(() => {
    if (activeTab === 'addUser') {
      fetchGroupsForDropdown();
    }
  }, [activeTab]);
  const showLimitExceededMessage = !isSuperAdmin && isReadOnly;

  const resetForm = () => {
    setTeamName('');
    setTeamSize(1);
    setSelectedTeam('');
    setEmail('');
    setConfirmEmail('');
    setFirstName('');
    setLastName('');
    setErrors({});
  };

  const UserAddedNotification = ({ message }) => {
    return (
      <div className="max-w-md w-full bg-emerald-50 border border-emerald-200 rounded-lg p-4 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <CheckCircle className="h-6 w-6 text-emerald-400" />
        </div>
        <div className="flex-1">
          <p className="font-inter font-medium text-emerald-800">User added!</p>
          <p className="font-inter font-medium text-emerald-800">Email with temporary password and instructions will be sent to user</p>
          <p className="mt-1 text-emerald-700">{message}</p>
        </div>
      </div>
    );
  };


 

  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  //   if (onFormTypeChange) {
  //     onFormTypeChange(tab);
  //   }
  //   //setFormTitle(tab === 'createTenant' ? 'Add New Tenant' : 'Add New User');
  // };

  //console.log('import notifications inside add user formare: '+importNotification);
  const updateNotifications = (success, header, message, timestamp) => {
    setImportNotifications((prevNotifications) => {
      const newNotification = {
        id: prevNotifications.length + 1,
        success,
        title: header,
        description: message,
        timestamp
      };
      const updatedNotifications = [...prevNotifications, newNotification];
      
      // Store in local storage
      localStorage.setItem('importNotifications', JSON.stringify(updatedNotifications));
      
      return updatedNotifications;
    });
  };
  
  return (
    
    <div className="w-full mx-auto"  style={{border: "2px solid var(--Foundation-Alert-gray-Normal, #v808080)",}}>
      <form onSubmit={handleSubmit} className="space-y-4">
      {showLimitExceededMessage && (
          <div
          className="w-full  "
          style={{
            display: "flex",
            padding: "16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            borderRadius: "13px",
            border: "2px solid var(--Foundation-Alert-red-Normal, #EF6950)",
            background: "var(--Foundation-Alert-red-Light, #FDF0EE)",
            boxShadow: "2px 2px 8px 0px rgba(239, 105, 80, 0.10)",
            alignSelf: "stretch",
          }}
        >
          {/* Flex container for SVG and text */}
          <div
            style={{
              display: "flex",
              alignItems: "center", // Vertically align icon and text
              gap: "8px", // Gap between icon and text
            }}
          >
            {/* SVG Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M10.3633 4.45728L2.25653 18.517C2.08938 18.8177 2.00094 19.1587 2.00001 19.5059C1.99907 19.8531 2.08567 20.1946 2.25119 20.4963C2.41671 20.7979 2.65538 21.0493 2.94346 21.2254C3.23153 21.4015 3.55897 21.4962 3.8932 21.5H20.1068C20.441 21.4962 20.7685 21.4015 21.0565 21.2254C21.3446 21.0493 21.5833 20.7979 21.7488 20.4963C21.9143 20.1946 22.0009 19.8531 22 19.5059C21.9991 19.1587 21.9106 18.8177 21.7435 18.517L13.6367 4.45728C13.466 4.16505 13.2258 3.92345 12.9391 3.75577C12.6524 3.58809 12.329 3.5 12 3.5C11.671 3.5 11.3476 3.58809 11.0609 3.75577C10.7742 3.92345 10.534 4.16505 10.3633 4.45728Z"
                stroke="#EF6950"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 9.5V13.5"
                stroke="#EF6950"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17V17.5"
                stroke="#EF6950"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
        
            {/* User limit exceeded text */}
            <div
              style={{
                color: "var(--Foundation-Alert-red-Normal, #EF6950)",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "120%", // 19.2px
              }}
            >
              User limit exceeded
            </div>
          </div>
        
          {/* Additional content */}
          <div
            style={{
              color: "var(--Foundation-Alert-red-Normal, #EF6950)",
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "120%", // 19.2px
              alignSelf: "stretch",
            }}
          >
            The limit of 10 users has been exceeded in this account.
          </div>
        </div>
        
        
        )}
      {message && (
        <UserAddedNotification message={message} />
      )}
        {isSuperAdmin && (
          <>
                  {/* <div className="flex items-center gap-2.5 mb-4 justify-start border-b border-solid border-b-zinc-300 pb-4">
         <TabButton
          label="Administrator"
          selected={activeTab === 'createTenant'}
          onClick={() => handleTabChange('createTenant')}
        />
         <TabButton
          label="User"
          selected={activeTab === 'addUser'}
          //onClick={() => handleTabChange('addUser')}
        /> 
      </div> */}
      {activeTab === 'createTenant' ? (
        <>
          <div className="flex space-x-4 px-2.5">
          <div className="flex-grow">
            <InputField
               label="Team Name"
               placeholder="Enter team name"
               value={teamName}
               onChange={(e) => setTeamName(e.target.value)}
               className={errors.teamName ? 'border-red-500' : ''}
            />
          </div>
          <div className="w-1/3 relative">
          <div className="relative border border-gray-300 rounded-[7px] focus-within:ring-1 focus-within:ring-black focus-within:border-black overflow-hidden">
              <input
                type="number"
                min="1"
                max="50"
                value={teamSize}
                onChange={(e) => setTeamSize(Math.min(50, Math.max(1, e.target.value)))}
                className={`w-full px-3 pt-6 pb-2 text-sm appearance-none focus:outline-none ${errors.teamSize ? 'border-red-500' : ''}`}
                placeholder=" "
              />
              <label className="absolute text-xs text-gray-500 top-2 left-3 transition-all duration-300 pointer-events-none">
                Members
              </label>
            </div>
            <span className="text-xs text-gray-400 font-inter mt-1 block">Members want to add</span>
          </div>
        </div>
        </>
      ) : ( 
          <SelectField
          label="Select Administrator"
          options={userGroupNames}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          className={errors.teamName ? 'border-red-500' : ''}
          />
        )}
    </>
    )}
        
        <div className="border-b border-solid border-b-zinc-300 my-1"></div>
        <div className="flex space-x-4 px-2.5 pt-2.5">
          <div className="flex-1">
            <InputField
              label="First name"
              placeholder="John"
              value={firstName}
              onChange={handleFirstNameChange}  // Updated to use new handler
              disabled={!isSuperAdmin && isReadOnly}
              className={errors.firstName ? 'border-red-500' : ''}
            />
          </div>
          <div className="flex-1">
            <InputField
              label="Last name"
              placeholder="Doe"
              value={lastName}
              onChange={handleLastNameChange}  // Updated to use new handler
              disabled={!isSuperAdmin && isReadOnly}
              className={errors.lastName ? 'border-red-500' : ''}
            />
          </div>
        </div>
        
        <div className='px-2.5'>
        <InputField
          label="User email"
          placeholder="Johndoe@gmail.com"
          type="email"
          value={email}
          disabled={!isSuperAdmin && isReadOnly}
          onChange={(e) => setEmail(e.target.value)}
          className={errors.email ? 'border-red-500' : ''}
        />
        
        <InputField
          label="Confirm User email"
          placeholder="Johndoe@gmail.com"
          type="email"
          value={confirmEmail}
          disabled={!isSuperAdmin && isReadOnly}
          onChange={(e) => setConfirmEmail(e.target.value)}
          className={errors.confirmEmail ? 'border-red-500' : ''}
        />
        </div>
        
        {/* Line above the Add user button */}
      <div className="border-b border-solid border-b-zinc-300 my-3 "></div>
        <div className="flex justify-end px-2.5 ">
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={!isSuperAdmin && isReadOnly}
            className={`px-4 py-2 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-300
              ${
                (!isSuperAdmin && isReadOnly)
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-amber-500'
              }`}
          >
            Add user
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddUserForm;
