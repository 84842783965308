import React, { useRef, useState, useEffect, useContext } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { conversationState, responseErrorState, tableLoadingState  } from "../../store/atoms";
//import { Amplify } from 'aws-amplify';
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { MyContext } from "../../Context/MyContext";
import { logEvent } from '../../cloudwatchLogger';
import {MoveUpRight} from 'lucide-react';
import useLambda from '../hooks/useLambda';
import { awsExports } from '../../aws-exports';
import AWS from 'aws-sdk';
import Sparkles from "./Sparkles";
import { getCurrentUser, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import {jwtTokenState} from '../../store/atoms';
//Amplify.configure(awsExports);
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';

import {
  conversationIdState
} from '../../store/atoms';

const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};



const DropdownItem = ({ text, onClick }) => (
  <div 
    className="w-full hover:bg-gray-100 cursor-pointer rounded-md transition-colors duration-200"
    onClick={onClick}
  >
    <div className="flex items-center p-3">
      <MoveUpRight className="w-4 h-4 mr-3 text-gray-600" />
      <span className="text-sm font-inter">{text}</span>
    </div>
    {/* <button onClick={(e) => { e.stopPropagation(); onRemove(); }} className="text-yellow-500 hover:text-yellow-700">
      Remove
    </button> */}
  </div>
);

const PromptBar = ({ selectedQuery }) => {
  const [selectedManagedQuery, setSelectedManagedQuery] = useState('');
  const [conversation, setConversation] = useRecoilState(conversationState);
  const [conversationId, setConversationId] = useRecoilState(conversationIdState);
  const [tableLoading, setTableLoading] = useRecoilState(tableLoadingState)
  //console.log('conversation in prompt bar: '+conversation.length);
  const [inputQuestion, setInputQuestion] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localTranscript, setLocalTranscript] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { value, setValue, isDarkTheme } = useContext(MyContext);
  const [error, setError] = useState(null);
  //const [logEvents, setLogEvents] = useRecoilState(logEventsState);
  const [firstName, setFirstName] = useState("");
  const {configureLambda, decodeToken,getJWT } = useLambda();
  const [responseError, setResponseError] = useRecoilState(responseErrorState);

  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
  const [tenantGroup, setTenantGroup] = useState([]);
  const [jwtToken, setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [sub, setSub] = useState('');
  const isMobile = useMediaQuery('(max-width:768px)');

  const [managedQueries, setManagedQueries] = useState([]);
  //console.log('table loading state is: '+ tableLoading);

  useEffect(() => {
    const fetchTokenAndSub = async () => {
      try {
        const token = await getJWT();
        await configureLambda(token);
        setJwtToken(token);
        const decodedToken = decodeToken(token);
        const tenantgroup = decodedToken['cognito:groups'];
        let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
        setTenantGroup(filteredGroups);
        const cognitoRoles = decodedToken['cognito:roles'];
        if (cognitoRoles) {
          setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
          setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
        }
        setSub(decodedToken['sub']);
      } catch (error) {
        console.error('Failed to fetch token:', error);
        logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
      }
    };

    fetchTokenAndSub();
  }, []);

  useEffect(() => {
    const fetchSavedQueries = async () => {
      if (!sub) return;
      try {
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getSavedQueries', { 'created_by':sub }, lambda, lambdaFunctionName);
        // console.log('managed queries for saved is: '+JSON.stringify(data));
        setManagedQueries(data);
      } catch (error) {
        console.error('Error fetching saved queries:', error);
      }
    };

    const fetchUserSavedQueries = async () => {
      //console.log("inside fetch user saved queries");
      if (!tenantGroup) return;
      try {
        //console.log(`tenant group inside fetch user group is : ${tenantGroup}`);
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getUserSavedQueries', { 'groupname':tenantGroup[0] }, lambda, lambdaFunctionName);
        //console.log('managed queries for user-saved is: '+JSON.stringify(data));
        setManagedQueries(data);
      } catch (error) {
        console.error('Error fetching saved queries:', error);
      }
    };

    if (isSuperAdmin || isTenantAdmin) {
      fetchSavedQueries();
    } else if (tenantGroup.length > 0) {
      fetchUserSavedQueries();
    }
  }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        //console.log(`user atttributes are: ${JSON.stringify(userAttributes)}`);
        setFirstName(userAttributes.given_name);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      }
    };
  
    fetchUserName();
  }, []);
   
  const apiUrl = awsExports.API.apiurl;

  const updateUserActivity = async (queryText) => {
    try {
        const attributes = await fetchUserAttributes();
        
        // Get current date and existing query count
        const today = new Date().toISOString().split('T')[0];
        // Handle null/undefined cases
        const lastQueryDate = attributes['custom:lastQueryDate'] || '';
        const queryCount = attributes['custom:dailyQueryCount'];
        
        // Calculate new query count
        let newQueryCount = 1;
        if (lastQueryDate === today) {
          // Use 0 as fallback if queryCount is null/undefined or not a valid number
          newQueryCount = parseInt(queryCount || '0', 10) + 1;
        }
        // Update user attributes
        await updateUserAttributes({
          userAttributes: {
            'custom:lastQuery': queryText,
            'custom:lastQueryTime': Date.now().toString(),
            'custom:lastQueryDate': today,
            'custom:dailyQueryCount': newQueryCount.toString()
          }
        });
    } catch (error) {
        console.error('Error updating user attributes:', error);
    }
};

  const handleInputChange = (e) => {
    setInputQuestion(e.target.value);
    setError(null); // Clear error on new input
  };

  const handleQueryClick = (query) => {
    setSelectedManagedQuery(query);
    logEvent({ eventType: 'querySelected', details: `Selected query: ${query}` });
  };

  const generateAnswer = async (prompt) => {
    // Get the JWT token
    const tokenJWT = await getJWT();
    //const tokenJWT = useRecoilValue(jwtTokenState)
    //console.log('token is: ' + tokenJWT);
   
    const postData = { 
      user_prompt: prompt,
      token: tokenJWT,
      conversation_id: conversationId,
    };
   
    // try {
    //   // Make the API request with both Authorization and auth-token headers
    //   const response = await axios.post(apiUrl, postData, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${tokenJWT}`, // Bearer token for Authorization
    //       // 'auth-token': tokenJWT,              // auth-token in the header
    //       'Content-Type': 'application/json',
    //     },
    //   }
    // );
   
    //   const answer = response.data;
    //   //console.log('answer is: '+JSON.stringify(answer));
    //   const parsedRes = JSON.parse(answer.body);
    //   setConversationId(parsedRes.conversation_id);
    //   //console.log('The ID is: ' + parsedRes.conversation_id);
    //   setError(null); // Clear any previous errors
    //   return answer;
   
    // } catch (error) {
    //   console.error("Error:", error);
    //   setError("Failed to fetch the response. Please try again.");
    //   throw error; // Re-throw the error if needed elsewhere
    // }

    try {
      // Make the API request with a 20-second timeout
      const response = await axios.post(apiUrl, postData, {
        headers: {
          Authorization: `Bearer ${tokenJWT}`, // Bearer token for Authorization
          'Content-Type': 'application/json',
        },
        timeout: 60000, // Set timeout to 45 seconds (45000 milliseconds)
      });
    
      const answer = response.data;
      //console.log('answer is: '+JSON.stringify(answer));
      const parsedRes = JSON.parse(answer.body);
      setConversationId(parsedRes.conversation_id);
      setError(null); // Clear any previous errors
      return answer;
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        console.error('Request timed out:', error);
        setError('The request took too long to complete. Please try again.');
        setResponseError('The request took too long to complete. Please try again.')
      } else {
        console.error('An error occurred:', error);
        setResponseError(error.message || 'Request is missing key information needed to process accurate results. Please clarify or rephrase and try again.');
        setError(error.message || 'An unexpected error occurred.');
      }
    }
  };


  const handleSend = async () => {
    const userPrompt = inputQuestion || value; // Use the context value if inputQuestion is empty
    setValue(""); // Reset the context value after using it
    setIsLoading(true);
    setLocalTranscript(userPrompt);
    logEvent({ action: 'UserInput', message: userPrompt, type: 'input' });
    
    try {
      //console.log('this si user prompt: '+userPrompt);
      await updateUserActivity(userPrompt);
      const answer = await generateAnswer(userPrompt);
      const newConversation = {
        id: new Date().getTime(),
        date: new Date(),
        question: userPrompt,
        response: answer,
      };
      setConversation([newConversation, ...conversation]);
      setInputQuestion("");
      logEvent({ action: 'AIResponse', message: 'AI response generated', type: 'response' });
    } catch (error) {
      console.error("Error:", error);
      
      logEvent({ action: 'Error', message: 'Failed to generate AI response', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevents the default action of the Enter key (e.g., form submission)
      if (!isLoading) {
        handleSend();
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading && conversation.length === 0) {
      setTableLoading(true); //navigate('/sparkles');
    } else if (conversation.length > 0) {
      setTableLoading(false);
      navigate('/targetPage');
    }
  }, [isLoading, conversation, navigate]);

  const handleClear = () => {
    setInputQuestion("");
  };

  useEffect(() => {
    if (selectedQuery) {
      setInputQuestion(selectedQuery);
    }
  }, [selectedQuery]);

  useEffect(() => {
    if (value) {
      setInputQuestion(value);
    }
  }, [value]);

  useEffect(() => {
    if (selectedManagedQuery) {
      setInputQuestion(selectedManagedQuery);
    }
  }, [selectedManagedQuery]);

  const handleDropdownItemClick = (item) => {
    setInputQuestion(item);
    setShowDropdown(false);
    inputRef.current.focus();
  };

  const handleRemoveItem = (item) => {
    // Implement remove logic here
    // console.log('Removing:', item);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const promptBarBackgroundColor = inputQuestion ? 'bg-white' : 'bg-zinc-100';

  

  const buttonClass = `
    w-full min-w-[120px] h-[40px] min-h-[40px] rounded-2xl 
    bg-[#f1b20b] text-center p-2 text-[#ffffff] font-bold mb-2
    whitespace-nowrap overflow-hidden text-ellipsis
  `;

  const LogoSVG = ({ isActive }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11.7891 21.8977C17.3119 21.8977 21.7891 17.4206 21.7891 11.8977C21.7891 6.37486 17.3119 1.89771 11.7891 1.89771C6.26621 1.89771 1.78906 6.37486 1.78906 11.8977C1.78906 17.4206 6.26621 21.8977 11.7891 21.8977Z" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.7891 15.8977C13.9982 15.8977 15.7891 14.1068 15.7891 11.8977C15.7891 9.68857 13.9982 7.89771 11.7891 7.89771C9.57992 7.89771 7.78906 9.68857 7.78906 11.8977C7.78906 14.1068 9.57992 15.8977 11.7891 15.8977Z" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.71875 4.82764L8.95875 9.06764" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6191 14.7278L18.8591 18.9678" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6191 9.0676L18.1491 5.5376" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.71875 18.9678L8.95875 14.7278" stroke={isActive ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
  
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [inputQuestion]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to get the correct scrollHeight
      textarea.style.height = 'auto';
      // Calculate new height while respecting line breaks
      const newHeight = Math.min(textarea.scrollHeight, 200); // Max height 200px
      textarea.style.height = `${newHeight}px`;
    }
  };

  const handleTextareaChange = (e) => {
    handleInputChange(e);
    adjustTextareaHeight();
  };

    // <div 
    //   className="w-[800px] flex items-center"
    //   style={{
    //     padding: '14px',
    //     borderBottom: '1px solid var(--foundation-primary-black-light-hover, #D9D9D9)',
    //     background: 'var(--Primary-white, #FFF)'
    //   }}
    // >
    //   {showDropdown && (
    //     <div 
    //       ref={dropdownRef} 
    //       className={`absolute bottom-full left-0 right-0 flex flex-col items-start p-1.5 gap-1.5 rounded-[14px] border border-[#F2F2F2] bg-white shadow-lg mb-2 max-h-[270px] overflow-y-auto ${isMobile ? 'w-full' : 'w-[640px]'}`}
    //     >
    //       {managedQueries.map((item) => (
    //         <div 
    //           key={item.id} 
    //           onClick={() => handleQueryClick(item.query_name)}
    //           className="cursor-pointer w-full p-2 hover:bg-gray-100"
    //         >
    //           {item.query_name}
    //         </div>
    //       ))}
    //     </div>
    //   )}
      
    //   <div className="flex flex-1 items-center gap-[14px]">
    //     <input 
    //       type="text" 
          // value={inputQuestion} 
          // onChange={handleInputChange} 
          // onKeyDown={handleKeyDown} 
    //       onFocus={() => setIsInputFocused(true)} 
    //       onBlur={() => setIsInputFocused(false)} 
    //       className="flex-1 px-[14px] py-[14px] outline-none focus:outline-none"
    //       placeholder="Type your query here"
    //       ref={inputRef}
    //       style={{
    //         color: inputQuestion ? 'black' : 'var(--foundation-primary-black-light-hover, #D9D9D9)',
    //         fontFamily: 'Inter',
    //         fontSize: '25px',
    //         fontStyle: 'normal',
    //         fontWeight: 700,
    //         lineHeight: '120%',
    //         border: 'none'
    //       }}
    //     />
        
        // {isLoading ? (
        //   <div className="flex-shrink-0 p-2">
        //     <FontAwesomeIcon icon={faSpinner} className="w-6 h-6 text-black-400" spin />
        //   </div>
        // ) : (
    //       <button 
    //         className={`flex-shrink-0 p-2 rounded-full transition-colors duration-200 ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
    //         onClick={handleSend} 
    //         disabled={isLoading} 
    //       >
    //         {inputQuestion ? (
    //           <img src="/magic-wand-send2.svg" alt="Send" className="w-6 h-6" />
    //         ) : (
    //           <img src="/magic-wand-send.svg" alt="Send" className="w-6 h-6" />
    //         )}
    //       </button>
    //     )}
    //   </div>
    // </div>
    return (
      <form className="flex flex-wrap gap-3.5 w-[50rem] items-start p-3.5 bg-white border-b border-solid border-b-zinc-300">
        <div className="flex-1 relative">
          <textarea
            ref={textareaRef}
            id="searchQuery"
            value={inputQuestion}
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown}
            rows={1}
            className={`${
              isLoading
                ? 'bg-gradient-to-r from-[#EBAE12] via-[#40C5AF] to-[#EBAE12] bg-[length:200%_auto] animate-slowflow text-transparent bg-clip-text'
                : 'text-black'
            } font-inter text-2xl font-bold leading-tight w-full resize-none 
              min-h-[2.5rem] max-h-[200px] overflow-y-auto 
              placeholder-zinc-300 focus:outline-none focus:ring-0 caret-black
              scrollbar-thin scrollbar-thumb-zinc-300 scrollbar-track-transparent`}
            placeholder="Type your query here"
            aria-label="Search query input"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word'
            }}
          />
          {/* {isLoading && (
            <div 
              className="absolute top-0 left-0 w-full h-full pointer-events-none
                         font-inter text-2xl font-bold leading-tight text-black
                         whitespace-pre-wrap overflow-y-auto"
            >
              {inputQuestion}
            </div>
          )} */}
        </div>
        <div className="flex-shrink-0 self-start">
          {isLoading ? (
            <div className="p-2">
              <FontAwesomeIcon icon={faSpinner} className="w-6 h-6 text-black-400" spin />
            </div>
          ) : (
            <button
              type="submit"
              aria-label="Submit search"
              className="p-2 rounded-full transition-colors duration-200 cursor-pointer"
              onClick={handleSend}
            >
              <img 
                src={inputQuestion ? "/magic-wand-send2.svg" : "/magic-wand-send.svg"} 
                alt="Send" 
                className="w-6 h-6" 
              />
            </button>
          )}
        </div>
      </form>
    );
  };
  
  export default PromptBar;