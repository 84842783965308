  // import React from 'react';

  // const ExpirationPopup = ({ onLogout, onRefresh }) => (
  //   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
  //     <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
  //       <h2 className="text-xl font-bold mb-4 text-center">
  //         Your session is about to expire
  //       </h2>
  //       <p className="text-center mb-6">
  //         You'll be logged out soon.
  //       </p>
  //       <div className="flex justify-center space-x-4">
  //         <button
  //           onClick={onRefresh}
  //           className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
  //         >
  //           Refresh Session
  //         </button>
  //         <button
  //           onClick={onLogout}
  //           className="px-4 py-2 bg-yellow-400 text-black rounded hover:bg-yellow-500 transition-colors"
  //         >
  //           Logout Now
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );

  // export default ExpirationPopup;

  import React, { useState, useEffect } from 'react';
  import { fetchAuthSession, signOut, getCurrentUser, updateUserAttribute } from 'aws-amplify/auth';
  import { useNavigate } from 'react-router-dom';

  const ExpirationPopup = ({ 
    onRefresh, 
    exp,
    onClose
  }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
      const calculateTimeLeft = () => {
        const expirationTime = exp * 1000; // Convert to milliseconds
        const now = Date.now();
        const difference = expirationTime - now;
        return Math.max(Math.floor(difference / 1000), 0); // Ensure non-negative
      };

      const initialTimeLeft = calculateTimeLeft();
      setTimeLeft(initialTimeLeft);

      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        setTimeLeft(newTimeLeft);

        if (newTimeLeft <= 0) {
          clearInterval(timer);
          // Automatically handle session expiration
          handleSessionExpiration();
        }
      }, 1000);

      return () => clearInterval(timer);
    }, [exp]);

    const handleSessionExpiration = async () => {
      try {
        // Attempt to fetch auth session
        await fetchAuthSession();
      } catch (error) {
        // If authentication fails, use the provided navigation callback
        console.error("Not authenticated, redirecting to signin");
        await handleLogout();
      }
    };

    async function updateLastLogin() {
      try {
        const currentUser = await getCurrentUser();
        if (!currentUser) {
          // console.log('No authenticated user found');
          return;
        }
    
        
        const loginTime = localStorage.getItem('loginTime');
        // console.log('Setting login time to:', loginTime);
    
            // Try with explicit attributes object format
            const result = await updateUserAttribute({
              userAttribute: {
                attributeKey: 'custom:loginTime',
                value: loginTime
              }
            });
    
        // console.log('Last login updated successfully', result);
      } catch (error) {
        console.error('Error updating last login:', error);
        if (error instanceof Error) {
          console.error('Error type:', error.constructor.name);
          console.error('Error message:', error.message);
          console.error('Full error:', error);
        }
      }
    }

    const handleLogout = async () => {
      try {
        await updateLastLogin();
        // Mark MFA setup as false
        await updateUserAttribute({ 
          userAttribute: {
            attributeKey: 'custom:mfa_setup_completed',
            value: 'false' 
          }
        });
        await signOut();
        navigate('/signin', { replace: true });
      } catch (error) {
        console.error('Error signing out:', error);
      }
    };

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleManualRefresh = async () => {
      try {
        // Call the provided onRefresh callback
        if (onRefresh) {
          await onRefresh();
        }
        // Attempt to fetch auth session to validate refresh
        await fetchAuthSession();
        onClose();
      } catch (error) {
        // If refresh fails, use the provided navigation callback
        console.error("Session refresh failed, redirecting to signin");
        await handleLogout();
        // if (onSessionExpire) {
        //   onSessionExpire();
        // }
      }
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center text-red-500">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.3633 3.95728L2.25653 18.017C2.08938 18.3177 2.00094 18.6587 2.00001 19.0059C1.99907 19.3531 2.08567 19.6946 2.25119 19.9963C2.41671 20.2979 2.65538 20.5493 2.94346 20.7254C3.23153 20.9015 3.55897 20.9962 3.8932 21H20.1068C20.441 20.9962 20.7685 20.9015 21.0565 20.7254C21.3446 20.5493 21.5833 20.2979 21.7488 19.9963C21.9143 19.6946 22.0009 19.3531 22 19.0059C21.9991 18.6587 21.9106 18.3177 21.7435 18.017L13.6367 3.95728C13.466 3.66505 13.2258 3.42345 12.9391 3.25577C12.6524 3.08809 12.329 3 12 3C11.671 3 11.3476 3.08809 11.0609 3.25577C10.7742 3.42345 10.534 3.66505 10.3633 3.95728Z" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 9V13" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16.5V17" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span className="font-semibold">Session out</span>
            </div>
          </div>
          <div className="border-b border-solid border-b-zinc-300 my-1"></div>
          <p className="text-gray-600 mb-4">
            The session is set to expire shortly, and it is kindly requested that the session be refreshed.
          </p>
          <div className="text-center mb-6">
            <span className="text-5xl font-bold text-red-500">{formatTime(timeLeft)}</span>
            <span className="text-gray-500 ml-2">min</span>
          </div>
          <button
            onClick={handleManualRefresh}
            className="w-full px-4 py-2 bg-yellow-400 text-black rounded-lg hover:bg-yellow-500 transition-colors font-semibold"
          >
            Refresh session
          </button>
        </div>
      </div>
    );
  };

  export default ExpirationPopup;