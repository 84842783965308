import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { useRecoilState, useSetRecoilState  } from 'recoil';
import { conversationState, pinnedQueriesState, responseErrorState   } from '../../store/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faSpinner, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from '../../cloudwatchLogger';
import { faHeart, faRedo, faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { SiMicrosoftexcel } from 'react-icons/si';
import { Pin, Loader, Check } from 'lucide-react';
import { jsPDF } from "jspdf";
import { FileQuestion, X} from 'lucide-react';
import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, BorderStyle, HeadingLevel, WidthType } from "docx";
import { useRecoilValue } from "recoil";
import { saveAs } from "file-saver";
import { utils, write } from 'xlsx';
import { Fragment } from "react";
import { importNotificationsState } from '../../store/atoms';
import useLambda from '../hooks/useLambda';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';
import { useMediaQuery } from '@mui/material';
import { Maximize2 } from 'lucide-react';
import '../../styles.css';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import {
  jwtTokenState, 
  subState,
  isSuperAdminState,
  isTenantAdminRoleState
} from '../../store/atoms';

// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    // console.log('knowledge base is: '+JSON.stringify(result));
    //console.log('result from the lambda is: '+JSON.stringify(result.data));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

const extractFirstKeys = (table) => {
  return table.map((item, index) => {
    const firstKey = Object.keys(item)[0];
    const firstVal = item[firstKey];
    return (
      <li key={index}>
        {index + 1}. {firstVal}
      </li>
    );
  });
};

const formatTime = (time) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  return `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
};

// Modal component
const Modal = ({ onClose, children }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl w-96 max-w-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-inter font-bold text-gray-800">unpin a query to add a new one</h2>
        <button 
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>
      </div>
      <div className="modal-content mb-4">{children}</div>
    </div>
  </div>
);

const ModalContent = ({ pinnedQueries, queryText, setPinnedQueries, setModalOpen, deleteQuery, addQuery }) => {
  const handleUnpinAndPin = async (oldQueryId) => {
    try {
      // Unpin the old query (delete it)
      await deleteQuery(oldQueryId);
      //console.log('delete query with id: '+oldQueryId)
      // Update the pinnedQueries state by removing the query with the oldQueryId
      setPinnedQueries((prevQueries) =>
        prevQueries.filter((query) => query.id !== oldQueryId)
      );
      //console.log('pinned queries are: '+pinnedQueries);

      const data = await addQuery(queryText);
      //console.log("this is my querytext3" +queryText);
      const newQuery = {
          icon: <FileQuestion size={24} className="text-yellow-600" />,
          text: data[0].query_name,
          isPinned: true,
          id: data[0].id
        };
        const updatedQueries = [...pinnedQueries, newQuery];
        setPinnedQueries(updatedQueries);

      // // Add the new query to the pinnedQueries state
      // setPinnedQueries((prevQueries) => [...prevQueries, newQuery]);

      // Close the modal after pinning the new query
      setModalOpen(false);
    } catch (error) {
      console.error("Error unpinning and pinning query:", error);
    }
  };

  return (
    <div className="space-y-3">
      {pinnedQueries.map((pinnedQuery) => (
        <div key={pinnedQuery.id} className="flex justify-between items-center p-2 bg-gray-100 rounded-lg">
          <span className="text-gray-800 font-inter">{pinnedQuery.text}</span>
          <button
            className="bg-yellow-400 text-white font-inter px-3 py-1 rounded-full hover:bg-yellow-500 transition-colors"
            onClick={() => handleUnpinAndPin(pinnedQuery.id)}
          >
            Unpin & Pin
          </button>
        </div>
      ))}
    </div>
  );
};

const PinButton = ({ handlePinToggle, pinnedQueries, queryText }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    // console.log("handleClick called");
    setIsLoading(true);
    try {
      await handlePinToggle();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error pinning query:", error);
    }
  };
  return (
    <button
      onClick={handleClick}
      className="mt-4 ml-2 text-black-500 relative"
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader className="animate-spin" size={16} />
      ) : (
        <Pin size={16} fill="none" />
      )}
    </button>
  );
};

const ConversationCard = ({ 
  chat, 
  isResponse,
  initialResponse ,
}) => {
  // const [response, setResponse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isExporting, setIsExporting] = useState(null);
  const [notification, setNotification] = useState("");
  const conversation = useRecoilValue(conversationState);
  const setConversation = useSetRecoilState(conversationState)
  //const [logEvents] = useRecoilState(logEventsState);
  const [pinnedQueries, setPinnedQueries] = useRecoilState(pinnedQueriesState);
  const [responseError, setResponseError] = useRecoilState(responseErrorState);
  //const setLogEvents = useSetRecoilState(logEventsState);
  //const [isPinned, setIsPinned] = useState(false);
  const queryText = chat.question;
  const isPinned = pinnedQueries.some((pinnedQuery) => pinnedQuery.text === queryText);
  const [isModalOpen, setModalOpen] = useState(false);
  const setImportNotifications = useSetRecoilState(importNotificationsState);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
  const [isTablePopup, setIsTablePopup] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [tenantGroup, setTenantGroup] = useState([]);
  const { decodeToken, getJWT } = useLambda();

  const [jwtToken,setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState('');
  const [isTenantAdmin, setIsTenantAdmin] = useState('');
  const [sub,setSub] = useState('');
  const tableRef = useRef(null);

  const isMobile = useMediaQuery('(max-width:768px)');
  const [showFullText, setShowFullText] = useState({});
  // const jwtToken = useRecoilValue(jwtTokenState);
  // const isSuperAdmin = useRecoilValue(isSuperAdminState);
  // const isTenantAdmin = useRecoilValue(isTenantAdminRoleState);
  // const sub = useRecoilValue(subState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [response, setResponse] = useState(initialResponse);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isWordLoading, setIsWordLoading] = useState(false);


  const updateNotifications = (success, message, timestamp) => {
  setImportNotifications((prevNotifications) => [
    ...prevNotifications,
    { success, message, timestamp: new Date().getTime() },
  ]);
};


  const handlePinToggle = async () => {
     if (pinnedQueries.length < 6) {
      // Add a new pinned query
      try {
        //console.log("inside else if the size is less than 6", queryText);
        const data = await addQuery(queryText);  // Call addQuery to add to DB
        //console.log('data inside handle pin toggle is : '+JSON.stringify(data));
        const newQuery = {
          icon: <FileQuestion size={24} className="text-yellow-600" />,
          text: data[0].query_name,
          isPinned: true,
          id: data[0].id
        };
        const updatedQueries = [...pinnedQueries, newQuery]; // Update state
        setPinnedQueries(updatedQueries);
        showNotification("Query pinned successfully!");
      } catch (error) {
        console.error("Error pinning query:", error);
        showNotification("Failed to pin query. Please try again.");
      }
    } else {
      //console.log('if there are already 6 queries', queryText)
      // If there are already 6 pinned queries, open the modal to unpin
      setModalOpen(true);
    }
  };
  
  useEffect(() => {
    //console.log('Pinned Queries updated:', pinnedQueries);
  }, [pinnedQueries]);

  
  

  const addQuery = async (query) => {
    const lambda = new AWS.Lambda();
    const payload = {
      'created_by' :  sub,
      'pinned_query': query
      }
    // Invoke Lambda function to add the query to the database
    const data = await invokeLambda('addPrompts', payload, lambda, lambdaFunctionName);
    
    //console.log('Response from addPrompt Lambda:', JSON.stringify(data));
    return data;
    // If you want to update availableQueries based on the Lambda response
    // setAvailableQueries(data);
    
    setTimeout(() => {
      logEvent({ eventType: 'queryPinned', details: `Pinned query: ${query.id}` });
    }, 0);
  };

  const deleteQuery = async (id) => {
    //console.log(`Deleting query with id: ${id}`);
  
    const updatedDeleteQueries = pinnedQueries.filter((query) => query.id !== id);
    setPinnedQueries(updatedDeleteQueries);

    const lambda = new AWS.Lambda();
    const deleteData = await invokeLambda('deletePrompts',{ 'id':id }, lambda, lambdaFunctionName);
    setTimeout(() => {
      logEvent({ eventType: 'queryUnpinned', details: `Unpinned query with id: ${id}` });
    }, 0);
  };
  //console.log('chat is : '+JSON.stringify(chat));
  useEffect(() => {
    if (chat.response?.body) {
      try {
        const parsedResponse = JSON.parse(chat.response.body);
        // console.log('parsedResponse is : '+JSON.stringify(parsedResponse));
        setResponse(parsedResponse);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        setResponse({ summary: chat.response.body, table: [] });
      }
    }
  }, [chat.response?.body, isResponse]);

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
      tableRef.current.scrollLeft = 0;
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const disablePreviousButton = currentPage === 1;
  const disableNextButton = currentPage * rowsPerPage >= response?.table?.length;

  const toTitleCase = (str) => {
    if (!str) return '';
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  const exportToExcel = (id, question) => {
    logEvent({
      action: 'UserExportedResponse',
      details: 'Export to Excel was performed by the user',
      type: 'excel'
    });
    setIsExporting(id);
  
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat?.response?.body) {
          showNotification('Failed to export: No response available to export in excel format.');
          return;
        }
  
        const jsonResponse = JSON.parse(chat.response.body);
        if (!jsonResponse.table) {
          throw new Error('No Data to export from the prompt');
        }
  
        const keys = [...new Set(jsonResponse.table.flatMap(Object.keys))];
        const data = jsonResponse.table.map(donor => {
          let row = {};
          keys.forEach(key => {
            const value = donor[key];
            // Format Full Name in title case
            if (key === 'Full Name' || key === 'City' || key === 'Occupation' || key === 'Employer') {
              row[key] = toTitleCase(value);
            }
            // Format street address according to US standards
            else if (key === 'Street Address' || key === 'Address') {
              row[key] = formatStreetAddress(value);
            }
            // Format currency for amount columns
            else if (
              key.includes('Total') ||
              key.includes('Contribution') ||
              key.includes('Amount') ||
              key.includes('Donation') ||
              key.includes('Donated') ||
              key.includes('Donations')
            ) {
              row[key] = typeof value === 'number' ? `$${value.toLocaleString('en-US')}` : value || '';
            }
            // Format phone numbers by adding + prefix
            else if (key === 'Phone Number') {
              row[key] = value ? `+${value}` : '';
            }
            else {
              row[key] = value || '';
            }
          });
          return row;
        });
  
        const description = jsonResponse.description || '';
  
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(data, { header: keys });
  
        if (description) {
          const cellRef = utils.encode_cell({r: 0, c: 0});
          if (!ws[cellRef]) ws[cellRef] = {};
          ws[cellRef].c = [{ a: "Author", t: description }];
        }
  
        utils.book_append_sheet(wb, ws, "Donors");
        const wbout = write(wb, { bookType: "xlsx", type: "binary" });
        const fileName = `${sanitizeFileName(question)}-Donors.xlsx`;
        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
        saveAs(blob, fileName);
  
        showNotification("Export successful!");
        updateNotifications(true, "Excel export successful", new Date().getTime());
      } catch (error) {
        console.error('Failed to export to Excel:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "CSV export failed: " + error.message, new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const exportToPDF = (id, question) => {
    logEvent({ action: 'UserExportedResponse', details: 'Export to PDF was performed by the user', type: 'pdf' });
    setIsExporting(id);
  
    const formatValue = (key, value) => {
      if (!value) return '';
  
      // Format Full Name, City, Occupation, Employer in title case
      if (key === 'Full Name' || key === 'City' || key === 'Occupation' || key === 'Employer') {
        return toTitleCase(value);
      }
  
      // Format Street Address according to US standards
      if (key === 'Street Address' || key === 'Address') {
        return formatStreetAddress(value);
      }
  
      // Format phone numbers
      if (key === 'Phone Number') {
        return `+${value}`;
      }
  
      // Format currency values
      if (
        (key.includes('Total') ||
        key.includes('Contribution') ||
        key.includes('Amount') ||
        key.includes('Donation') ||
        key.includes('Donated') ||
        key.includes('Donations')) &&
        typeof value === 'number'
      ) {
        return `$${value.toLocaleString('en-US')}`;
      }
  
      return value;
    };
  
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat?.response?.body) {
          showNotification('No response available to export pdf.');
          return;
        }
  
        const doc = new jsPDF();
        const jsonResponse = JSON.parse(chat.response.body);
  
        doc.setFontSize(12);
        const lineHeight = 7;
        let yPosition = 20;
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 10;
        const maxWidth = pageWidth - 2 * margin;
  
        const addText = (text, isBold = false) => {
          if (isBold) doc.setFont(undefined, 'bold');
          const lines = doc.splitTextToSize(text, maxWidth);
          lines.forEach(line => {
            if (yPosition > 280) {
              doc.addPage();
              yPosition = 20;
            }
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
          });
          if (isBold) doc.setFont(undefined, 'normal');
          yPosition += 5;
        };
  
        addText(`Question: ${question}`, true);
  
        if (jsonResponse.description) {
          addText(jsonResponse.description);
        }
  
        if (jsonResponse.table?.length > 0) {
          addText("Table Data:", true);
          jsonResponse.table.forEach((row, index) => {
            addText(`${index + 1}.`);
            Object.entries(row).forEach(([key, value]) => {
              const formattedValue = formatValue(key, value);
              addText(`${key}: ${formattedValue}`);
            });
          });
        }
  
        doc.save(`${sanitizeFileName(question)}-Donors.pdf`);
        showNotification("PDF export successful!");
        updateNotifications(true, "PDF export successful", new Date().getTime());
      } catch (error) {
        console.error('Failed to export to PDF:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "PDF export unsuccessful", new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const exportToWord = (id, question) => {
    logEvent({ action: 'UserExportedResponse', details: 'Export to Word was performed by the user', type: 'word' });
    setIsExporting(id);
  
    const formatValue = (key, value) => {
      if (!value) return '';
      
      // Format Full Name, City, Occupation, Employer in title case
      if (key === 'Full Name' || key === 'City' || key === 'Occupation' || key === 'Employer') {
        return toTitleCase(value);
      }
  
      // Format Street Address according to US standards
      if (key === 'Street Address' || key === 'Address') {
        return formatStreetAddress(value);
      }
      
      // Format phone numbers
      if (key === 'Phone Number') {
        return `+${value}`;
      }
      
      // Format currency values
      if (
        (key.includes('Total') || 
        key.includes('Contribution') ||
        key.includes('Amount') || 
        key.includes('Donated') ||
        key.includes('Donation') ||
        key.includes('Donations')) &&
        typeof value === 'number'
      ) {
        return `$${value.toLocaleString('en-US')}`;
      }
      
      return value.toString();
    };
  
    setTimeout(() => {
      try {
        const chat = conversation.find(chat => chat.id === id);
        if (!chat?.response?.body) {
          showNotification('No response available to export word.');
          return;
        }
  
        const jsonResponse = JSON.parse(chat.response.body);
  
        // Create document with initial sections
        const doc = new Document({
          sections: [{
            children: [
              new Paragraph({
                text: `Question: ${question}`,
                heading: HeadingLevel.HEADING_1
              }),
              new Paragraph({
                text: jsonResponse.description || '',
                style: "normalParagraph"
              }),
              new Paragraph({
                text: "Table Data:",
                heading: HeadingLevel.HEADING_2
              })
            ]
          }]
        });
  
        if (jsonResponse.table?.length > 0) {
          const keys = Object.keys(jsonResponse.table[0]);
          const table = new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              // Header row
              new TableRow({
                children: keys.map(key =>
                  new TableCell({
                    children: [new Paragraph({
                      text: key,
                      style: "strong"
                    })],
                    shading: {
                      fill: "CCCCCC",
                    },
                  })
                ),
              }),
              // Data rows
              ...jsonResponse.table.map(row =>
                new TableRow({
                  children: keys.map(key =>
                    new TableCell({
                      children: [new Paragraph({
                        text: formatValue(key, row[key]),
                        style: "normalParagraph"
                      })],
                    })
                  ),
                })
              ),
            ],
            // Add table styling
            tableProperties: {
              borders: {
                insideHorizontal: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: "CCCCCC",
                },
                insideVertical: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: "CCCCCC",
                },
                outside: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: "000000",
                },
              },
            },
          });
  
          doc.addSection({
            children: [table]
          });
        }
  
        Packer.toBlob(doc).then(blob => {
          saveAs(blob, `${sanitizeFileName(question)}-Donors.docx`);
          updateNotifications(true, "Word export successful", new Date().getTime());
        });
  
        showNotification("Word export successful!");
      } catch (error) {
        console.error('Failed to export to Word:', error);
        showNotification(`Failed to export: ${error.message}`);
        updateNotifications(false, "Word export failed: " + error.message, new Date().getTime());
      } finally {
        setIsExporting(null);
      }
    }, 4000);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; s.length > i; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
 
  const sanitizeFileName = (name) => {
    return name.replace(/[<>:"\/\\|?*]/g, "_");
  };
  const showNotification = (message) => {
    // setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 5000);
  };
 
  const handleFeedback = (isPositive) => {
    const feedbackType = isPositive ? 'Positive' : 'Negative';
    //console.log("inside feedback" )
    // Log the feedback action
    // logEvent({
    //   message: `User${feedbackType}Feedback`,
      
    // });

    // Log the question and response to CloudWatch
    // logEvent({
    //   action: 'FeedbackDetails',
    //   details: `Question: ${chat.question}\nResponse: ${chat.response?.body}`,
    //   type: 'feedback'
    // });

    // Log the query/question from the prompt box
    logEvent({
      action: 'FeedbackQuery',
      details: `Query associated with ${feedbackType.toLowerCase()} feedback: ${chat.question}`,
      type: 'feedback'
    });

    // Set the feedback message
    setFeedbackMessage(`Thank you for your ${feedbackType.toLowerCase()} feedback!`);

    // Clear the feedback message after 3 seconds
    setTimeout(() => {
      setFeedbackMessage("");
    }, 3000);
  };

  useEffect(() => {
    const fetchTokenAndSub = async () => {
      try {
        const token = await getJWT();
        setJwtToken(token);
        const decodedToken = decodeToken(token);
        setSub(decodedToken['sub']);
      } catch (error) {
        console.error('Failed to fetch token:', error);
        logEvent({ eventType: 'fetchTokenError', details: `Error fetching token: ${error.message}` });
      }
    };
    fetchTokenAndSub();
  }, []);

  const formatDateWithWeekday = (date) => {
    const options = { weekday: 'short', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  
  const PinButton = ({ handlePinToggle, pinnedQueries, queryText }) => (
    <button 
      onClick={() => handlePinToggle(queryText)}
      className="group hover:cursor-pointer"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path 
          d="M3.5 21L8.5 16" 
          className="stroke-[#8E8E8E] group-hover:stroke-black transition-colors duration-200" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
        <path 
          d="M13.7585 18.8714C10.0152 18.0215 6.47844 14.4848 5.62853 10.7415C5.49399 10.1489 5.42672 9.85266 5.62161 9.37197C5.8165 8.89129 6.05457 8.74255 6.53071 8.44509C7.60705 7.77265 8.77254 7.55888 9.98209 7.66586C11.6793 7.81598 12.5279 7.89104 12.9512 7.67048C13.3746 7.44991 13.6622 6.93417 14.2376 5.90269L14.9664 4.59604C15.4465 3.73528 15.6866 3.3049 16.2513 3.10202C16.816 2.89913 17.1558 3.02199 17.8355 3.26771C19.4249 3.84236 20.6576 5.07505 21.2323 6.66449C21.478 7.34417 21.6009 7.68401 21.398 8.2487C21.1951 8.8134 20.7647 9.05346 19.9039 9.53358L18.5672 10.2792C17.5376 10.8534 17.0229 11.1406 16.8024 11.568C16.5819 11.9955 16.662 12.8256 16.8221 14.4859C16.9399 15.7068 16.7369 16.88 16.0555 17.9697C15.7577 18.4458 15.6088 18.6839 15.1283 18.8786C14.6477 19.0733 14.3513 19.006 13.7585 18.8714Z" 
          className="stroke-[#8E8E8E] group-hover:stroke-black transition-colors duration-200" 
          strokeWidth="1.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
  // useEffect(() => {
  //   if (tableRef.current) {
  //     tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //   }
  // }, [response]);

  // const ErrorMessage = () => (
  //   <p className="mt-[1rem] mb-[1rem]"
  //     style={{
  //       color: '#000',
  //       fontFamily: 'Inter, sans-serif',
  //       fontSize: '13px',
  //       fontStyle: 'normal',
  //       fontWeight: 400,
  //       lineHeight: '120%',
  //       padding: '10px',
  //       borderRadius: '7px 14px 14px 14px',
  //       border: '1px solid #D9D9D9',
  //       background: '#FFF',
        
  //     }}
  //   >
  //     {response?.error ? (
  //       response.error.message
  //     ) : (
  //       responseError
  //     )}
      
  //     {/* !== '' ? responseError : 
  //       'Request is missing key information needed to process accurate results. Please clarify or rephrase and try again.'
  //     } */}
  //     {/* {responseError} */}
  //   </p>
  // );

  const ErrorMessage = () => {
    // Split the message by newlines and remove empty strings
    const formatErrorMessage = (message) => {
      if (!message) return [];
      return message.split('\n').filter(msg => msg.trim());
    };
  
    return (
      <div className="mt-4 mb-4">
        <div
          className="p-3 rounded-[7px_14px_14px_14px] border border-[#D9D9D9] bg-white"
        >
          {response?.error ? (
            <ul className="list-disc pl-5 space-y-2">
              {formatErrorMessage(response.error.message).map((msg, index) => (
                <li
                  key={index}
                  className="text-black font-inter text-sm font-normal leading-[120%]"
                >
                  {/* Remove bullet point character if it exists */}
                  {msg.replace(/^[•\s]+/, '')}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-black font-inter text-sm font-normal leading-[120%]">
              {responseError}
            </p>
          )}
        </div>
      </div>
    );
  };

  
  
  const IconWrapper = ({ children, title, onClick }) => (
    <div
      className="cursor-pointer"
      title={title}
      onClick={onClick}
      style={{ width: '20px', height: '20px', marginRight: '10px' }}
    >
      {children}
    </div>
  );
 
// Add this handler function where needed
const handleTableClick = () => {
 // console.log('setting the isTablePopup '+(!isTablePopup));
  setIsTablePopup(!isTablePopup);
};

const scrollbarClasses = `
  h-[325px] 
  overflow-auto 
  
  /* Hide scrollbar by default */
  [&::-webkit-scrollbar]:w-0
  hover:[&::-webkit-scrollbar]:w-2
  
  /* Smooth transition */
  [&::-webkit-scrollbar]:transition-[width]
  [&::-webkit-scrollbar]:duration-300
  [&::-webkit-scrollbar]:ease-in-out
  
  /* Thumb and track styles */
  [&::-webkit-scrollbar-thumb]:bg-white 
  [&::-webkit-scrollbar-thumb]:rounded-full 
  [&::-webkit-scrollbar-track]:bg-gray-100 
  
  /* Firefox support */
  scrollbar-none
  hover:scrollbar-thin 
  scrollbar-thumb-gray-400 
  scrollbar-track-gray-100
`;

const ChevronRightIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="16" 
    height="16" 
    viewBox="0 0 16 16" 
    fill="none"
    style={{ cursor: 'pointer' }}
    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
  >
    <path 
        d="M6 4L10 8L6 12" 
        stroke="#8E8E8E" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
    />
  </svg>
);

const DownloadIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="21" 
    viewBox="0 0 20 21" 
    fill="none"
  >
    <path d="M17.5 12.7334V16.0667C17.5 16.5088 17.3244 16.9327 17.0118 17.2452C16.6993 17.5578 16.2754 17.7334 15.8333 17.7334H4.16667C3.72464 17.7334 3.30072 17.5578 2.98816 17.2452C2.67559 16.9327 2.5 16.5088 2.5 16.0667V12.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.83203 8.56689L9.9987 12.7336L14.1654 8.56689" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.7334V2.7334" stroke="#8E8E8E" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

// const TablePopupView = ({ response, startIndex, endIndex, isVisible, onClose }) => {
//   if (!isVisible) return null;

//   // Function to transform header text
//   const getTransformedHeader = (header) => {
//     const columnNameMap = {
//       'Full Name': 'Contact Details',
//       'Phone Number': '',
//       'Total Cycle Contribution': 'Cycle Total',
//       'Primary Election Contribution': 'Primary',
//       'General Election Contribution': 'General',
//       'Total Donations': 'Lifetime Total',
//       'Total Lifetime Contribution': 'Lifetime Total',
//       'occupation': 'Occupation',
//     };

//     return columnNameMap[header] || header;
//   };

//   // Function to format name in camel case
//   const formatName = (name) => {
//     return name
//       .split(' ')
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//       .join(' ');
//   };

//   // Function to format city name
//   const formatCity = (city) => {
//     if (!city) return ''; // Return empty string if city is null/undefined
//     return city
//       .split(' ')
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//       .join(' ');
//   };

//   // Function to format currency values
//   const formatCurrency = (value) => {
//     if (typeof value === 'number') {
//       return `$${value.toLocaleString('en-US')}`;
//     } else if (typeof value === 'string') {
//       const numValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
//       if (!isNaN(numValue)) {
//         return `$${numValue.toLocaleString('en-US')}`;
//       }
//     }
//     return value;
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div
//         className="relative bg-white rounded-lg shadow-xl transform transition-all overflow-hidden"
//         style={{
//           width: 'auto',
//           maxWidth: '90vw',
//           maxHeight: '90vh',
//         }}
//         onClick={e => e.stopPropagation()}
//       >
//         {/* Close button */}
//         <button           
//           onClick={onClose}           
//           className="absolute right-4 top-4 z-20 p-1 rounded-full hover:bg-gray-100 transition-colors duration-150 cursor-pointer stroke-[#8E8E8E] hover:stroke-black transition-colors duration-200"         
//         >           
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLineJoin="round"
//           >
//             <path d="M3 3 L9 9 M3 9 L9 9 L9 3" />
//             <path d="M21 21 L15 15 M21 15 L15 15 L15 21" />
//           </svg>
//         </button>

//         <div
//           className="overflow-auto max-h-[90vh] rounded-lg"
//           style={{
//             scrollbarWidth: 'thin',
//             scrollbarColor: '#CBD5E0 #EDF2F7',
//           }}
//         >
//           <div className="min-w-full p-4">
//             <table className="min-w-full bg-white">
//               <thead className="bg-[#FFFFFF] sticky top-0 z-10">
//                 <tr className="border-b border-solid border-gray-300 relative">
//                   {/* Contact Details and Address columns */}
//                   <th className="px-1.5 py-4" style={{
//                     color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
//                     fontFamily: 'Inter',
//                     fontSize: '13px',
//                     fontStyle: 'normal',
//                     fontWeight: '400',
//                     lineHeight: '120%'
//                   }}>
//                     <div className="flex items-center min-h-[24px] group relative">
//                       <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
//                         Contact Details
//                       </span>
//                     </div>
//                   </th>
//                   <th className="px-1.5 py-4" style={{
//                     color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
//                     fontFamily: 'Inter',
//                     fontSize: '13px',
//                     fontStyle: 'normal',
//                     fontWeight: '400',
//                     lineHeight: '120%'
//                   }}>
//                     <div className="flex items-center min-h-[24px] group relative">
//                       <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
//                         Address
//                       </span>
//                     </div>
//                   </th>

//                   {/* Other columns */}
//                   {Object.keys(response?.table[0] || {})
//                     .filter(key => 
//                       key !== 'Full Name' && 
//                       key !== 'Phone Number' && 
//                       key !== 'City' && 
//                       key !== 'State' && 
//                       key !== 'Zip Code'
//                     )
//                     .map((header, index) => {
//                       const displayHeader = getTransformedHeader(header);
//                       return (
//                         <th 
//                           key={index} 
//                           className="px-1.5 py-4" 
//                           style={{
//                             color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
//                             fontFamily: 'Inter',
//                             fontSize: '13px',
//                             fontStyle: 'normal',
//                             fontWeight: '400',
//                             lineHeight: '120%'
//                           }}
//                         >
//                           <div className="flex items-center min-h-[24px] group relative">
//                             <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
//                               {displayHeader}
//                             </span>
//                           </div>
//                         </th>
//                       );
//                     })}
//                 </tr>
//               </thead>
//               <tbody className="bg-white">
//                 {response?.table.map((row, rowIndex) => (
//                   <tr
//                     key={rowIndex}
//                     className="hover:bg-gray-50 border-b border-[#F2F2F2] last:border-b-0"
//                   >
//                     {/* Contact Details Column */}
//                     <td className="px-1.5 py-2.5">
//                       <div className="flex items-center">
//                         <div>
//                           <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-1">
//                             {formatName(row['Full Name'])}
//                           </div>
//                           <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
//                             {row['Phone Number'] ? `+${row['Phone Number']}` : ''}
//                           </div>
//                         </div>
//                       </div>
//                     </td>

//                     {/* Address Column */}
//                     <td className="px-1.5 py-2.5">
//                       <div>
//                         <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-1">
//                           {row['City'] && formatCity(row['City'])}
//                         </div>
//                         <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
//                           {(row['State'] || row['Zip Code']) && 
//                             `${row['State'] || ''}${row['State'] && row['Zip Code'] ? ', ' : ''}${row['Zip Code'] || ''}`
//                           }
//                         </div>
//                       </div>
//                     </td>

//                     {/* Other Columns */}
//                     {Object.entries(row)
//                       .filter(([key]) => 
//                         key !== 'Full Name' && 
//                         key !== 'Phone Number' && 
//                         key !== 'City' && 
//                         key !== 'State' && 
//                         key !== 'Zip Code'

//                       )
//                       .map(([key, cell], cellIndex) => {
//                         const isTotalColumn = key.includes('Lifetime Total') || key.includes('Total');
//                         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//                         const isEmail = typeof cell === 'string' && emailRegex.test(cell);
//                         const isAmount = isTotalColumn || key.includes('Contribution') || key.includes('Donations') || key.includes('Amount');
//                         const isStreetAddress = key === 'Street Address';

//                         const displayValue = isAmount 
//                           ? formatCurrency(cell)
//                           : isEmail
//                             ? <EmailLink email={cell} />
//                             : isStreetAddress
//                               ? formatStreetAddress(cell)
//                               : typeof cell === 'string'
//                                 ? cell.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
//                                 : cell;

//                         return (
//                           <td key={cellIndex} className="px-1.5 py-2.5">
//                             <div
//                               className={`text-[0.8125rem] font-inter truncate ${
//                                 isEmail 
//                                 ? 'inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]'
//                                   : isTotalColumn
//                                   ? 'inline-flex p-1 justify-center items-center gap-2.5 font rounded-[0.4375rem] bg-[#EBAE12] bg-opacity-20'
//                                   : ''
//                               }`}
//                               style={{ 
//                                 //highlighting yellow bubble for lifetime
//                                 // color: '#000'
//                               color: isTotalColumn ? '#BC8B0E' : '#000',
//                               }}
//                             >
//                               {displayValue}
//                             </div>
//                           </td>
//                         );
//                       })}
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Custom scrollbar styles */}
//         <style jsx global>{`
//           .overflow-auto::-webkit-scrollbar {
//             width: 8px;
//             height: 8px;
//           }
//           .overflow-auto::-webkit-scrollbar-track {
//             background: #EDF2F7;
//             border-radius: 4px;
//           }
//           .overflow-auto::-webkit-scrollbar-thumb {
//             background: #CBD5E0;
//             border-radius: 4px;
//           }
//           .overflow-auto::-webkit-scrollbar-thumb:hover {
//             background: #A0AEC0;
//           }
//         `}</style>
//       </div>
//     </div>
//   );
// };

const TablePopupView = ({ response, startIndex, endIndex, isVisible, onClose }) => {
  if (!isVisible) return null;

  // Function to transform header text
  const getTransformedHeader = (header) => {
    const columnNameMap = {
      'Full Name': 'Contact Details',
      // 'Phone Number': '',
      'Total Cycle Contribution': 'Cycle Total',
      'Primary Election Contribution': 'Primary',
      'General Election Contribution': 'General',
      // 'Total Donations': 'Lifetime Total',
      // 'Total Lifetime Contribution': 'Lifetime Total',
      'occupation': 'Occupation',
    };

    return columnNameMap[header] || header;
  };

  // Function to format city name
  const formatCity = (city) => {
    if (!city) return ''; // Return empty string if city is null/undefined
    return city
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Function to format currency values
  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return `$${value.toLocaleString('en-US')}`;
    } else if (typeof value === 'string') {
      const numValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      if (!isNaN(numValue)) {
        return `$${numValue.toLocaleString('en-US')}`;
      }
    }
    return value;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="relative bg-white rounded-lg shadow-xl transform transition-all overflow-hidden"
        style={{
          width: 'auto',
          maxWidth: '90vw',
          maxHeight: '90vh',
        }}
        onClick={e => e.stopPropagation()}
      >
        {/* Close button */}
        <button           
          onClick={onClose}           
          className="absolute right-4 top-4 z-20 p-1 rounded-full hover:bg-gray-100 transition-colors duration-150 cursor-pointer stroke-[#8E8E8E] hover:stroke-black transition-colors duration-200"         
        >           
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLineJoin="round"
          >
            <path d="M3 3 L9 9 M3 9 L9 9 L9 3" />
            <path d="M21 21 L15 15 M21 15 L15 15 L15 21" />
          </svg>
        </button>

        <div
          className="overflow-auto max-h-[90vh] rounded-lg"
          style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#CBD5E0 #EDF2F7',
          }}
        >
          <div className="min-w-full p-4">
            <table className="min-w-full bg-white">
              <thead className="bg-[#FFFFFF] sticky top-0 z-10">
                <tr className="border-b border-solid border-gray-300 relative">
                  {/* Contact Details and Address columns */}
                  <th className="px-1.5 py-4" style={{
                    color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                    fontFamily: 'Inter',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '120%'
                  }}>
                    <div className="flex items-center min-h-[24px] group relative">
                      <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
                        Contact Details
                      </span>
                    </div>
                  </th>
                  <th className="px-1.5 py-4" style={{
                    color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                    fontFamily: 'Inter',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '120%'
                  }}>
                    <div className="flex items-center min-h-[24px] group relative">
                      <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
                        Address
                      </span>
                    </div>
                  </th>

                  {/* Other columns */}
                  {Object.keys(response?.table[0] || {})
                    .filter(key => 
                      key !== 'Full Name' && 
                      key !== 'Phone Number' && 
                      key !== 'City' && 
                      key !== 'State' && 
                      key !== 'Zip Code'
                    )
                    .map((header, index) => {
                      const displayHeader = getTransformedHeader(header);
                      return (
                        <th 
                          key={index} 
                          className="px-1.5 py-4" 
                          style={{
                            color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                            fontFamily: 'Inter',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '120%'
                          }}
                        >
                          <div className="flex items-center min-h-[24px] group relative">
                            <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] font leading-[120%]">
                              {displayHeader}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {response?.table.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="hover:bg-gray-50 border-b border-[#F2F2F2] last:border-b-0"
                  >
                    {/* Contact Details Column */}
                    <td className="px-1.5 py-2.5">
                      <div className="flex items-center">
                        <div>
                          <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-1">
                            {row['Full Name']}
                          </div>
                          <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
                            {row['Phone Number'] ? `+${row['Phone Number']}` : ''}
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* Address Column */}
                    <td className="px-1.5 py-2.5">
                      <div>
                        <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-1">
                          {row['City'] && formatCity(row['City'])}
                        </div>
                        <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
                          {(row['State'] || row['Zip Code']) && 
                            `${row['State'] || ''}${row['State'] && row['Zip Code'] ? ', ' : ''}${row['Zip Code'] || ''}`
                          }
                        </div>
                      </div>
                    </td>

                    {/* Other Columns */}
                    {Object.entries(row)
                      .filter(([key]) => 
                        key !== 'Full Name' && 
                        key !== 'Phone Number' && 
                        key !== 'City' && 
                        key !== 'State' && 
                        key !== 'Zip Code'
                      )
                      .map(([key, cell], cellIndex) => {
                        const isTotalColumn = key.includes('Lifetime') || key.includes('Amount') || key.includes('Cycle Total') || key.includes('Total Donations') || key.includes('Total');
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        const isEmail = typeof cell === 'string' && emailRegex.test(cell);
                        const isAmount = isTotalColumn || key.includes('Contribution') || key.includes('Total') || key.includes('Amount');
                        const isStreetAddress = key === 'Street Address';

                        const displayValue = isAmount 
                          ? formatCurrency(cell)
                          : isEmail
                            ? <EmailLink email={cell} />
                            : isStreetAddress
                              ? formatStreetAddress(cell)
                              : cell;

                        return (
                          <td key={cellIndex} className="px-1.5 py-2.5">
                            <div
                              className={`text-[0.8125rem] font-inter truncate ${
                                isEmail 
                                ? 'inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]'
                                  : isTotalColumn
                                  ? 'inline-flex p-1 justify-center items-center gap-2.5 font rounded-[0.4375rem] bg-[#EBAE12] bg-opacity-20'
                                  : ''
                              }`}
                              style={{ 
                                color: isTotalColumn ? '#BC8B0E' : '#000',
                              }}
                            >
                              {displayValue}
                            </div>
                          </td>
                        );
                      })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Custom scrollbar styles */}
        <style jsx global>{`
          .overflow-auto::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          .overflow-auto::-webkit-scrollbar-track {
            background: #EDF2F7;
            border-radius: 4px;
          }
          .overflow-auto::-webkit-scrollbar-thumb {
            background: #CBD5E0;
            border-radius: 4px;
          }
          .overflow-auto::-webkit-scrollbar-thumb:hover {
            background: #A0AEC0;
          }
        `}</style>
      </div>
    </div>
  );
};

const HeaderCell = ({ header }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = header.split(' ');
  const shouldTruncate = words.length > 2;
  
  return (
      <th 
        scope="col"
        className="px-4 py-3 text-left text-[13px] font-bold text-[#8E8E8E] uppercase relative group"
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >

      <div className={`${isExpanded ? '' : 'overflow-hidden'}`}>
        <span className={`
          inline-block
          ${!isExpanded && shouldTruncate ? 'truncate max-w-[80px]' : ''}
          ${isExpanded ? 'whitespace-normal' : 'whitespace-nowrap'}
        `}>
          {shouldTruncate ? `${words.slice(0, 2).join(' ')}..` : header}
        </span>
      </div>
      {shouldTruncate && !isExpanded && (
        <div className="absolute hidden group-hover:block bg-black text-white p-2 rounded-md text-xs whitespace-normal z-10 min-w-[200px] max-w-[300px] top-full left-0 mt-1">
          {header}
        </div>
      )}
    </th>
  );
};

//console.log('response from conversation card is: '+JSON.stringify(response));

const EmailLink = ({ email, children }) => {
  return (
    <div className="inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]">
      <a href={`mailto:${email}`} className="text-[#40C5AF] font-inter text-[0.8125rem] font-normal leading-[120%]">
        {children || email}
      </a>
    </div>
  );
};


const handleContactDetailSort = () => {
  if (!response?.table || !Array.isArray(response.table)) {
    console.error('Invalid table data.');
    return;
  }

  // Create a copy of the table to avoid mutating the original state
  const sortedTable = [...response.table];

  // Sort the table based on Full Name
  sortedTable.sort((a, b) => {
    const nameA = a['Full Name']?.toLowerCase() || ''; // Handle undefined or null gracefully
    const nameB = b['Full Name']?.toLowerCase() || ''; // Handle undefined or null gracefully

    // Toggle between ascending and descending
    if (sortOrder === 'asc') {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  // Update the state with the sorted table and toggle sort order
  setResponse((prev) => ({
    ...prev,
    table: sortedTable,
  }));

  // Switch sort order for the next click
  setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
};

const handleAddressSort = () => {
  if (!response?.table || !Array.isArray(response.table)) {
    console.error('Invalid table data.');
    return;
  }

  // Create a copy of the table to avoid mutating the original state
  const sortedTable = [...response.table];

  // Sort the table based on City
  sortedTable.sort((a, b) => {
    const cityA = a['City']?.toLowerCase() || ''; // Handle undefined or null gracefully
    const cityB = b['City']?.toLowerCase() || ''; // Handle undefined or null gracefully

    // Toggle between ascending and descending
    if (sortOrder === 'asc') {
      return cityA.localeCompare(cityB);
    } else {
      return cityB.localeCompare(cityA);
    }
  });

  // Update the state with the sorted table and toggle sort order
  setResponse((prev) => ({
    ...prev,
    table: sortedTable,
  }));

  // Switch sort order for the next click
  setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
};

const renderTableCell = (value) => {
  if (value === null || value === undefined) return '';
  
  if (typeof value === 'string') {
    return value.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  
  return value;
};

// Function to format street address according to US standards
const formatStreetAddress = (address) => {
  if (!address) return '';
  
  // Common street abbreviations mapping
  const abbreviations = {
    'alley': 'Aly',
    'annex': 'Anx',
    'arcade': 'Arc',
    'avenue': 'Ave',
    'bayou': 'Byu',
    'beach': 'Bch',
    'bend': 'Bnd',
    'bluff': 'Blf',
    'bottom': 'Btm',
    'boulevard': 'Blvd',
    'branch': 'Br',
    'bridge': 'Brg',
    'brook': 'Brk',
    'brooks': 'Brks',
    'burg': 'Bg',
    'burgs': 'Bgs',
    'bypass': 'Byp',
    'camp': 'Cp',
    'canyon': 'Cyn',
    'cape': 'Cpe',
    'causeway': 'Cswy',
    'center': 'Ctr',
    'centers': 'Ctrs',
    'circle': 'Cir',
    'circles': 'Cirs',
    'cliff': 'Clf',
    'cliffs': 'Clfs',
    'club': 'Clb',
    'common': 'Cmn',
    'commons': 'Cmns',
    'corner': 'Cor',
    'corners': 'Cors',
    'course': 'Crse',
    'court': 'Ct',
    'courts': 'Cts',
    'cove': 'Cv',
    'coves': 'Cvs',
    'creek': 'Crk',
    'crescent': 'Cres',
    'crest': 'Crst',
    'crossing': 'Xing',
    'crossroad': 'Xrd',
    'curve': 'Curv',
    'dale': 'Dl',
    'dam': 'Dm',
    'divide': 'Dv',
    'drive': 'Dr',
    'drives': 'Drs',
    'estate': 'Est',
    'estates': 'Ests',
    'expressway': 'Expy',
    'extension': 'Ext',
    'extensions': 'Exts',
    'fall': 'Fall',
    'falls': 'Fls',
    'ferry': 'Fry',
    'field': 'Fld',
    'fields': 'Flds',
    'flat': 'Flt',
    'flats': 'Flts',
    'ford': 'Frd',
    'fords': 'Frds',
    'forest': 'Frst',
    'forge': 'Frg',
    'forges': 'Frgs',
    'fork': 'Frk',
    'forks': 'Frks',
    'fort': 'Ft',
    'freeway': 'Fwy',
    'garden': 'Gdn',
    'gardens': 'Gdns',
    'gateway': 'Gtwy',
    'glen': 'Gln',
    'glens': 'Glns',
    'green': 'Grn',
    'greens': 'Grns',
    'grove': 'Grv',
    'groves': 'Grvs',
    'harbor': 'Hbr',
    'harbors': 'Hbrs',
    'haven': 'Hvn',
    'heights': 'Hts',
    'highway': 'Hwy',
    'hill': 'Hl',
    'hills': 'Hls',
    'hollow': 'Holw',
    'inlet': 'Inlt',
    'island': 'Is',
    'islands': 'Iss',
    'isle': 'Isle',
    'junction': 'Jct',
    'junctions': 'Jcts',
    'key': 'Ky',
    'keys': 'Kys',
    'knoll': 'Knl',
    'knolls': 'Knls',
    'lake': 'Lk',
    'lakes': 'Lks',
    'land': 'Land',
    'landing': 'Lndg',
    'lane': 'Ln',
    'light': 'Lgt',
    'lights': 'Lgts',
    'loaf': 'Lf',
    'lock': 'Lck',
    'locks': 'Lcks',
    'lodge': 'Ldg',
    'loop': 'Loop',
    'mall': 'Mall',
    'manor': 'Mnr',
    'manors': 'Mnrs',
    'meadow': 'Mdw',
    'meadows': 'Mdws',
    'mews': 'Mews',
    'mill': 'Ml',
    'mills': 'Mls',
    'mission': 'Msn',
    'motorway': 'Mtwy',
    'mount': 'Mt',
    'mountain': 'Mtn',
    'mountains': 'Mtns',
    'neck': 'Nck',
    'orchard': 'Orch',
    'oval': 'Oval',
    'overpass': 'Opas',
    'park': 'Park',
    'parks': 'Park',
    'parkway': 'Pkwy',
    'parkways': 'Pkwy',
    'pass': 'Pass',
    'passage': 'Psge',
    'path': 'Path',
    'pike': 'Pike',
    'pine': 'Pne',
    'pines': 'Pnes',
    'place': 'Pl',
    'plain': 'Pln',
    'plains': 'Plns',
    'plaza': 'Plz',
    'point': 'Pt',
    'points': 'Pts',
    'port': 'Prt',
    'ports': 'Prts',
    'prairie': 'Pr',
    'radial': 'Radl',
    'ramp': 'Ramp',
    'ranch': 'Rnch',
    'rapid': 'Rpd',
    'rapids': 'Rpds',
    'rest': 'Rst',
    'ridge': 'Rdg',
    'ridges': 'Rdgs',
    'river': 'Riv',
    'road': 'Rd',
    'roads': 'Rds',
    'route': 'Rte',
    'row': 'Row',
    'rue': 'Rue',
    'run': 'Run',
    'shoal': 'Shl',
    'shoals': 'Shls',
    'shore': 'Shr',
    'shores': 'Shrs',
    'skyway': 'Skwy',
    'spring': 'Spg',
    'springs': 'Spgs',
    'spur': 'Spur',
    'spurs': 'Spur',
    'square': 'Sq',
    'squares': 'Sqs',
    'station': 'Sta',
    'stravenue': 'Stra',
    'stream': 'Strm',
    'street': 'St',
    'streets': 'Sts',
    'summit': 'Smt',
    'terrace': 'Ter',
    'throughway': 'Trwy',
    'trace': 'Trce',
    'track': 'Trak',
    'trafficway': 'Trfy',
    'trail': 'Trl',
    'tunnel': 'Tunl',
    'turnpike': 'Tpke',
    'underpass': 'Upas',
    'union': 'Un',
    'unions': 'Uns',
    'valley': 'Vly',
    'valleys': 'Vlys',
    'viaduct': 'Via',
    'view': 'Vw',
    'views': 'Vws',
    'village': 'Vlg',
    'villages': 'Vlgs',
    'ville': 'Vl',
    'vista': 'Vis',
    'walk': 'Walk',
    'wall': 'Wall',
    'way': 'Way',
    'ways': 'Ways',
    'well': 'Wl',
    'wells': 'Wls',

    // Secondary unit designators
    'apartment': 'Apt',
    'building': 'Bldg',
    'department': 'Dept',
    'floor': 'Fl',
    'suite': 'Ste',
    'room': 'Rm',
    'space': 'Spc',
    'unit': 'Unit',

    // Common non-USPS abbreviations that might appear
    'basement': 'Bsmt',
    'front': 'Frnt',
    'hangar': 'Hngr',
    'lobby': 'Lbby',
    'lot': 'Lot',
    'lower': 'Lowr',
    'office': 'Ofc',
    'penthouse': 'Ph',
    'pier': 'Pier',
    'rear': 'Rear',
    'side': 'Side',
    'slip': 'Slip',
    'trailer': 'Trlr',
    'upper': 'Uppr',
    'po': 'PO',
    'Po': 'PO',
    'P.o.': 'PO',
    'p.o.': 'PO',
  };

  // Directional abbreviations that should always be uppercase
  const directionals = {
    'north': 'N',
    'south': 'S',
    'east': 'E',
    'west': 'W',
    'northeast': 'NE',
    'northwest': 'NW',
    'southeast': 'SE',
    'southwest': 'SW',
    // Add common lowercase variations
    'nw': 'NW',
    'ne': 'NE',
    'sw': 'SW',
    'se': 'SE',
    'n': 'N',
    's': 'S',
    'e': 'E',
    'w': 'W'
  };

  // Split address into parts
  let parts = address.toLowerCase().split(' ');
  
  // Capitalize first letter of each word and handle abbreviations
  parts = parts.map((part, index) => {
    // Check for directionals first (especially at the end of address)
    if (directionals[part]) {
      return directionals[part];
    }
    
    // Check if word is in street abbreviations
    if (abbreviations[part]) {
      return abbreviations[part];
    }
    
    // Handle numeric parts (including those with letters like "2nd")
    if (/\d/.test(part)) {
      return part.toUpperCase();
    }
    
    // Special case for hyphenated words
    if (part.includes('-')) {
      return part.split('-')
        .map(p => p.charAt(0).toUpperCase() + p.slice(1))
        .join('-');
    }
    
    // Capitalize first letter of other words
    return part.charAt(0).toUpperCase() + part.slice(1);
  });

  return parts.join(' ');
};

return (      
    <>
    {!isResponse ? (
      <div className="flex w-full py-2.5 justify-between items-center border-b border-[#D9D9D9]">
        <div className="flex flex-col items-start gap-0.5 flex-1 mt-[10px]">
          <div className="flex justify-between items-start self-stretch">
            <div className="flex w-full items-start gap-5">
              <div className="flex items-center gap-2.5 flex-1">
                {!isPinned && !isResponse &&  (
                  <PinButton
                    handlePinToggle={handlePinToggle}
                    pinnedQueries={pinnedQueries}
                    queryText={chat.question}
                  />
                )}
                <p className="font-inter text-[1.25rem] font-bold leading-[120%] bg-gradient-to-r from-[#40C5AF] via-[#EBAE12] to-[#EBAE12] bg-clip-text text-transparent">{chat.question}</p>
              </div>
              {/* <div 
                className="cursor-pointer p-1 hover:bg-gray-100 rounded-md ml-auto" 
                onClick={handleTableClick}
              > */}
              
              {response?.table && (
                <svg xmlns="http://www.w3.org/2000/svg"  width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={handleTableClick} className="cursor-pointer stroke-[#8E8E8E] hover:stroke-black transition-colors duration-200">
                  <path d="M16.9998 3.26621C17.8442 3.25421 20.6407 2.67328 21.2336 3.26621C21.8265 3.85913 21.2456 6.65559 21.2336 7.5M21.0058 3.49097L14.002 10.4961"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.76621 16.5025C3.75421 17.3469 3.17328 20.1434 3.76621 20.7363C4.35913 21.3292 7.15559 20.7483 8 20.7363M11.0018 13.5L3.99809 20.5051"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              )}
              {/* </div> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M3.5 7H6.5" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 17H9.5" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.5 17H21.5" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.5 7H21.5" stroke="#8E8E8E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.5 7C6.5 6.06812 6.5 5.60218 6.65224 5.23463C6.85523 4.74458 7.24458 4.35523 7.73463 4.15224C8.10218 4 8.56812 4 9.5 4C10.4319 4 10.8978 4 11.2654 4.15224C11.7554 4.35523 12.1448 4.74458 12.3478 5.23463C12.5 5.60218 12.5 6.06812 12.5 7C12.5 7.93188 12.5 8.39782 12.3478 8.76537C12.1448 9.25542 11.7554 9.64477 11.2654 9.84776C10.8978 10 10.4319 10 9.5 10C8.56812 10 8.10218 10 7.73463 9.84776C7.24458 9.64477 6.85523 9.25542 6.65224 8.76537C6.5 8.39782 6.5 7.93188 6.5 7Z" stroke="#8E8E8E" stroke-width="1.5"/>
                <path d="M12.5 17C12.5 16.0681 12.5 15.6022 12.6522 15.2346C12.8552 14.7446 13.2446 14.3552 13.7346 14.1522C14.1022 14 14.5681 14 15.5 14C16.4319 14 16.8978 14 17.2654 14.1522C17.7554 14.3552 18.1448 14.7446 18.3478 15.2346C18.5 15.6022 18.5 16.0681 18.5 17C18.5 17.9319 18.5 18.3978 18.3478 18.7654C18.1448 19.2554 17.7554 19.6448 17.2654 19.8478C16.8978 20 16.4319 20 15.5 20C14.5681 20 14.1022 20 13.7346 19.8478C13.2446 19.6448 12.8552 19.2554 12.6522 18.7654C12.5 18.3978 12.5 17.9319 12.5 17Z" stroke="#8E8E8E" stroke-width="1.5"/>
              </svg> */}

              {/* Downloads 3 icons */}
            {response?.table && (
              <div className="flex items-center">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                className="cursor-pointer stroke-[#8E8E8E] hover:stroke-black transition-colors duration-200"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <path
                  d="M12.5 14.5V4.5M12.5 14.5C11.7998 14.5 10.4915 12.5057 10 12M12.5 14.5C13.2002 14.5 14.5085 12.5057 15 12"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.5 16.5C20.5 18.982 19.982 19.5 17.5 19.5H7.5C5.018 19.5 4.5 18.982 4.5 16.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              {/* Chevron Right Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                className="cursor-pointer stroke-[#8E8E8E] hover:stroke-black transition-colors duration-200"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <path d="M9 18l6-6-6-6" />
              </svg>

                {/* Dropdown Menu */}
                {isDropdownOpen && (
                  <div className="flex items-center ml-2 gap-2">
                    <div 
                      title="Export to Excel" 
                      onClick={() => {
                        exportToExcel(chat.id, chat.question);
                        setIsDropdownOpen(false);
                      }}
                      className="cursor-pointer"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                        <path fill="#4CAF50" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"/>
                        <path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"/>
                        <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z"/>
                        <path fill="#FFF" d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"/>
                      </svg>
                    </div>
                    <div 
                      title="Export to PDF" 
                      onClick={() => {
                        exportToPDF(chat.id, chat.question);
                        setIsDropdownOpen(false);
                      }}
                      className="cursor-pointer"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                        <path fill="#FF5722" d="M40 45L8 45 8 3 30 3 40 13z"/>
                        <path fill="#FBE9E7" d="M38.5 14L29 14 29 4.5z"/>
                        <path fill="#FFF" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"/>
                      </svg>
                    </div>
                    <div 
                      title="Export to Word" 
                      onClick={() => {
                        exportToWord(chat.id, chat.question);
                        setIsDropdownOpen(false);
                      }}
                      className="cursor-pointer"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                        <path fill="#2196F3" d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"/>
                        <path fill="#FFF" d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"/>
                        <path fill="#0D47A1" d="M27 42L6 38 6 10 27 6z"/>
                        <path fill="#FFF" d="M21.174,31l-2.136-8.318l-2.097,8.318h-2.663l-3.074-11.992h2.576l1.822,8.685l2.097-8.685h2.386l2.175,8.685l1.822-8.685h2.576L23.797,31H21.174z"/>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex flex-col w-full mt-1 mb-[40px]"> {/* Added margin top and bottom */}
      <>
        {response?.table ? (
          <>
            <div 
              ref={tableRef} 
              className={`w-full h-auto min-h-[325px] rounded-lg bg-white ${scrollbarClasses}`}
            >
              <div className="w-full relative">
                <div style={{ width: '100%', height: 'auto' }}>
                  <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full bg-white">
                    <thead className="bg-[#FFFFFF] sticky top-0 z-10">
                      <tr className="border-b border-solid border-gray-300 relative">
                        <th 
                          className="px-1.5 cursor-pointer" 
                          onClick={handleContactDetailSort}
                          style={{
                            color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                            fontFamily: 'Inter',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',  // Changed from 700 to 400
                            lineHeight: '120%'
                          }}
                        >
                          <div className="flex items-center min-h-[24px] group relative my-2.5">
                            <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] leading-[120%]">
                              <div className="flex items-center">
                                <div>
                                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="arrow-up-down">
                                      <path id="Vector" d="M4.08398 2.33594V11.6693" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path id="Vector_2" d="M9.91602 11.0859V2.33594" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path id="Vector_3" d="M5.83398 4.08593C5.83398 4.08593 4.54513 2.33594 4.08397 2.33594C3.62281 2.33593 2.33398 4.08594 2.33398 4.08594" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path id="Vector_4" d="M11.666 9.91406C11.666 9.91406 10.3771 11.6641 9.91602 11.6641C9.45483 11.6641 8.16602 9.91406 8.16602 9.91406" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                  </svg>
                                </div>
                                <span className="ml-2">Contact Details</span>
                              </div>
                            </span>
                          </div>
                        </th>
                        <th 
                          className="px-1.5 cursor-pointer" 
                          onClick={handleAddressSort}
                          style={{
                            color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                            fontFamily: 'Inter',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '120%'
                          }}
                        >
                          <div className="flex items-center min-h-[24px] group relative my-2.5">
                            <span className="truncate text-[#8E8E8E] font-inter text-[0.8125rem] leading-[120%]">
                              <div className="flex items-center">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g id="arrow-up-down">
                                    <path id="Vector" d="M4.08398 2.33594V11.6693" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" d="M9.91602 11.0859V2.33594" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_3" d="M5.83398 4.08593C5.83398 4.08593 4.54513 2.33594 4.08397 2.33594C3.62281 2.33593 2.33398 4.08594 2.33398 4.08594" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_4" d="M11.666 9.91406C11.666 9.91406 10.3771 11.6641 9.91602 11.6641C9.45483 11.6641 8.16602 9.91406 8.16602 9.91406" stroke="#8E8E8E" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                </svg>
                                <span className="ml-2">Address</span>
                              </div>
                            </span>
                          </div>
                        </th>
                        {Object.keys(response.table[0])
                          .filter(key => 
                            key !== 'Full Name' && 
                            key !== 'Phone Number' && 
                            key !== 'City' && 
                            key !== 'State' && 
                            key !== 'Zip Code'
                          )
                          .map((header, index) => {
                            const columnNameMap = {
                              'Total Cycle Contribution': 'Cycle Total',
                              'Primary Election Contribution': 'Primary Total',
                              'General Election Contribution': 'General Total',
                              // 'Total Donations': 'Lifetime Total ',
                              // 'Total Lifetime Contribution': 'Lifetime Total',
                              // 'Amount Donated': 'Amount Donated',
                              'occupation': 'Occupation',
                              // 'Total': 'Lifetime Total'
                            };
                            // console.log('header', header);
                            const displayHeader = columnNameMap[header] || header;
                            const isTotalHeader = displayHeader.includes('Lifetime Total');
                            
                            return (
                              <th key={index} className="px-1.5" style={{
                                color: 'var(--foundation-primary-black-light-active, #8E8E8E)',
                                fontFamily: 'Inter',
                                fontSize: '13px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '120%'
                              }}>
                                <div className="flex items-center min-h-[24px] group relative my-2.5">
                                  <span className={`truncate text-[#8E8E8E] font-inter text-[0.8125rem]  'font-normal' leading-[120%]`}>
                                    {displayHeader}
                                  </span>
                                </div>
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    
                    <tbody className="bg-white overflow-auto">
                      {response.table.slice(startIndex, endIndex).map((row, rowIndex) => (
                        <tr key={rowIndex} className="hover:bg-gray-50 border-b border-[#F2F2F2]">
                          <td className="px-1.5 py-2">
                            <div className="flex items-center">
                              <div>
                                <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-0.5 py-0.5">
                                  {row['Full Name'] ? (
                                    row['Full Name'].split(' ').length > 3 ? (
                                      <>
                                        <div>{row['Full Name'].split(' ').slice(0, 3).join(' ')}</div>
                                        <div>{row['Full Name'].split(' ').slice(3, 6).join(' ')}</div>
                                        <div>{row['Full Name'].split(' ').slice(6).join(' ')}</div>
                                      </>
                                    ) : (
                                      row['Full Name']
                                    )
                                  ) : ''}
                                </div>
                                <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%] py-1">
                                  {row['Phone Number'] ? `+${row['Phone Number']}` : ''}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-1.5 py-2">
                            <div>
                              <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-0.5 py-0.5">
                                {renderTableCell(row['City'])}
                              </div>
                              <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%] py-1">
                                {row['State'] && row['Zip Code'] ? `${row['State']}, ${row['Zip Code']}` : ''}
                              </div>
                            </div>
                          </td>
                          {Object.entries(row)
                            .filter(([key]) => 
                              key !== 'Full Name' && 
                              key !== 'Phone Number' && 
                              key !== 'City' && 
                              key !== 'State' && 
                              key !== 'Zip Code'
                            )
                            .map(([key, cell], cellIndex) => {
                              const isTotalColumn = key.includes('Lifetime') || key.includes('Amount') || key.includes('Cycle Total') || key.includes('Total Donations') || key.includes('Total');
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              const isEmail = typeof cell === 'string' && emailRegex.test(cell);
                              const isAmount = isTotalColumn || key.includes('Contribution') || key.includes('Total') || key.includes('Amount');
                              const isStreetAddress = key === 'Street Address';
                              
                              const formatCurrency = (value) => {
                                if (typeof value === 'number') {
                                  return `$${value.toLocaleString('en-US')}`;
                                }
                                if (typeof value === 'string' && !isNaN(parseFloat(value))) {
                                  return `$${parseFloat(value).toLocaleString('en-US')}`;
                                }
                                return value;
                              };

                              const displayValue = isAmount 
                                ? formatCurrency(cell)
                                : isEmail
                                  ? <EmailLink email={cell} />
                                  : isStreetAddress
                                    ? formatStreetAddress(cell)
                                    : cell; // Removed the case transformation here

                              return (
                                <td key={cellIndex} className="px-1.5 py-2">
                                  <div
                                    className={`text-[0.8125rem] font-inter my-2 ${
                                      showFullText[`row-${rowIndex}-col-${cellIndex}`]
                                        ? ''
                                        : 'truncate'
                                    } ${
                                      isEmail 
                                        ? 'inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]'
                                        : isTotalColumn
                                          ? 'inline-flex p-1 justify-center items-center gap-2.5 font-bold rounded-[0.4375rem] bg-[#EBAE12] bg-opacity-20'
                                          : ''
                                    }`}
                                    style={{ 
                                      color: isTotalColumn ? '#BC8B0E' : '#000',
                                      fontWeight: 'normal'
                                    }}
                                    onClick={() =>
                                      setShowFullText({
                                        ...showFullText,
                                        [`row-${rowIndex}-col-${cellIndex}`]: !showFullText[
                                          `row-${rowIndex}-col-${cellIndex}`
                                        ],
                                      })
                                    }
                                  >
                                    {displayValue}
                                  </div>
                                </td>
                              );
                            })}
                        </tr>
                      ))}
                    </tbody>
                    </table>
                  </div>
                </div>
              </div>

{/* <tbody className="bg-white overflow-auto">
                      {response.table.slice(startIndex, endIndex).map((row, rowIndex) => (
                        <tr key={rowIndex} className="hover:bg-gray-50 border-b border-[#F2F2F2]">
                          <td className="px-1.5 py-2">
                            <div className="flex items-center">
                              <div>
                                <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-0.5 py-0.5">
                                  {row['Full Name'] ? (
                                    row['Full Name'].split(' ').length > 3 ? (
                                      <>
                                        <div>
                                          {row['Full Name'].split(' ')
                                            .slice(0, 3)
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                            .join(' ')}
                                        </div>
                                        <div>
                                          {row['Full Name'].split(' ')
                                            .slice(3, 6)
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                            .join(' ')}
                                        </div>
                                        <div>
                                          {row['Full Name'].split(' ')
                                            .slice(6)
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                            .join(' ')}
                                        </div>
                                      </>
                                    ) : (
                                      row['Full Name'].split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                        .join(' ')
                                    )
                                  ) : ''}
                                </div>
                                <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%] py-1">
                                  {row['Phone Number'] ? `+${row['Phone Number']}` : ''}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-1.5 py-2">
                            <div>
                              <div className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%] mb-0.5 py-0.5">
                                {renderTableCell(row['City'])}
                              </div>
                              <div className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%] py-1">
                                {row['State'] && row['Zip Code'] ? `${row['State']}, ${row['Zip Code']}` : ''}
                              </div>
                            </div>
                          </td>
                          {Object.entries(row)
                            .filter(([key]) => 
                              key !== 'Full Name' && 
                              key !== 'Phone Number' && 
                              key !== 'City' && 
                              key !== 'State' && 
                              key !== 'Zip Code'
                            )
                            .map(([key, cell], cellIndex) => {
                              const isTotalColumn = key.includes('Lifetime') || key.includes('Total');
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              const isEmail = typeof cell === 'string' && emailRegex.test(cell);
                              const isAmount = isTotalColumn || key.includes('Contribution') || key.includes('Donations') || key.includes('Amount');
                              const isStreetAddress = key === 'Street Address';
                              
                              const formatCurrency = (value) => {
                                if (typeof value === 'number') {
                                  return `$${value.toLocaleString('en-US')}`;
                                }
                                if (typeof value === 'string' && !isNaN(parseFloat(value))) {
                                  return `$${parseFloat(value).toLocaleString('en-US')}`;
                                }
                                return value;
                              };

                              const displayValue = isAmount 
                              ? formatCurrency(cell)
                              : isEmail
                                ? <EmailLink email={cell} />
                                : isStreetAddress
                                  ? formatStreetAddress(cell)
                                  : typeof cell === 'string'
                                    ? cell.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
                                    : cell;

                              return (
                                <td key={cellIndex} className="px-1.5 py-2">
                                  <div
                                    className={`text-[0.8125rem] font-inter my-2 ${
                                      showFullText[`row-${rowIndex}-col-${cellIndex}`]
                                        ? ''
                                        : 'truncate'
                                    } 
                                    ${
                                    //   isEmail 
                                    //   ? 'inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]'
                                    //   : ''
                                    // }`}
                                  
                                      isEmail 
                                        ? 'inline-flex p-1 justify-center items-center gap-2.5 rounded-[0.4375rem] bg-[#ECF9F7]'
                                          : isTotalColumn
                                          ? 'inline-flex p-1 justify-center items-center gap-2.5 font-bold rounded-[0.4375rem] bg-[#EBAE12] bg-opacity-20'
                                          : ''
                                      }`}
                                  
                                    style={{ 
                                      //highlighting yellow bubble for lifetime
                                      //  color: '#000',
                                      color: isTotalColumn ? '#BC8B0E' : '#000',
                                      fontWeight: 'normal'  // Ensuring normal font weight
                                    }}
                                    onClick={() =>
                                      setShowFullText({
                                        ...showFullText,
                                        [`row-${rowIndex}-col-${cellIndex}`]: !showFullText[
                                          `row-${rowIndex}-col-${cellIndex}`
                                        ],
                                      })
                                    }
                                  >
                                    {displayValue}
                                  </div>
                                </td>
                              );
                            })}
                        </tr>
                      ))}
                    </tbody> */}

                    
                 
            </div>
            <div className="flex w-full h-5 justify-between items-start pt-[3px]">
              <div className="flex items-end gap-4">
                <div style={{ cursor: 'pointer'}} title="Positive Feedback" onClick={() => handleFeedback(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13.5255 11.0157L10.4771 17.7656C9.87075 17.7656 9.28922 17.5286 8.86046 17.1066C8.4317 16.6847 8.19083 16.1124 8.19083 15.5157V12.5157H3.87738C3.65645 12.5182 3.43761 12.4733 3.23603 12.3843C3.03445 12.2953 2.85495 12.1642 2.70996 12.0001C2.56498 11.836 2.45798 11.6429 2.39638 11.4341C2.33477 11.2252 2.32004 11.0057 2.3532 10.7908L3.40488 4.04087C3.46 3.6832 3.64461 3.35718 3.9247 3.12288C4.20479 2.88859 4.56149 2.76181 4.92907 2.7659H13.5255M13.5255 11.0157V2.7659M13.5255 11.0157H15.5603C15.9916 11.0233 16.4107 10.8746 16.738 10.5981C17.0653 10.3216 17.2781 9.93647 17.3359 9.51577V4.26587C17.2781 3.84517 17.0653 3.46001 16.738 3.1835C16.4107 2.90699 15.9916 2.75839 15.5603 2.7659H13.5255" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div style={{ cursor: 'pointer'}} title="Negative Feedback" onClick={() => handleFeedback(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M5.1464 9.51558L8.19477 2.76562C8.80113 2.76563 9.38265 3.00268 9.81141 3.42463C10.2402 3.84658 10.481 4.41888 10.481 5.01561V8.01559H14.7945C15.0154 8.01313 15.2343 8.05797 15.4358 8.147C15.6374 8.23604 15.8169 8.36714 15.9619 8.53122C16.1069 8.69531 16.2139 8.88845 16.2755 9.09727C16.3371 9.30609 16.3518 9.5256 16.3187 9.74058L15.267 16.4905C15.2119 16.8482 15.0273 17.1742 14.7472 17.4085C14.4671 17.6428 14.1104 17.7696 13.7428 17.7655H5.1464M5.1464 9.51558V17.7655M5.1464 9.51558H2.86012C2.45588 9.51558 2.0682 9.67362 1.78236 9.95492C1.49652 10.2362 1.33594 10.6178 1.33594 11.0156V16.2655C1.33594 16.6634 1.49652 17.0449 1.78236 17.3262C2.0682 17.6075 2.45588 17.7655 2.86012 17.7655H5.1464" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div> 
              </div>
              <div className="flex items-center gap-2.5">
                <span className="text-[#000] font-inter text-[0.8125rem] font-normal leading-[120%]">
                  {startIndex + 1} - {Math.min(endIndex, response?.table?.length)} of {response?.table?.length} items
                </span>
                <button
                  className="flex justify-center items-center px-2 py-1 gap-2.5
                    text-gray-600 hover:bg-gray-100 disabled:opacity-50 
                    disabled:cursor-not-allowed w-8 h-8"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={disablePreviousButton}
                >
                  <ChevronLeft size={20} />
                </button>
                <button
                  className="flex justify-center items-center px-2 py-1 gap-2.5
                    text-gray-600 hover:bg-gray-100
                    disabled:opacity-50 disabled:cursor-not-allowed
                    w-8 h-8"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={disableNextButton}
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            </div>
          </>  
          
        ) : (
          <ErrorMessage />
        )}
        
      </>
    </div>
    )}
      
      {(isTablePopup) && (
        <TablePopupView
          response={response}
          startIndex={startIndex}
          endIndex={endIndex}
          isVisible={isTablePopup}
          onClose={() => setIsTablePopup(false)}
        />
      )}  
    </>
  );
};
export default ConversationCard;