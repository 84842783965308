/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from 'react';
import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
import { Info, Trash2, X, ChevronDown, UserPlus, Edit, Plus, ChevronUp, ChevronsUpDown } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2'
import { useRecoilState } from 'recoil';
import { activeItemState } from "../../store/atoms";
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm'
import useLambda from '../hooks/useLambda';
import { awsExports } from '../../aws-exports';
import { fetchUserAttributes } from "@aws-amplify/auth";
import { logEvent } from '../../cloudwatchLogger';
import WelcomeHeader from './WelcomeHeader';
import { importNotificationsState } from '../../store/atoms';
import { useMediaQuery } from '@mui/material';

Amplify.configure(awsExports);



const UserTable = ({ users, isLoading, handleDeleteUser, handleSort, setSelectedUser, setIsEditModalOpen, setModalTitle, sortColumn,
  sortDirection  }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const isMobile = useMediaQuery('(max-width:768px)');
  // if (isLoading) {
  //   return (
  //     <div className="bg-white rounded-lg shadow-md overflow-x-auto w-full">
  //       <table className="w-full min-w-[600px]">
  //         <thead>
  //           <tr className="border-b">
  //             <th className="py-2 text-left text-gray-600">No.</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">First Name</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">Last Name</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">Role</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">Team Name</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">Email</th> {/* Text color changed to gray */}
  //             <th className="py-2 text-left text-gray-600">Action</th> {/* Text color changed to gray */}
  //           </tr>
  //         </thead>
  //         <tbody>getGloblLimit
  //           {Array(5).fill().map((_, index) => (
  //             <tr key={index} className="border-b border-gray-200">
  //               <td className="py-3 px-4"><div className="h-4 bg-gray-600 rounded animate-pulse"></div></td>
  //               <td className="py-3 px-4"><div className="h-4 bg-gray-600 rounded animate-pulse"></div></td>
  //               <td className="py-3 px-4"><div className="h-4 bg-gray-600 rounded animate-pulse"></div></td>
  //               <td className="py-3 px-4"><div className="h-4 bg-gray-600 rounded animate-pulse"></div></td>
  //               <td className="py-3 px-4"><div className="h-4 w-4 bg-gray-600 rounded animate-pulse"></div></td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   );
  // }

  const SortIcon = ({ column }) => {
    if (sortColumn !== column) {
      return <ChevronsUpDown size={16} className="ml-1 text-gray-400" />;
    }
    return sortDirection === 'asc' ? (
      <ChevronUp size={16} className="ml-1 text-gray-900" />
    ) : (
      <ChevronDown size={16} className="ml-1 text-gray-900" />
    );
  };

  if (users.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 text-center">
        <p className="text-gray-600">No users to display</p>
      </div>
    );
  }

  // New function to handle the initial click of Remove button
    const handleRemoveClick = (email, customAccess, customGroup) => {
      setUserToDelete({ email, customAccess, customGroup });
      setShowConfirmDialog(true);
    };

  return (
    <div className="bg-white rounded-lg flex flex-col h-full w-full">
      <div className="flex-grow flex flex-col">
        <div className="overflow-x-auto flex-grow">
          <div className="h-full overflow-y-auto" style={{ maxHeight: isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 235px)' }}>
            <div className="w-full min-w-[600px] grid grid-cols-[48px_1fr_1fr_1fr_1fr_2fr_128px] gap-2">
              <div className="contents">
                <div className="col-span-full grid grid-cols-subgrid gap-4 px-2.5 items-center border-b border-[#D9D9D9] sticky top-0 bg-white z-10">
                  <div className="flex h-12 justify-center items-center">
                    <span className="text-gray-400 text-[0.8125rem]">No.</span>
                  </div>
                  <div className="flex h-12 items-center">
                    <button onClick={() => handleSort('firstName')} className="flex items-center text-gray-400 text-[0.8125rem]">
                      First Name <SortIcon column="firstName" />
                      {/* <ChevronDown size={16} className="ml-1 text-gray-500" /> */}
                    </button>
                  </div>
                  <div className="flex h-12 items-center">
                    <button onClick={() => handleSort('lastName')} className="flex items-center text-gray-400 text-[0.8125rem]">
                      Last Name <SortIcon column="lastName" />
                      {/* <ChevronDown size={16} className="ml-1 text-gray-500" /> */}
                    </button>
                  </div>
                  <div className="flex h-12 items-center">
                    <button onClick={() => handleSort('customAccess')} className="flex items-center text-gray-400 text-[0.8125rem]">
                        Role <SortIcon column="customAccess" />
                        {/* <ChevronDown size={16} className="ml-1 text-gray-500" /> */}
                      </button>
                    {/* <span className="text-gray-400 text-[0.8125rem]">Role</span> */}
                  </div>
                  <div className="flex h-12 items-center">
                    <button onClick={() => handleSort('customGroup')} className="flex items-center text-gray-400 text-[0.8125rem]">
                      Team Name <SortIcon column="customGroup" />
                        {/* <ChevronDown size={16} className="ml-1 text-gray-500" /> */}
                    </button>
                    {/* <span className="text-gray-400 text-[0.8125rem]">Team Name</span> */}
                  </div>
                  <div className="flex h-12 items-center">
                    <button onClick={() => handleSort('email')} className="flex items-center text-gray-400 text-[0.8125rem]">
                      Email <SortIcon column="email" />
                        {/* <ChevronDown size={16} className="ml-1 text-gray-500" /> */}
                    </button>
                  </div>
                  <div className="flex h-12 items-center">
                    <span className="text-gray-400 text-[0.8125rem]">Actions</span>
                  </div>
                </div>
              </div>
              {users.map((user, index) => (
                <div key={index} className="contents">
                  <div className="col-span-full grid grid-cols-subgrid gap-4 px-2.5 items-center border-b border-[#D9D9D9]">
                    <div className="flex h-12 justify-center items-center">
                      <span className="text-[0.8125rem]">{index + 1}</span>
                    </div>
                    <div className="flex h-12 items-center overflow-hidden">
                      <span className="text-[0.8125rem] truncate">{user.firstName}</span>
                    </div>
                    <div className="flex h-12 items-center overflow-hidden">
                      <span className="text-[0.8125rem] truncate">{user.lastName}</span>
                    </div>
                    <div className="flex h-12 items-center overflow-hidden -ml-2">
                      <div className="truncate">
                        {user.customAccess === 'tenantAdminRole' ? (
                          <span className="inline-block px-[0.375rem] py-1 text-[0.8125rem] font-bold leading-tight rounded-[7px] border border-[#F9E6B6] bg-[#FDF7E7] text-[#EBAE12] whitespace-nowrap">
                            Administrator
                          </span>
                        ) : user.customAccess === 'userRole' ? (
                          <span className="inline-block px-[0.375rem] py-1 text-[0.8125rem] font-bold leading-tight rounded-[7px] border border-[#FAD1C9] bg-[#FDF0EE] text-[#EF6950]">
                            User
                          </span>
                        ) : user.customAccess === 'superAdminRole' ? (
                          <span className="inline-block px-[0.375rem] py-1 text-[0.8125rem] font-bold leading-tight rounded-[7px] border border-[#C4EDE6] bg-emerald-50 text-emerald-400 whitespace-nowrap">
                            Global Admin
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex h-12 items-center overflow-hidden">
                      <span className="text-[0.8125rem] truncate">{user.customGroup}</span>
                    </div>
                    <div className="flex h-12 items-center overflow-hidden">
                      <a href={`mailto:${user.email}`} className="flex items-center text-[0.8125rem] truncate hover:underline">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none" className="mr-2">
                          <path d="M9.39645 7.52219V10.6542C9.39645 10.9311 9.28646 11.1966 9.09067 11.3924C8.89488 11.5882 8.62934 11.6982 8.35245 11.6982H2.61041C2.33352 11.6982 2.06798 11.5882 1.87219 11.3924C1.6764 11.1966 1.56641 10.9311 1.56641 10.6542V4.91217C1.56641 4.63528 1.6764 4.36974 1.87219 4.17395C2.06798 3.97816 2.33352 3.86816 2.61041 3.86816H5.74243" stroke="black" stroke-width="1.04401" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.83008 2.30225H10.9621V5.43427" stroke="black" stroke-width="1.04401" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.2207 8.04428L10.9627 2.30225" stroke="black" stroke-width="1.04401" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> */}
                        <span>{user.email}</span>
                      </a>
                    </div>
                    <div className="flex h-12 items-center">
                      <div className="flex items-center space-x-4">
                        <button 
                          className="text-[#EBAE12] font-inter text-[13px] font-bold leading-[120%] hover:text-[#EBAE12]/80 transition-colors duration-200 whitespace-nowrap"
                          onClick={() => {
                            setSelectedUser(user);
                            setIsEditModalOpen(true);
                            setModalTitle('Edit User')
                          }}
                        >
                          Edit
                        </button>
                         {/* Modified button code */}
                         <button 
                            className="text-[#EF6950] font-inter text-[13px] font-bold leading-[120%] hover:text-[#EF6950]/80 transition-colors duration-200 whitespace-nowrap"
                            onClick={() => handleRemoveClick(user.email, user.customAccess, user.customGroup)}
                        >
                            Remove
                        </button>
                        {/* Add confirmation dialog */}
                        {showConfirmDialog && (
                        <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${isMobile ? 'p-4':''}`}>
                          {/* Overlay */}
                          <div 
                            className="fixed inset-0 bg-black/10" // Changed from opacity-50 to bg-black/30
                            onClick={() => setShowConfirmDialog(false)}
                          ></div>
                          
                          {/* Modal Container */}
                          <div className="relative w-full max-w-md mx-auto my-6">
                            <div className={`inline-flex flex-col items-start p-5 gap-5 rounded-[0.875rem] border border-[#F2F2F2] bg-white shadow-[4px_4px_10px_0px_rgba(0,0,0,0.10)] ${isMobile ? 'w-full max-w-[29.6875rem]':''}`}>
                              <div className={`flex flex-col items-start ${isMobile ? 'w-full' : 'w-[29.6875rem]'} pb-5 gap-5 border-b border-[#D9D9D9]`}>
                                <div className="flex items-center gap-2.5 self-stretch">
                                  <div className="flex items-center gap-2.5 flex-[1_0_0]">
                                    <p className="text-[#EF6950] text-[1.25rem] font-bold leading-[120%]">Delete User</p>
                                  </div>
                                  <X className="w-6 h-6 cursor-pointer" onClick={() => setShowConfirmDialog(false)}/>
                                </div>
                              </div>
                              <div className="flex flex-col items-start gap-2.5 self-stretch">
                                <p className="self-stretch text-black font-inter text-base font-bold leading-tight">
                                  Are you sure you want to delete this user?
                                </p>
                                <p className="self-stretch text-[#8E8E8E] font-inter text-base font-normal leading-tight">
                                  This action cannot be undone. The user will lose all access to the system immediately.
                                </p>
                              </div>
                              <div className="flex flex-col items-end gap-2.5 self-stretch pt-5 border-t border-[#D9D9D9]">
                                <div className="flex items-center gap-[0.625rem]">
                                  <button 
                                    onClick={() => setShowConfirmDialog(false)}
                                    className="flex items-start px-3.5 py-2.5 gap-1 rounded-[0.4375rem] bg-gray-300 hover:bg-gray-400 transition-colors cursor-pointer font-bold"
                                  >
                                    Cancel
                                  </button>
                                  <button 
                                    onClick={() => {
                                      handleDeleteUser(userToDelete.email, userToDelete.customAccess, userToDelete.customGroup);
                                      setShowConfirmDialog(false);
                                    }}
                                    className="flex items-start px-3.5 py-2.5 gap-1 rounded-[0.4375rem] bg-[#EBAE12] hover:bg-[#D99E10] transition-colors cursor-pointer text-white font-bold"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
  const Modal = ({ isOpen, onClose, children, isSuperAdmin, loggedInUserLimit, modalTitle }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4 sm:p-6 md:p-8">
        <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-[90%] sm:max-w-md md:max-w-lg lg:max-w-xl">
          <div className="flex items-center gap-2.5 self-stretch mb-4">
            <div className="flex items-center gap-2.5 flex-1 flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20 8V14" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23 11H17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span></span>
              <h2 className="text-lg sm:text-xl font-bold">{modalTitle}</h2>
              {(!isSuperAdmin && !modalTitle.toLowerCase().includes('edit')) && (
                <div className="ml-2 relative group">
                  <Info size={20} className="text-gray-500 hover:text-gray-700 cursor-pointer" />
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Your users limit is {loggedInUserLimit}
                  </div>
                </div>
              )}
            </div>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 self-end sm:self-auto">
              <X size={24} />
            </button>
          </div>
          <div className="max-h-[70vh] overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
  );
};


const UserManagement = () => {
    const [activeItem, setActiveItem] = useRecoilState(activeItemState);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const [roles, setRoles] = useState([]);
    const [displayRole, setDisplayRole] = useState('');
    const [sub, setSub] = useState('');
    const [tenantGroup, setTenantGroup] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [tenantsUsers, setTenantsUsers] = useState([]);
    const [limit, setLimit] = useState('');
    const [loggedInUserLimit, setLoggedInUserLimit] = useState('');
    const [globalLimit, setGlobalLimit] = useState('');
    const [isSuperAdmin, setisSuperAdmin] = useState();
    const [isTenantAdmin, setIsTenantAdmin] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [lambdaFunctionName] = useState(awsExports.Lambda.userManagement);
    const [addButtonText, setAddButtonText] = useState('Add New User');
    const [firstName, setFirstName] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const[user, setSelectedUser] = useState([]);
    const[isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const [importNotification,setImportNotifications] = useRecoilState(importNotificationsState);

    const isMobile = useMediaQuery('(max-width:768px)');

    const { configureLambda, decodeToken, getJWT } = useLambda();
    /* to set the active nav bar item */
    useEffect(() => {
        setActiveItem('User Management');
    }, []);

   

    const handleSort = (column) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    };
    //console.log('tenant users are: '+JSON.stringify(tenantsUsers));
    const sortedUsers = [...tenantsUsers].sort((a, b) => {
      if (!sortColumn) return 0;
      
      // Handle null/undefined values
      const aValue = a[sortColumn]?.toLowerCase() ?? '';
      const bValue = b[sortColumn]?.toLowerCase() ?? '';
      
      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  
    useEffect(() => {
      const fetchUserName = async () => {
        try {
          const userAttributes = await fetchUserAttributes();
          setFirstName(userAttributes.given_name);
        } catch (error) {
          console.error('Failed to fetch roles:', error);
        }
      };
    
      fetchUserName();
    }, []);

    const fetchRoles = async () => {
      setIsLoading(true);
      try {
        const token = await getJWT();
        //console.log('jwt token:'+token);
        setJwtToken(token);
        await configureLambda(token);
        //console.log('lambda is configured')
        const decodedToken = decodeToken(token);
        //console.log('decoded toke ins: '+JSON.stringify(decodedToken));
        const cognitoRoles = decodedToken['cognito:roles'];
        if(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole')) {
          setDisplayRole('Global Admin');
        } else if(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole')) {
          setDisplayRole('Administrator');
        }
        const subAttribute = decodedToken['sub'];
        const tenantgroup = decodedToken['cognito:groups'];
        //console.log('tenant groups: '+tenantgroup);
        const email = decodedToken['email'];
        let filteredGroups = [];
        if (tenantgroup.includes("Tenants")) {
          filteredGroups = tenantgroup.filter(group => group !== 'Tenants');
        } else if (tenantgroup) {
          filteredGroups = [...tenantgroup];
        }
        setisSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
        setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
        setUserEmail(email);
        setRoles(cognitoRoles);
        setSub(subAttribute);
        setTenantGroup(filteredGroups);
        //console.log('user Email is : '+userEmail);
        //console.log('setting of email, roles, sub, and groupnames done');
      
      } catch (error) {
        console.error('Failed to fetch roles:', error);
        // logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
      }
    };
    
    /* set the jwt token and configure the lambda */
    useEffect(() => {
      fetchRoles();
    }, []);
   
    const updateNotifications = (success, header, message, timestamp) => {
      setImportNotifications((prevNotifications) => {
        const newNotification = {
          id: prevNotifications.length + 1,
          success,
          title: header,
          description: message,
          timestamp
        };
        const updatedNotifications = [...prevNotifications, newNotification];
        
        // Store in local storage
        localStorage.setItem('importNotifications', JSON.stringify(updatedNotifications));
        
        return updatedNotifications;
      });
    };
    
    const fetchTenantUsers = async () => {
      try {
        //setLoading(true);
        await configureLambda(jwtToken);
        //console.log('jwt token is: '+jwtToken);
        const lambda = new AWS.Lambda();
        const payload = {
          'operation': 'fetchTenantUsers',
          isSuperAdmin,
          tenantGroup,
          userEmail,
        };
        const params = {
          //FunctionName: 'createTenant',
          FunctionName: lambdaFunctionName,
          Payload: JSON.stringify(payload),
        };
  
        const result = await lambda.invoke(params).promise();
        const data = JSON.parse(result.Payload);
        const users = JSON.parse(data.body);
        //console.log(`the users are  : ${JSON.stringify(users)}`);
        setTenantsUsers(users);
        //setLoading(false);
        //logEvent({ eventType: 'fetchedTenantUsers', details: `Fetched tenant users for user: ${userEmail}` });
      } catch (error) {
        console.error('Error fetching tenant users:', error);
        //setLoading(false);
        // logEvent({ eventType: 'fetchTenantUsersError', details: `Error fetching tenant users: ${error.message}` });
      } finally {
        setIsLoading(false);
      }
    };
    
    /* getTenantUsers when the isSuperAdmin is updated renders */
    useEffect(() => {
      //console.log('inside fetching user limit');
      userLimit();
      if(jwtToken)
        fetchTenantUsers();
      if(!isSuperAdmin){
        //console.log('fetching limit if not super admin'+!isSuperAdmin);
        fetchLimit();
      }
    },[isSuperAdmin]);

    // useEffect(() => {
    //   if(setIsModalOpen){
    //     fetchLimit();
    //   }
    // }, [setIsModalOpen]);

    useEffect(() => {
      const getGloblLimit = async () => {
        try {
          await configureLambda(jwtToken);
          const lambda = new AWS.Lambda();
          const payload = {
            'operation': 'fetchGlobalLimit',
          };
          //console.log('payload of fetchGroups is: ' + JSON.stringify(payload));
          const params = {
            //FunctionName: 'createTenant',
            FunctionName: lambdaFunctionName,
            Payload: JSON.stringify(payload),
          };
  
          const result = await lambda.invoke(params).promise();
          const data = JSON.parse(result.Payload);
          const limitGlobal = JSON.parse(data.body);
          //console.log('group limit is: ' + groupLimit.size);
          setGlobalLimit(limitGlobal.size);
          //logEvent({ eventType: 'fetchedGlobalLimit', details: `Fetched global limit` });
        } catch (error) {
          console.error('Error fetching the global limit:', error);
          // logEvent({ eventType: 'fetchLimitError', details: `Error fetching the global limit: ${error.message}` });
        } 
      }

      getGloblLimit();
    })

    const fetchLimit = async () => {
      //console.log('is superadmin: ' + isSuperAdmin + ' jwt token is: ' + jwtToken);
      //setIsLoading(true);
      if (!isSuperAdmin) {
        try {
          await configureLambda(jwtToken);
          const lambda = new AWS.Lambda();
          const payload = {
            'operation': 'fetchLimit',
            userEmail,
            tenantGroup,
          };
          //console.log('payload of fetchGroups is: ' + JSON.stringify(payload));
          const params = {
            //FunctionName: 'createTenant',
            FunctionName: lambdaFunctionName,
            Payload: JSON.stringify(payload),
          };
  
          const result = await lambda.invoke(params).promise();
          const data = JSON.parse(result.Payload);
          const groupLimit = JSON.parse(data.body);
          //console.log('group limit is: ' + groupLimit.size);
          setLimit(groupLimit.size);
          //logEvent({ eventType: 'fetchedLimit', details: `Fetched limit for user: ${userEmail}` });
        } catch (error) {
          console.error('Error fetching the limit:', error);
          //logEvent({ eventType: 'fetchLimitError', details: `Error fetching the limit: ${error.message}` });
        } 
      }
    };
  
    const userLimit = async () => {
      try {
        // Fetch user attributes
        const userAttributes = await fetchUserAttributes();
        const tenantLimitAttribute = userAttributes['custom:tenantLimit'];
        setLoggedInUserLimit(tenantLimitAttribute);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        return null;
      }
    };
    /* handle delete of user*/
    const handleDeleteUser = async (email, role, group) => {
      try {
        
        await configureLambda(jwtToken);
        const lambda = new AWS.Lambda();
        const payload = {
          'operation': 'disableUsers',
          email,
          role,
          group,
          isSuperAdmin,
        };
        //console.log('pay load is : '+JSON.stringify(payload));
        const params = {
          //FunctionName: 'createTenant',
          FunctionName: lambdaFunctionName,
          Payload: JSON.stringify(payload),
        };
  
        const result = await lambda.invoke(params).promise();
        const data = JSON.parse(result.Payload);
        const bodyData = JSON.parse(data.body);
        // setTenantsUsers(tenantsUsers => tenantsUsers
        //                 .filter(user => user !== email));
        setTenantsUsers(prevUsers => prevUsers.filter(user => user.email !== email));
        //setMessage(bodyData.message || 'Lambda function called successfully');
        logEvent({ eventType: 'deletedTenantUser', details: `User deleted: ${email}` });
        updateNotifications(true,'User Management',`User deleted: ${email}`, new Date().getTime());
        window.location.reload();
      } catch (error) {
        console.log('error deleting user: '+error);
        updateNotifications(false,'User Management',`Error deleting user: ${email}`, new Date().getTime());
        //setMessage(`Error creating tenant: ${error.message}`);
        logEvent({ eventType: 'errorDeletingTenantUser', details: `Error deleting tenant user: ${error.message}` });
      }
      console.log(`Deleting user: ${email}`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    };
  

  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setIsSidebarOpen(true);
        } else {
            setIsSidebarOpen(false);
        }
    };

    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
}, []);

// Update this function to close sidebar on mobile when navigating
useEffect(() => {
    setActiveItem('User Management');
    if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
    }
}, []);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
};
//console.log('limit is: '+limit);
//console.log('logged in user limit is: '+loggedInUserLimit);
const isReadOnly = (limit >= loggedInUserLimit);
//console.log('is read only: '+isReadOnly);
const addUserReadOnly = globalLimit > 200;
//const addUserReadOnly = false;
//console.log(`add user read only button: ${globalLimit}`);

const handleFormTypeChange = (formType) => {
  if (formType === 'createTenant') {
      setAddButtonText('Add New Administrator');
  } else {
      setAddButtonText('Add New User');
  }
};

if(isLoading) {
  return (
    <div className="w-[64.625rem] h-[46.5625rem] p-5 flex-shrink-0 flex items-center justify-center">
      <img src="/UM_Loading.svg" alt="UM Loading" className="w-[64.625rem] h-[46.5625rem]" />
    </div>
    
  );
}


  return (
    <div style={{ 
      backgroundColor: '#FFF', 
      color: '#000', 
      minHeight: '100vh', 
      display: "flex", 
      width: "100%",
      overflowX: "hidden"
    }}>
      <div className='w-full'>
        <div className="flex h-screen">
          <div className="flex-1 overflow-hidden">
            <WelcomeHeader name={firstName} role={displayRole} />
            <main className={`${isMobile ? 'p-5': 'p-10'} flex flex-col items-start flex-1 overflow-y-auto h-full gap-[2.5rem]`}>
              <div className={isMobile
                ? "flex w-full flex-col items-start gap-2.5"
                : "flex justify-between items-center self-stretch"
              }>
                <div className={isMobile
                  ? "flex w-full flex-col items-start gap-1.5"
                  : "flex flex-col items-start w-[37.5rem] gap-1.5"
                }>
                  <h1 className="text-[1.5625rem] font-bold leading-[120%] text-black self-stretch">User Management</h1>
                  <p className={`${isMobile ? 'w-full' : 'w-[37.5rem]'} text-base font-inter font-normal leading-[120%] text-black`}>
                    Add user or update permission
                  </p>
                </div>
                <button
                  className={`flex justify-center items-center rounded-[0.4375rem] bg-[#EBAE12] hover:bg-[#D99E10] text-white font-bold ${
                    isMobile
                      ? "p-[0.625rem_0.875rem] gap-1" // Added w-full for mobile
                      : "py-[0.625rem] px-[0.875rem] gap-1"
                  } ${
                    addUserReadOnly ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={() => {
                    if (!addUserReadOnly) {
                      setIsModalOpen(true);
                      setModalTitle('Add New User ');
                    }
                  }}
                  disabled={addUserReadOnly}
                >
                  <Plus size={20} />
                  Add New User
                </button>
              </div>
              <div className="flex flex-col items-start self-stretch">
                <UserTable
                  users={sortedUsers}
                  isLoading={isLoading}
                  handleDeleteUser={handleDeleteUser}
                  handleSort={handleSort}
                  sortColumn={sortColumn}      
                  sortDirection={sortDirection} 
                  isSuperAdmin={isSuperAdmin}
                  isTenantAdmin={isTenantAdmin}
                  setSelectedUser={setSelectedUser}
                  setIsEditModalOpen={setIsEditModalOpen}
                  setModalTitle={setModalTitle}
                />
              </div>
            </main>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isSuperAdmin={isSuperAdmin}
        loggedInUserLimit={loggedInUserLimit}
        modalTitle={modalTitle}
      >
        <AddUserForm
          isSuperAdmin={isSuperAdmin}
          roles={roles}
          sub={sub}
          tenantGroup={tenantGroup}
          isReadOnly={isReadOnly}
          onFormTypeChange={handleFormTypeChange}
        />
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        isSuperAdmin={isSuperAdmin}
        modalTitle={modalTitle}
      >
        <EditUserForm
          user={user}
          isSuperAdmin={isSuperAdmin}
        />
      </Modal>
    </div>
  );
};

export default UserManagement;




{/* <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                        <div className="flex flex-col items-start">
                            <h1 className="text-2xl font-semibold text-black mb-4 md:mb-0">User Management</h1>
                            { isSuperAdmin ? (
                              // <p className="text-gray-600">Add user or Administrator</p>
                              <p className="text-gray-600">Add user</p>
                              ) : <p className="text-gray-600"></p> 
                            }
                            
                          </div>
                          
                          <div className="relative group">
                              <button 
                                className={`flex justify-center items-center gap-1 py-2.5 px-3.5 rounded-[7px] 
                                          bg-[#EBAE12] hover:bg-[#D99E10] text-white font-bold
                                          ${
                                            addUserReadOnly ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                onClick={() => {
                                          if (!addUserReadOnly) {
                                            setIsModalOpen(true);
                                            setModalTitle('Add New User ');
                                          }
                                        }}
                                disabled={addUserReadOnly}
                              >
                                <Plus size={20} />
                                Add New User
                              </button>
                              
                              {addUserReadOnly && (
                                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                                      User limit exceeded
                                  </div>
                              )}
                          </div>
                      </div>
                      <UserTable 
                        users={sortedUsers} 
                        isLoading={isLoading} 
                        handleDeleteUser={handleDeleteUser} 
                        handleSort={handleSort}
                        isSuperAdmin={isSuperAdmin}
                        isTenantAdmin={isTenantAdmin}
                        setSelectedUser={setSelectedUser}
                        setIsEditModalOpen={setIsEditModalOpen}
                        setModalTitle= {setModalTitle}
                      /> */}