import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Lock, Smartphone, ChevronDown } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import { 
  setUpTOTP, 
  updateMFAPreference, 
  verifyTOTPSetup,
  getCurrentUser,
  fetchUserAttributes,
  confirmSignIn,
  fetchMFAPreference,
  updateUserAttribute,
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
  rememberDevice
} from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import {mfaActiveState} from '../../store/atoms';
import { Alert } from '@mui/material';

const MFASetupFlow = ({setIsAuthenticated}) => {
  const [error, setError] = useState('');
  const [qrCode, setQrCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const [isVerificationCodeFocused, setIsVerificationCodeFocused] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [mfaType, setMfaType] = useState('sms');
  const [isMfaActivated, setIsMfaActivated] = useRecoilState(mfaActiveState);
  const [smsSent, setSmsSent] = useState(false);

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^(\+1)?\d{10}$/;
    return regex.test(phoneNumber);
  };

  // useEffect(() => {
  //   async function handleFirstTimeLogin() {
  //     try {
  //       if(isMfaActivated === null) {
  //         const currentUser = await getCurrentUser();
  //         // Fetch user attributes to check custom attribute for MFA setup
  //         const userAttributes = await fetchUserAttributes();
  //         console.log('this is userAttributes: '+JSON.stringify(userAttributes));
  //         const mfaSetupCompleted = userAttributes['custom:mfa_setup_completed'];
          
  //         // Check if MFA is already set up
  //         const mfaPreference = await fetchMFAPreference();
  //         const isMFAConfigured = mfaPreference.TOTP === true;
      
  //         if (!isMFAConfigured || !mfaSetupCompleted || mfaSetupCompleted !== 'true') {
  //           // Set up TOTP for MFA
  //           const totpSecret = await setUpTOTP(currentUser);
  //           // console.log('this is totp secret: '+JSON.stringify(totpSecret));
      
  //           // Generate the QR code link for authenticator apps
  //           const qrCode = `otpauth://totp/FindRaiser:${currentUser.username}?secret=${totpSecret.sharedSecret}&issuer=FindRaiser`;
  //           setQrCode(qrCode);
  //           setIsMfaActivated(false);
  //         } else {
  //           setIsMfaActivated(true);
  //           //SET MFA TYPE HERE
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error during first-time MFA setup:', error);
  //       //setError('Error during first-time MFA setup')
  //     }
  //   }

  //   handleFirstTimeLogin();
  // },[]);

  const handleDontAskAgainChange = (e) => {
    setDontAskAgain(e.target.checked);
  };

  async function updateLastLogin() {
    try {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        // console.log('No authenticated user found');
        return;
      }
  
      // Get the current timestamp
      const timestamp = new Date().toISOString();
      // console.log('Setting login time to:', timestamp);
  
      // Store the login time in localStorage
      localStorage.setItem('loginTime', timestamp);
  
      // console.log('Last login time stored in localStorage successfully');
    } catch (error) {
      console.error('Error storing last login time:', error);
      if (error instanceof Error) {
        console.error('Error type:', error.constructor.name);
        console.error('Error message:', error.message);
        console.error('Full error:', error);
      }
    }
  }

  const verifyTOTPSetupHandler = async () => {
    try {
      if (!verificationCode) {
      throw new Error("TOTP code is required.");
    }
    // console.log('userCode in verify is : '+userCode);
      await verifyTOTPSetup({ code: verificationCode }); // Assuming newUser is available here
      // console.log('TOTP setup successful!');
      await updateMFAPreference({ totp: 'ENABLED', totp: 'PREFERRED' });
      await updateUserAttribute({ 
        userAttribute: {
          attributeKey: 'custom:mfa_setup_completed',
          value: 'true' 
      }
    });
      // console.log('MFA setup marked as completed in user attributes.');
      setIsAuthenticated(true);
      // setTimeout(() => {
      //   navigate('/');
      // }, 2000);
      navigate('/');
    } catch (error) {
      console.error('Error verifying TOTP setup:', error);
      setError('Error verifying TOTP setup')
    }
  };

  async function handleRegularLogin() {
    try {
      // const user = await signIn(username, password);
      // console.log('User signed in:', user);
  
      // Check for MFA challenge
       // Check for MFA challenge
        //const mfaCode = prompt('Enter the MFA code from your authenticator app:');
        if (!verificationCode) {
          console.error('Verification code is required.');
          return;
        }

        // console.log('MFA required. Confirming with TOTP code...');
     
        const confirmsignRes = await confirmSignIn({
          challengeResponse: verificationCode
        });
        // console.log('MFA confirmed, login successful! : '+JSON.stringify(confirmsignRes));
        if (confirmsignRes['isSignedIn']) {
          // logEvent({ action: 'SignInConfirmationRequested', message: 'User signed in' });
          // Mark MFA setup as completed
          await updateUserAttribute({ 
            userAttribute: {
              attributeKey: 'custom:mfa_setup_completed',
              value: 'true' 
            }
          });
          await updateLastLogin();
          setIsAuthenticated(true);
          navigate('/');
        }
    } catch (error) {
      console.error('Error during regular login with MFA:', error);
      setError(`Error during regular login with MFA: ${error.message}`);
    }
  }

  const sendSMSVerification = async () => {
    try {
      // Validate phone number format (you might want to add more robust validation)
      if (!validatePhoneNumber(phoneNumber)) {
        // console.log('Invalid phone number format');
        throw new Error('Invalid phone number format');
      }

          // Ensure the phone number is in E.164 format
      const formattedPhoneNumber = phoneNumber.startsWith('+') 
      ? phoneNumber 
      : `+1${phoneNumber.replace(/\D/g, '')}`;

      // Update user phone number attribute 
    const updateResult = await updateUserAttribute({
      userAttribute: {
        attributeKey: 'phone_number',
        value: formattedPhoneNumber,
      }
    });
    //console.log('user attribute updates: '+JSON.stringify(updateResult));

    // Optional: Add a small delay to ensure attribute is processed
    await new Promise(resolve => setTimeout(resolve, 500));
    const userAttributes = await fetchUserAttributes();
          //console.log('this is userAttributes in verification: '+JSON.stringify(userAttributes));

      //Send verification code to the phone number
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number'
      });
      //console.log('sms sent');
      //await sendUserAttributeVerificationCode('phone_number');
      setSmsSent(true);
      setError('');
    } catch (error) {
      console.error('Error sending SMS verification:', error);
      setError(`Error sending SMS verification: ${error.message}`);
    }
  };

  const verifySMSSetupHandler = async () => {
    try {
      if (!verificationCode) {
        throw new Error("Verification code is required.");
      }

      //console.log('verfiy sms setup '+verificationCode );

      // Confirm the phone number with the verification code
      // await confirmUserAttribute({
      //   attributeKey: 'phone_number',
      //   confirmationCode: verificationCode
      // });
      const userAttributeKey = 'phone_number';
      try {
        await confirmUserAttribute({ userAttributeKey, confirmationCode: verificationCode });
      } catch (error) {
        // console.log(error);
        setError('Invalid verification code');
        return;
      }

      // Update MFA preference to use SMS
      await updateMFAPreference({ sms: 'PREFERRED' });

      // Mark MFA setup as completed
      await updateUserAttribute({ 
        userAttribute: {
          attributeKey: 'custom:mfa_setup_completed',
          value: 'true' 
        }
      });

      if (dontAskAgain) {
        await rememberDevice(); // Mark the device as trusted
        // console.log('Device remembered');
      }

      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      console.error('Error verifying SMS setup:', error);
      setError('Error verifying SMS setup:', error)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isMfaActivated) {
      if (mfaType === 'mfaApp') {
        await verifyTOTPSetupHandler();
      } else if (mfaType === 'sms') {
        if (!smsSent) {
          await sendSMSVerification();
        } else {
          await verifySMSSetupHandler();
        }
      }
    } else {
      await handleRegularLogin();
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // console.log('usercode is: '+userCode);
  //   if(!isMfaActivated) {
  //     if(mfaType === 'mfaApp') {
  //       verifyTOTPSetupHandler();
  //     } else {

  //     }  
  //   } else {
  //     handleRegularLogin();
  //   }
    
  // };

  return (
    <div className="flex flex-col md:flex-row w-full">

    <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
      <div className="flex w-[22.75rem] flex-col items-center gap-[3.75rem] flex-shrink-0">
        {/* Main Container */}
        <div className="flex flex-col items-start gap-[0.625rem] self-stretch">
          <p className="text-black font-inter text-[3.8125rem] font-black leading-[120%]">
            Multi-factor Authentication
          </p>
          {/* <div className="flex w-[22.75rem] h-[3rem] justify-center items-center">
            <p className="text-black font-inter text-xl font-normal leading-[1.5rem] w-[22.75rem] flex-shrink-0">
              Scan the QR code below to using an Authenticator App
            </p>
          </div> */}
        </div>

        {/* SELECT TYPE OF MFA for first time */}
        {/* {!isMfaActivated && 
          <div className="flex flex-col items-center gap-[1.25rem] self-stretch">
            <div className="flex flex-col gap-[1.25rem] w-full">
              <p className="text-black font-inter text-xl font-normal leading-[1.5rem] w-[22.75rem] flex-shrink-0 text-center">
                Select MFA Method
              </p>
              <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid`}>
                <Smartphone size={24} />
                <select
                  className="w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none appearance-none bg-transparent"
                  style={{
                    fontFamily: 'Inter, sans-serif',
                  }}
                  onChange={(e) => setMfaType(e.target.value)}
                  value={mfaType}
                >
                  <option value="mfaApp">MFA App</option>
                  <option value="sms">SMS</option>
                </select>
                <ChevronDown size={24} />
              </div>
            </div>
          </div>
        } */}

        {/* {mfaType === 'mfaApp' && 
          <div className="flex flex-col items-center gap-[1.25rem] self-stretch">
            <form onSubmit={handleSubmit} className="flex flex-col items-center gap-[3.75rem] w-[22.75rem] flex-shrink-0">
              <div className="flex flex-col gap-[1.25rem] w-full">
                {error && <Alert type="error">{error}</Alert>}
                {!isMfaActivated && 
                  <div className="w-[22.75rem] flex-shrink-0 flex flex-col items-center">
                    <QRCodeSVG value={qrCode} size={200} />
                  </div>
                } 
                <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid ${isVerificationCodeFocused ? 'border-black' : 'border-transparent'}`}> 
                  <input
                    type="text" //{showConfirmPassword ? "password" : "text"}
                    placeholder="Verification Code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    onFocus={() => setIsVerificationCodeFocused(true)}
                    onBlur={() => setIsVerificationCodeFocused(false)}
                    required
                    className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isVerificationCodeFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: error ? '#EF6950' : 'black',
                    }}
                  />
                </div>

              </div>
              <button
                type="submit"
                className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Verify
              </button>
            </form>
          </div>
        } */}

        {mfaType === 'sms' && 
          <div className="flex flex-col items-center gap-[1.25rem] self-stretch">
            <form onSubmit={handleSubmit} className="flex flex-col items-center gap-[3.75rem] w-[22.75rem] flex-shrink-0">
              <div className="flex flex-col gap-[1.25rem] w-full">
                {error && error.length > 0 && <Alert severity="error">{error}</Alert>}

                {!isMfaActivated && !smsSent ? (
                  <>
                  
                  <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid ${isPhoneNumberFocused ? 'border-black' : 'border-transparent'}`}> { /*${error ? 'border-2 border-[#EF6950]' : isConfirmPasswordFocused ? 'border-black' : 'border-transparent'}`}> */}
                    <Smartphone size={24} />
                    <input
                      type="tel"
                      placeholder="Phone Number (e.g. +1234567890)"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onFocus={() => setIsPhoneNumberFocused(true)}
                      onBlur={() => setIsPhoneNumberFocused(false)}
                      required
                      className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isPhoneNumberFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        color: error ? '#EF6950' : 'black',
                      }}
                    />
                  </div>
                  <div className="flex items-center justify-start gap-2.5">
                    <div className="relative">
                      <input
                        type="checkbox"
                        id="dontAskAgain"
                        checked={dontAskAgain}
                        onChange={handleDontAskAgainChange}
                        className="w-[19px] h-[19px] flex-shrink-0 appearance-none bg-gray-300 border-2 border-gray-400 rounded-sm checked:bg-gray-300 checked:border-gray-400 focus:outline-none transition duration-200"
                      />
                      <svg
                        className={`absolute left-0 top-0 w-[19px] h-[19px] pointer-events-none ${dontAskAgain ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}  
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                    <label htmlFor="dontAskAgain" className="text-[16px] font-inter font-normal leading-[120%] text-black" style={{ fontFamily: 'Inter, sans-serif' }}>
                      Don't ask again on this device
                    </label>
                  </div>
                  </>
                  
                ) : (
                  <div className={`flex p-3.5 items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white border border-solid`}> { /*${error ? 'border-2 border-[#EF6950]' : isConfirmPasswordFocused ? 'border-black' : 'border-transparent'}`}> */}
                    <Lock size={24} />
                    <input
                      type="text" //{showConfirmPassword ? "password" : "text"}
                      placeholder="Verification Code"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      onFocus={() => setIsVerificationCodeFocused(true)}
                      onBlur={() => setIsVerificationCodeFocused(false)}
                      required
                      className={`w-full text-[16px] font-inter font-normal leading-[120%] focus:outline-none ${isVerificationCodeFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'} ${error ? 'text-[#EF6950]' : ''}`}
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        color: error ? '#EF6950' : 'black',
                      }}
                    />
                </div>
              )}
              </div>

              <button
                type="submit"
                className="flex w-full py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                {!isMfaActivated && !smsSent ? 'Send Verification Code' : 'Verify'}
              </button>
            </form>
          </div>
        }
      </div>
    </div>
    
    <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative overflow-hidden">
      {/* Logo container */}
      <div className="flex w-[23rem] flex-col items-start gap-5 absolute left-[6.5rem] top-[5rem]">
        <img
          src="Frame8.png"
          alt="FINDRAISER Logo"
          className="w-full h-auto object-contain"
        />
      </div>

      {/* Branding image container */}
      <div className="absolute bottom-0 right-0 w-[250px] h-[250px]">
        <img
          src="Branding.png"
          alt="FINDRAISER Branding"
          className="w-full h-full object-contain md:scale-90 sm:scale-75 lg:scale-100"
          style={{
            maxWidth: '100%',
            maxHeight: '100%'
          }}
        />
      </div>
    </div>
  </div>
  );
};

export default MFASetupFlow;