import React, { useContext, useState, useEffect, useRef  } from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { conversationState, pastConversationsState, activeItemState } from "../../store/atoms";
import ThemeToggleButton from './ThemeToggleButton';
import { MyContext } from '../../Context/MyContext';
import { logEvent } from '../../cloudwatchLogger';
import { signOut } from 'aws-amplify/auth';
import useLambda from '../hooks/useLambda';
import PinnedPrompts from '../Home/PinnedPrompts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faFileDownload, faDashboard, faUsers, faComments, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import AWS from 'aws-sdk';
import { fetchAuthSession, getCurrentUser, updateUserAttribute } from 'aws-amplify/auth';
import * as XLSX from 'xlsx';
import { ClipLoader } from 'react-spinners';
import { Search, Users, FileQuestion, Target, Heart, Upload, Bell, Sun, HelpCircle, Menu, 
  Settings, LogOut, ChevronLeft, ChevronRight, Cpu, LayoutDashboard,
  Download} from 'lucide-react';
import MySavedQueries from '../Home/MySavedQueries';
import UserManagement from '../Home/UserManagement';
import QueryManagement from '../Home/QueryManagement';
import Notifications from '../Home/Notifications';
//import { faChartLine, faRobot } from '@fortawesome/free-solid-svg-icons';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Modal, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Setting from '../Home/Setting';
import { useTheme } from '@emotion/react';
import { X, Trash2, ChevronDown } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import {
  isSuperAdminState,
  isTenantAdminRoleState
} from '../../store/atoms';

import { useMediaQuery } from '@mui/material';

const LogoutPopUp = () => {
  return (
  <div className="inline-flex flex-col items-start p-5 gap-5 rounded-[0.875rem] border border-[#F2F2F2] bg-white shadow-[4px_4px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="flex flex-col items-start w-[29.6875rem] pb-5 gap-5 border-b border-[#D9D9D9]">
            <div className="flex items-center gap-2.5 self-stretch">
              <div className="flex items-center gap-2.5 flex-[1_0_0]">
                <div className="flex justify-center items-center w-6 h-6">
                  <span style={{ color: 'red' }}>
                    {/* Power icon or SVG */}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="power">
                        <path id="Vector" d="M18.3601 6.64062C19.6185 7.89941 20.4754 9.50307 20.8224 11.2488C21.1694 12.9946 20.991 14.804 20.3098 16.4484C19.6285 18.0927 18.4749 19.4982 16.9949 20.487C15.515 21.4758 13.775 22.0035 11.9951 22.0035C10.2152 22.0035 8.47527 21.4758 6.99529 20.487C5.51532 19.4982 4.36176 18.0927 3.68049 16.4484C2.99921 14.804 2.82081 12.9946 3.16784 11.2488C3.51487 9.50307 4.37174 7.89941 5.63012 6.64062" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Vector_2" d="M12 2V12" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                  </span>
                </div>
                <p className="text-[#EF6950] text-[1.25rem] font-bold leading-[120%]">Sign Out</p>
              </div>
              <X className="w-6 h-6" />
            </div>
          </div>
          <div className="flex flex-col items-start gap-2.5 self-stretch"></div>
          <div className="flex flex-col items-end gap-2.5 self-stretch pt-5 border-t border-[#D9D9D9]"></div>
        </div>
  )};

  const SignOutModal = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    title = "Sign Out", 
    message = "Are you sure you want to log out?" 
  }) => {
    const isMobile = useMediaQuery('(max-width:768px)');

    if (!isOpen) return null;
    
  
    return (
      
      <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${isMobile ? 'p-4':''}`}>
        {/* Overlay */}
        <div 
          className="fixed inset-0 bg-black opacity-50" 
          onClick={onClose}
        ></div>
        
        {/* Modal Container */}
        <div className="relative w-full max-w-md mx-auto my-6">
          {/* <div className="relative flex flex-col w-full bg-white rounded-xl shadow-lg">
            
            <div className="flex items-center justify-between p-5 border-b border-gray-200">
              <h3 className="text-xl font-semibold flex items-center gap-2">
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path 
                    d="M18.3601 6.64062C19.6185 7.89941 20.4754 9.50307 20.8224 11.2488C21.1694 12.9946 20.991 14.804 20.3098 16.4484C19.6285 18.0927 18.4749 19.4982 16.9949 20.487C15.515 21.4758 13.775 22.0035 11.9951 22.0035C10.2152 22.0035 8.47527 21.4758 6.99529 20.487C5.51532 19.4982 4.36176 18.0927 3.68049 16.4484C2.99921 14.804 2.82081 12.9946 3.16784 11.2488C3.51487 9.50307 4.37174 7.89941 5.63012 6.64062" 
                    stroke="#EF6950" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                  <path 
                    d="M12 2V12" 
                    stroke="#EF6950" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                </svg>
                {title}
              </h3>
              <button 
                onClick={onClose} 
                className="text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            </div>
  
            
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-gray-600 text-lg leading-relaxed">
                {message}
              </p>
            </div>
  
            
            <div className="flex items-center justify-end p-6 border-t border-gray-200">
              <button 
                onClick={onClose}
                className="mr-4 px-6 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={onConfirm}
                className="px-6 py-2 text-sm font-medium text-white bg-yellow-500 rounded-md hover:bg-yellow-600 transition-colors"
              >
                Sign Out
              </button>
            </div>
          </div> */}
          <div className={`inline-flex flex-col items-start p-5 gap-5 rounded-[0.875rem] border border-[#F2F2F2] bg-white shadow-[4px_4px_10px_0px_rgba(0,0,0,0.10)] ${isMobile ? 'w-full max-w-[29.6875rem]':''}`}>
          <div className={`flex flex-col items-start ${isMobile ? 'w-full' : 'w-[29.6875rem]'} pb-5 gap-5 border-b border-[#D9D9D9]`}>
            <div className="flex items-center gap-2.5 self-stretch">
              <div className="flex items-center gap-2.5 flex-[1_0_0]">
                <div className="flex justify-center items-center w-6 h-6">
                  <span style={{ color: 'red' }}>
                    {/* Power icon or SVG */}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="power">
                        <path id="Vector" d="M18.3601 6.64062C19.6185 7.89941 20.4754 9.50307 20.8224 11.2488C21.1694 12.9946 20.991 14.804 20.3098 16.4484C19.6285 18.0927 18.4749 19.4982 16.9949 20.487C15.515 21.4758 13.775 22.0035 11.9951 22.0035C10.2152 22.0035 8.47527 21.4758 6.99529 20.487C5.51532 19.4982 4.36176 18.0927 3.68049 16.4484C2.99921 14.804 2.82081 12.9946 3.16784 11.2488C3.51487 9.50307 4.37174 7.89941 5.63012 6.64062" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Vector_2" d="M12 2V12" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                  </span>
                </div>
                <p className="text-[#EF6950] text-[1.25rem] font-bold leading-[120%]">{title}</p>
              </div>
              <X className="w-6 h-6 cursor-pointer" onClick={onClose}/>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2.5 self-stretch">
            <p className="self-stretch text-black font-inter text-base font-bold leading-tight">
              {message}
            </p>
              <p className="self-stretch text-[#8E8E8E] font-inter text-base font-normal leading-tight">
                Logging out will end your current session and sign you out from all devices. You may need to re-enter your credentials to log back in and continue your work.
              </p>
          </div>
          <div className="flex flex-col items-end gap-2.5 self-stretch pt-5 border-t border-[#D9D9D9]">
            <div className="flex items-center gap-[0.625rem]">
              <button 
                onClick={onClose}
                className="flex items-start 
                  px-3.5 py-2.5 
                  gap-1 
                  rounded-[0.4375rem] 
                  bg-gray-300 
                  hover:bg-gray-400 
                  transition-colors 
                  cursor-pointer
                  font-bold"
              >
                Cancel
              </button>
              <button 
                onClick={onConfirm}
                className="flex items-start 
                  px-3.5 py-2.5 
                  gap-1 
                  rounded-[0.4375rem] 
                  bg-[#EBAE12] 
                  hover:bg-[#D99E10] 
                  transition-colors 
                  cursor-pointer 
                  text-white 
                  font-bold"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  };
  

const Sidebar_v2 = ({isCollapsed, setIsCollapsed}) => {
  const { isDarkTheme } = useContext(MyContext);
  const themeStyles = { backgroundColor: '#333' };

  const isSuperAdmin = useRecoilValue(isSuperAdminState);
  const isTenantAdminRole = useRecoilValue(isTenantAdminRoleState);
  const { configureLambda, decodeToken, getJWT } = useLambda();

  const [isOpen, setIsOpen] = useState(true);
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const [pastConversations, setPastConversations] = useRecoilState(pastConversationsState);
  const [conversation, setConversation] = useRecoilState(conversationState);

  
  const navigate = useNavigate();
  const [showPinnedPrompts, setShowPinnedPrompts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeItem, setActiveItem] = useRecoilState(activeItemState);
  const location = useLocation();
  const [csvFile, setCsvFile] = useState(null);
  const [showCsvEditor, setShowCsvEditor] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const cancelImportRef = useRef(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showMySavedQueries, setShowMySavedQueries] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const [isControlCenterExpanded, setIsControlCenterExpanded] = useState(false);
  

  const isMobile = useMediaQuery('(max-width:768px)');


  // Define getFormattedDateTime function
  const getFormattedDateTimeUTC = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  
  const fileInputRef = useRef(null);

  const sanitizeFilename = (filename) => {
    return filename.replace(/\s+/g, '_').toLowerCase();
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {  // You can adjust this breakpoint as needed
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    // Set the initial state
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  //const isUserRole = roles.includes('arn:aws:iam::696416640413:role/userRole');

  const handleImport = () => {
    logEvent({ action: 'ImportClicked', message: 'User clicked Import Data' });
    navigate('/import');
  };

  const handleClearAll = () => {
    logEvent({ action: 'ClearAllButtonClicked', message: 'User clicked clear all' });
    setPastConversations([]);
    setConversation([]);
    navigate('/');
  };

  const handleConstruction = () => {
    navigate('/underConstruction');
  }

  const handleSaved = () => {
    logEvent({ action: 'SavedQueriesClicked', message: 'User accessed saved queries' });
    navigate('/mySavedQueries');
  }
  
  const handleNotifications = () => {
    logEvent({ action: 'NotificationsClicked', message: 'User accessed notifications' });
    navigate('/notifications');
  }

  const handleSearch = () => {
    logEvent({ action: 'TargetedSearchClicked', message: 'User initiated a targeted search' });
    navigate('/targetPage');
    setConversation([]);
  }

  const handleDashboard = () => {
    logEvent({ action: 'DashboardClicked', message: 'User accessed the dashboard' });
    navigate('/dashboard');
  }

  const handleHelpClick = () => {
    logEvent({ action: 'HelpClicked', message: 'User accessed the help section' });
    navigate('/help');
  };

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleSidebar = () => {
    logEvent({ action: 'SidebarToggled', message: `Sidebar ${isCollapsed ? 'expanded' : 'collapsed'}` });
    setIsCollapsed(!isCollapsed);
  };

  // const handleAddUser = () => {
  //   signOut()
  //     .then(() => console.log('User signed out'))
  //     .catch(error => console.error('Error signing out: ', error));
  // };

  async function  updateLastLogin() {
    try {
      const currentUser = await getCurrentUser();
      if (!currentUser) {
        console.log('No authenticated user found');
        return;
      }
  
      
      const loginTime = localStorage.getItem('loginTime');
      console.log('Setting login time to:', loginTime);
  
          // Try with explicit attributes object format
          const result = await updateUserAttribute({
            userAttribute: {
              attributeKey: 'custom:loginTime',
              value: loginTime
            }
          });
  
      console.log('Last login updated successfully', result);
    } catch (error) {
      console.error('Error updating last login:', error);
      if (error instanceof Error) {
        console.error('Error type:', error.constructor.name);
        console.error('Error message:', error.message);
        console.error('Full error:', error);
      }
    }
  }

  const handleSignUp = () => {
    logEvent({ action: 'SignUpClicked', message: 'User initiated sign up process' });
    navigate('/signup');
  };
  const handleSignOut = async () => {
    try {
      logEvent({ action: 'SignOutInitiated', message: 'User initiated sign out process' });
      await updateLastLogin();
      // await updateUserAttribute({ 
      //   userAttribute: {
      //     attributeKey: 'custom:mfa_setup_completed',
      //     value: 'false' 
      //   }
      // });
      await signOut();
      
      window.location.href = '/signin';
    } catch (error) {
      logEvent({ action: 'SignOutError', message: 'Error occurred during sign out', error: error.message });
      console.error('Error signing out:', error);
    }
  };

  const AdminDashboard = () => {
    logEvent({ action: 'AdminDashboardAccessed', message: 'Admin accessed the dashboard' });
    navigate('/admin');
  };
  
  const UserManagement = () => {
    logEvent({ action: 'UserManagementAccessed', message: 'Admin accessed user management' });
    navigate('/userManagement');
  };

  const handleQueryManagement = () => {
    logEvent({ action: 'QueryManagementAccessed', message: 'User accessed query management' });
    navigate('/queryManagement');
  }

  const handlePinnedPrompts = () => {
    logEvent({ action: 'PinnedPromptsToggled', message: `Pinned prompts ${showPinnedPrompts ? 'hidden' : 'shown'}` });
    setShowPinnedPrompts(!showPinnedPrompts);
  };

  const handleAddColumn = () => {
    setHeaders([...headers, '']); // Add an empty header
  };
  
  const handleDeleteColumn = (index) => {
    const updatedHeaders = headers.filter((_, i) => i !== index);
    const updatedData = data.map(row => row.filter((_, i) => i !== index));
    setHeaders(updatedHeaders);
    setData(updatedData);
  };

  const sidebarClass = `
    flex flex-col top-0 left-0 h-full transition-width duration-300 bg-white shadow-md
    ${isOpen ? 'w-64' : 'w-16'}
    ${isLoading ? 'opacity-70 pointer-events-none' : ''}
  `;
  // py-5 px-3
  const buttonClass = `
    flex items-center w-full p-[0.75rem] text-gray-700 transition-colors duration-200
    rounded-[14px] border 
    
    ${isCollapsed ? 'justify-center' : 'justify-start'}
  `;

  const activeButtonClass = `
    bg-[#FDF7E7] text-[#EBAE12] border-[#EBAE12]
  `;


  const renderIcon = (IconComponent) => {
    return (
      <div className="flex items-center justify-center" style={{ width: '24px', height: '24px' }}>
        <IconComponent className="w-6 h-6 transition-colors duration-200 group-hover:text-black" />
      </div>
    );
  };

  const handleItemClick = (label, onClick) => {
    if (label === 'Control Center') {
      setIsControlCenterExpanded(!isControlCenterExpanded);
    } else if (label === 'User Management' || label === 'Query Management') {
      setActiveItem(label);
      setIsControlCenterExpanded(true);
      if (onClick) {
        if(isMobile) {
          setIsCollapsed(true);
        }
        onClick();
      }
    } else {
      setActiveItem(label);
      setIsControlCenterExpanded(false);
      if (onClick) {
        if(isMobile) {
          setIsCollapsed(true);
        } 
        onClick();
      }
    }
  };
  const handleLogout = () => {
    logEvent({ action: 'LogoutConfirmationRequested', message: 'User requested logout confirmation' });
    setShowLogoutConfirmation(true);
  };

  const handleSettings = () => {
    navigate('/settings');
  }

  const confirmLogout = () => {
    logEvent({ action: 'LogoutConfirmed', message: 'User confirmed logout' });
    setShowLogoutConfirmation(false);
    handleSignOut();
  };

  const cancelLogout = () => {
    logEvent({ action: 'LogoutCancelled', message: 'User cancelled logout' });
    setShowLogoutConfirmation(false);
  };
  
  const iconStyle = {
    width: '24px',
    height: '24px',
    flexShrink: 0,
  };

  const navItems = [
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
          <circle cx="11" cy="11" r="8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21 21L16.65 16.65" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ), 
      label: 'Search', 
      onClick: handleClearAll
    },
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
        <path d="M10 3H3V10H10V3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 3H14V10H21V3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 14H14V21H21V14Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 14H3V21H10V14Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      ),
      label: 'Dashboard',
      onClick: handleDashboard 
    },
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
          <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M7 10L12 15L17 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 15V3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ), 
      label: 'Import Data',
      onClick: handleImport 
    },
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
          <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ), 
      label: 'Notifications',
      onClick: handleNotifications 
    },
    ...(isSuperAdmin || isTenantAdminRole ? [{
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
          <path d="M4 21V14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4 10V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 21V12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 8V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 21V16" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 12V3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 14H7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 16H23" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      ),
      label: <span style={{ fontSize: '16px' }}>Control Center</span>,
      subItems: [
        { 
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
              <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M23 20.9999V18.9999C22.9993 18.1136 22.7044 17.2527 22.1614 16.5522C21.6184 15.8517 20.8581 15.3515 20 15.1299" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            
          ), 
          label: 'User Management', 
          onClick: () => navigate('/userManagement') 
        },
        { 
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="transition-colors duration-200 group-hover:stroke-[#EBAE12]">
              <path d="M11.7891 21.8977C17.3119 21.8977 21.7891 17.4206 21.7891 11.8977C21.7891 6.37486 17.3119 1.89771 11.7891 1.89771C6.26621 1.89771 1.78906 6.37486 1.78906 11.8977C1.78906 17.4206 6.26621 21.8977 11.7891 21.8977Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.7891 15.8977C13.9982 15.8977 15.7891 14.1068 15.7891 11.8977C15.7891 9.68857 13.9982 7.89771 11.7891 7.89771C9.57992 7.89771 7.78906 9.68857 7.78906 11.8977C7.78906 14.1068 9.57992 15.8977 11.7891 15.8977Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.71924 4.82764L8.95924 9.06764" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.6191 14.7278L18.8591 18.9678" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.6191 9.0676L18.1491 5.5376" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.71924 18.9678L8.95924 14.7278" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          ), 
          label: 'Query Management',
          onClick: () => navigate('/queryManagement') 
        },
      ],
      onClick: () => setIsControlCenterExpanded(!isControlCenterExpanded),
    }] : []),
  ];

  const subItemButtonClass = `
    flex items-center w-full py-5 px-5 text-gray-700 transition-colors duration-200
    rounded-[14px] border 
    hover:bg-[#FDF7E7] hover:text-black hover:border-[#EBAE12]
    ${isCollapsed ? 'justify-center' : 'justify-start pl-8'}
  `;

  const renderNavItems = () => {
    return navItems.map((item, index) => {
      if (item.subItems) {
        // Control Center item
        const isControlCenterActive = isControlCenterExpanded ||
          activeItem === item.label ||
          item.subItems.some(subItem => activeItem === subItem.label);
  
        return (isSuperAdmin || isTenantAdminRole) && (
          <div key={index} className="w-full">
            <button
              className={`${buttonClass} group w-full transition-all duration-200  hover:bg-white hover:border hover:text-black hover:border-[#D9D9D9] border-transparent ${
                isControlCenterActive ? 'rounded-xl p-2' : 'p-2'
              }`}
              onClick={() => handleItemClick(item.label, item.onClick)}
            >
              {React.cloneElement(item.icon, {
                className: `transition-colors duration-200 group-hover:text-black`,
                fontSize: '16px',
                stroke: "currentColor",
                width: "24",
                height: "24"
              })}
              {!isCollapsed && <span className={`ml-3 group-hover:text-black sm:inline`}>{item.label}</span>}
              {!isCollapsed && (
                <ChevronDown
                  size={20}
                  className={`ml-auto transition-transform ${isControlCenterExpanded ? 'transform rotate-180 text-black' : ''} group-hover:text-black sm:inline`}
                />
              )}
            </button>
            {isControlCenterExpanded && !isCollapsed && (
              <div className="pl-4">
                {item.subItems.map((subItem, subIndex) => (
                  <button
                    key={`${index}-${subIndex}`}
                    className={`${buttonClass} ${activeItem === subItem.label ? activeButtonClass : 'hover:bg-white hover:border hover:text-black hover:border-[#D9D9D9] border-transparent'} group w-full transition-all duration-200 ${
                      activeItem === subItem.label ? 'border border-amber-300 rounded-xl p-2' : 'p-2'
                    }`}
                    onClick={() => handleItemClick(subItem.label, subItem.onClick)}
                  >
                    {React.cloneElement(subItem.icon, {
                      className: `transition-colors duration-200 ${activeItem === subItem.label ? 'text-[#EBAE12]' : 'group-hover:text-black'}`,
                      stroke: "currentColor",
                      fontSize: '16px',
                      width: "24",
                      height: "24"
                    })}
                    {!isCollapsed && <span className={`ml-3 ${activeItem === subItem.label ? 'text-[#EBAE12]' : 'group-hover:text-black'} sm:inline text-[16px]`}>{subItem.label}</span>}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      } else {
        // Regular nav item
        return (
          <button
            key={index}
            className={`${buttonClass} ${activeItem === item.label ? activeButtonClass : 'hover:bg-white hover:border hover:text-black hover:border-[#D9D9D9] border-transparent'} group w-full transition-all duration-200 ${
              activeItem === item.label ? 'border border-amber-300 rounded-xl p-2' : 'p-2'
            }`}
            onClick={() => handleItemClick(item.label, item.onClick)}
          >
            {React.cloneElement(item.icon, {
              className: `transition-colors duration-200 ${activeItem === item.label ? 'text-[#EBAE12]' : 'group-hover:text-black'}`,
              stroke: "currentColor",
              fontSize: '16px',
              width: "24",
              height: "24"
            })}
            {!isCollapsed && <span className={`ml-3 ${activeItem === item.label ? 'text-[#EBAE12]' : 'group-hover:text-black'} sm:inline font-sans text-[16px]`}>{item.label}</span>}
          </button>
        );
      }
    });
  };
  
  const bottomNavItems = [
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" className="group-hover:scale-105 transition-transform">
          <circle cx="24" cy="24" r="23.5" fill="white" stroke="#F9E6B6"/>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" x="12" y="12">
            <rect x="0.5" y="0.5" width="23" height="23" stroke="white"/>
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EBAE12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001" stroke="#EBAE12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 17H12.01" stroke="#EBAE12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {/* <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19.4215" cy="19.4215" r="19.4215" fill="#EBAE12"/>
          <circle cx="18.0252" cy="18.43" r="7.90999" stroke="white" stroke-width="1.61846"/>
          <path d="M23.9814 23.5475L28.7274 28.2935" stroke="white" stroke-width="1.61846" stroke-linecap="round"/>
          </svg> */}
        </svg>
      ),
      label: <span style={{ fontSize: '1rem' }}>Help & Support</span>,
      subLabel:<span style={{ fontSize: '0.8125rem' }}>Answers here</span>,
      onClick: handleHelpClick
    },
    { 
      icon: <Settings size={24} />,
      label: 'Settings',
      onClick: handleSettings
    },
    { 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18.3601 6.63989C19.6185 7.89868 20.4754 9.50233 20.8224 11.2481C21.1694 12.9938 20.991 14.8033 20.3098 16.4476C19.6285 18.092 18.4749 19.4974 16.9949 20.4862C15.515 21.475 13.775 22.0028 11.9951 22.0028C10.2152 22.0028 8.47527 21.475 6.99529 20.4862C5.51532 19.4974 4.36176 18.092 3.68049 16.4476C2.99921 14.8033 2.82081 12.9938 3.16784 11.2481C3.51487 9.50233 4.37174 7.89868 5.63012 6.63989" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 2V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ),
      label: 'Sign out',
      onClick: handleLogout
    }
  ];
  
  const renderBottomNavItems = () => {
    return (
      
      <div className="mt-auto  w-full">
        {bottomNavItems.map((item, index) => {
          if (index === 0) {
            // Special rendering for Help & support
            return ( 
              <div 
                key={index} 
                className={`p-4 border border-amber-300 rounded-xl ${
                  isCollapsed 
                    ? 'px-2' 
                    : 'bg-[#FCF3DB] hover:bg-[#f9e6b6] rounded-lg my-2'
                }`}
              >
                <button
                  onClick={() => handleItemClick(item.label, item.onClick)}
                  className={`flex items-center text-gray-600 hover:text-gray-800 ${
                    isCollapsed ? 'justify-center' : ''
                  } group w-full`}
                >
                  {isCollapsed ? (
                    item.icon
                  ) : (
                    <>
                      {item.icon}
                      <div className="ml-3 text-[#000000] text-left sm:block">
                        <div className="font-inter font-semibold text-[16px]">{item.label}</div>
                        <div className="font-inter text-sm">{item.subLabel}</div>
                      </div>
                    </>
                  )}
                </button>
              </div>
            );
          } else {
            // Rendering for Settings and Sign out
            return (
              <button
                key={index}
                onClick={() => handleItemClick(item.label, item.onClick)}
                className={`${buttonClass} ${activeItem === item.label ? activeButtonClass : 'hover:bg-white hover:border hover:text-black hover:border-[#D9D9D9] border-transparent'} ${isCollapsed ? 'justify-center' : ''} group w-full`}
              >
                {React.cloneElement(item.icon, { 
                  className: `transition-colors duration-200 ${activeItem === item.label ? 'text-[#EBAE12]' : 'group-hover:text-black'}`,
                  stroke: "currentColor",
                  fontSize: '16px' ,
                  width: "24",
                  height: "24"
                })}
                {!isCollapsed && <span className={`ml-3 ${activeItem === item.label ? 'text-[#EBAE12]' : 'group-hover:text-black'} sm:inline text-[16px]`}>{item.label}</span>}
              </button>
            );
          }
        })}
      </div>
    );
  };


  return (
    // <div className={`bg-white border-r border-solid border-[#D9D9D9] flex flex-col  h-screen ${isCollapsed ? 'w-20' : 'w-[312px]'} transition-all duration-300`}>
    <div className={`bg-white border-r border-solid border-[#D9D9D9] flex flex-col
      ${(isMobile && !isCollapsed) ? 'fixed inset-0 z-50 w-full h-full' : ''}
      ${isMobile && isCollapsed ? 'w-full': ''}
      ${isCollapsed && !isMobile ? 'w-20' : 'w-[312px]'}
      transition-all duration-300`}>
      <div className={`p-4 ${isMobile ? 'flex justify-between items-center' : 'flex flex-col items-start'}`}>
        <button
          onClick={toggleSidebar}
          className="text-gray-500 hover:text-gray-700"
          style={{ width: '30px', height: '30px', flexShrink: 0 }}
        >
          {isCollapsed ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M25 21.25L25 8.75C25 7.36929 23.8807 6.25 22.5 6.25L15 6.25L7.5 6.25C6.11929 6.25 5 7.36929 5 8.75L5 21.25C5 22.6307 6.11929 23.75 7.5 23.75L22.5 23.75C23.8807 23.75 25 22.6307 25 21.25Z" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17.5 6.25L17.5 23.75" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M5 8.75L5 21.25C5 22.6307 6.11929 23.75 7.5 23.75L15 23.75L22.5 23.75C23.8807 23.75 25 22.6307 25 21.25L25 8.75C25 7.36929 23.8807 6.25 22.5 6.25L7.5 6.25C6.11929 6.25 5 7.36929 5 8.75Z" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.5 23.75L12.5 6.25" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
        </button>
        {isMobile ? (
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12.138px',
            alignSelf: 'stretch',
            padding: '10px'
            }}>
              <img 
                src="Logovarient2.svg" 
                alt="Logo Varient" 
                style={{ maxWidth: '100%', height: 'auto' }} 
              />
            </div>  
          </Link>
          
        ) : (
          <div className={`flex items-center mt-4`}>
            {isCollapsed ? (
              <Link to="/" style={{ textDecoration: 'none' }}>
                <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '38.843px',
                height: '38.843px'
                }}>
                  <img 
                    src="Logoicon2.svg" 
                    alt="Logo Icon" 
                    style={{ 
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }} 
                  />
                </div>
              </Link>
              
            ) : (
              <Link to="/" style={{ textDecoration: 'none' }}>
                <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12.138px',
                alignSelf: 'stretch',
                padding: '10px'
                }}>
                  <img 
                    src="Logovarient2.svg" 
                    alt="Logo Varient" 
                    style={{ maxWidth: '100%', height: 'auto' }} 
                  />
                </div>
              </Link>
              
            )}
          </div>
        )}
      </div>
      {(!isMobile || !isCollapsed) && (
      <div className={`flex flex-col bg-white transition-all duration-300 
        ${isCollapsed ? 'px-2 border-r border-solid border-[#D9D9D9]' : 'px-5'}  
        ${isCollapsed ? 'w-20' : 'w-full'} 
        ${isMobile && !isCollapsed ? 'h-full' : 'h-screen'} 
        overflow-y-auto`}>
        <div className="flex-grow">
          {renderNavItems()}
        </div>
        {renderBottomNavItems()}
      </div>
    )}
      {/* <Dialog
  open={showLogoutConfirmation}
  onClose={cancelLogout}
  PaperProps={{
    style: {
      padding: '6px',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '12px', // Adjusted gap
      borderRadius: '14px',
      border: '1px solid #F2F2F2',
      backgroundColor: '#FFF',
      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.10)',
    },
  }}
>
  <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2px', marginTop: '2px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <span style={{ color: 'red' }}>
       
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="power">
            <path id="Vector" d="M18.3601 6.64062C19.6185 7.89941 20.4754 9.50307 20.8224 11.2488C21.1694 12.9946 20.991 14.804 20.3098 16.4484C19.6285 18.0927 18.4749 19.4982 16.9949 20.487C15.515 21.4758 13.775 22.0035 11.9951 22.0035C10.2152 22.0035 8.47527 21.4758 6.99529 20.487C5.51532 19.4982 4.36176 18.0927 3.68049 16.4484C2.99921 14.804 2.82081 12.9946 3.16784 11.2488C3.51487 9.50307 4.37174 7.89941 5.63012 6.64062" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2" d="M12 2V12" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>
      </span>
      Sign out
    </div>
  </DialogTitle>
  <div className="w-full" style={{ borderBottom: '1px solid #d3d3d3' }}></div>
  <DialogContent>
    <span style={{ fontWeight: 'bold', color: 'black' }}>
      Are you sure you want to log out?
    </span>
    <br />
    Logging out will end your current session and sign you out from all devices. You may need to re-enter your credentials to log back in and continue your work.
  </DialogContent>
  <div className="w-full" style={{ borderBottom: '1px solid #d3d3d3' }}></div>
  <DialogActions style={{ justifyContent: 'flex-end', padding: '10px', display: 'flex', width: '100%' }}>
    <Button
      onClick={cancelLogout}
      style={{
        backgroundColor: '#E0E0E0',
        color: '#000000',
        padding: '10px 20px',
        marginRight: '10px',
        borderRadius: '5px',
        textTransform: 'none',
      }}
    >
      Cancel
    </Button>
    <Button
      onClick={confirmLogout}
      style={{
        backgroundColor: '#FFD700',
        color: '#000000',
        padding: '10px 20px',
        borderRadius: '5px',
        textTransform: 'none',
      }}
    >
      Sign Out
    </Button>
  </DialogActions>
</Dialog> */}

      {showLogoutConfirmation && (
        <SignOutModal 
        isOpen={showLogoutConfirmation}
        onClose={cancelLogout}
        onConfirm={confirmLogout}
      /> 
      )}


    </div>
  );
};
export default Sidebar_v2;