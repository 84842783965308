import { atom } from "recoil";

export const conversationState = atom({
  key: "conversationState",
  default: [],
});

export const pastConversationsState = atom({
  key: "pastConversationsState",
  default: [],
});

export const filteredConversationsState = atom({
  key: "filteredConversationsState",
  default: [],
});

export const activeItemState = atom({
  key: 'activeItemState',
  default: 'Search', // default value
});

// export const logEventsState = atom({
//   key: 'logEventsState',
//   default: [],
// });

// Add this new atom for notifications enabled state
export const notificationsEnabledState = atom({
  key: 'notificationsEnabledState',
  default: true, // Default to enabled
});

export const pinnedQueriesState = atom({
  key: 'pinnedQueriesState',
  default: []
});

export const importNotificationsState = atom({
  key: 'importNotificationsState',
  default: []
});

export const jwtTokenState = atom({
  key:'jwtTokenState',
  default: ''
});

export const rolesState = atom({
  key:'rolesState',
  default: []
});

export const subState = atom({
  key:'subState',
  default: ''
});

export const tenantGroupState = atom({
  key:'tenantGroupState',
  default: []
});

export const userEmailState = atom({
  key:'userEmailState',
  default: ''
});

export const isSuperAdminState = atom({
  key: 'isSuperAdminState',
  default: ''
});

export const isTenantAdminRoleState = atom({
  key: 'isTenantAdminRoleState',
  default:''
});

export const expState = atom({
  key:'expState',
  default:''
});

export const firstNameState = atom({
  key:'firstNameState',
  default:''
});

export const displayRoleState = atom({
  key:'displayRoleState',
  default:''
});

export const conversationIdState = atom({
  key:'conversationIdState',
  default:''
});

export const responseErrorState = atom({
  key:'responseErrorState',
  default:''
});

export const mfaActiveState = atom({
  key: 'mfaActiveState',
  default: null,
});

export const tableLoadingState = atom({
  key:'tableLoadingState',
  default: false,
});