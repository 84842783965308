import React, { useState, useEffect } from 'react';
import { Target, Clock, Database, MessageSquare, MapPin, Star, TrendingUp } from 'lucide-react';
import { useRecoilState } from 'recoil';
import { firstNameState } from '../../store/atoms';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import useLambda from '../hooks/useLambda';
import { awsExports } from '../../aws-exports';
import WelcomeHeader from './WelcomeHeader';
import { Line, Pie, Bar } from 'react-chartjs-2'; // Ensure Bar is imported
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend, BarElement } from 'chart.js'; // Add BarElement here
import moment from 'moment';
import { getStateNameByStateCode } from 'us-state-codes';

// Register all necessary chart types
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend, BarElement); // Add BarElement to registration

const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

// DateCarousel.jsx

// const DateCarousel = ({fecngpDates}) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [dates, setDates] = useState([]);
//  // console.log('dates are: '+JSON.stringify(fecngpDates));

//   useEffect(() => {
//     if (fecngpDates) {
//       const fecDate = new Date(`${fecngpDates.fec}T00:00:00Z`); 
//       const ngpDate = new Date(`${fecngpDates.ngp}T00:00:00Z`);

//       setDates([
//         { date: ngpDate, title: 'NGP' },
//         { date: fecDate, title: 'FEC' }
//       ]);
//     }
//   }, [fecngpDates]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex < 1 ? prevIndex + 1 : 1));
//   };

//   const formatDate = (date) => {
//     if (!date) return { day: 'TBD', month: '', year: '' };
//     return {
//       day: date.getUTCDate(), 
//       month: date.toLocaleString('default', { month: 'long', timeZone: 'UTC' }), 
//       year: date.getUTCFullYear() 
//     };
//   };

//   return (
//     <div className="flex justify-between items-center">
//       <button 
//         className="text-blue-500 hover:text-blue-700" 
//         onClick={handlePrevious}
//         disabled={currentIndex === 0}
//       >
//         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
//         </svg>
//       </button>
//       <div className="flex space-x-4 ">
//         {dates.map((item, index) => {
//           const formattedDate = formatDate(item.date);
//           return (
//             <div 
//               key={index} 
//               className={`text-center transition-opacity duration-300 ${
//                 index === currentIndex ? 'opacity-100' : 'opacity-50'
//               }`}
//             >
//               <p className="text-sm font-bold mb-2">{item.title}</p>
//               <p className="text-1xl font-bold ">{formattedDate.day}</p>
//               <div className="flex items-center space-x-2">
//                 <p className="text-sm">{formattedDate.month.slice(0, 3)}</p>
//                 <p className="text-sm text-gray-500">{formattedDate.year}</p>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <button 
//         className="text-blue-500 hover:text-blue-700" 
//         onClick={handleNext}
//         disabled={currentIndex === 1}
//       >
//         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
//         </svg>
//       </button>
//     </div>
//   );
// };


const DateCarousel = ({fecngpDates}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (fecngpDates) {
      const fecDate = new Date(`${fecngpDates.fec}T00:00:00Z`); 
      const ngpDate = new Date(`${fecngpDates.ngp}T00:00:00Z`);

      setDates([
        { date: ngpDate, title: 'NGP' },
        { date: fecDate, title: 'FEC' }
      ]);
    }
  }, [fecngpDates]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const formatDate = (date) => {
    if (!date) return { day: 'TBD', month: '', year: '' };
    return {
      day: date.getUTCDate(), 
      month: date.toLocaleString('default', { month: 'long', timeZone: 'UTC' }), 
      year: date.getUTCFullYear() 
    };
  };

  return (
    <div className="flex flex-col items-center overflow-hidden">
      <div className="relative w-full overflow-hidden">
        <div 
          className="flex transition-transform duration-500 ease-in-out"
          style={{ 
            transform: `translateX(-${currentIndex * 100}%)`,
            width: `${dates.length * 50}%`
          }}
        >
          {dates.map((item, index) => {
            const formattedDate = formatDate(item.date);
            return (
              <div 
                key={index} 
                className="w-full flex-shrink-0 flex flex-col items-center"
              >
                <p className="text-sm font-bold mb-2">{item.title}</p>
                <p className="text-2xl font-bold">{formattedDate.day}</p>
                <div className="flex items-center space-x-2">
                  <p className="text-sm">{formattedDate.month.slice(0, 3)}</p>
                  <p className="text-sm text-gray-500">{formattedDate.year}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      <div className="flex space-x-2 mt-4">
        {dates.map((_, index) => (
          <button 
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-3 h-3 rounded-full transition-colors duration-300 ${
              index === currentIndex 
                ? 'bg-[#EBAE12]' 
                : 'bg-gray-300 hover:bg-amber-200'
            }`}
          />
        ))}
      </div>
    </div>
  );
};



const Dashboard = () => {
  const [selectedQuery, setSelectedQuery] = useState('');
  const [firstName, setFirstName] = useRecoilState(firstNameState);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [lastLogin, setLastLogin] = useState(null);
  const [timeSpent, setTimeSpent] = useState(0);
  const [currentLoginTime, setCurrentLoginTime] = useState(null); 

  const [tenantGroup, setTenantGroup] = useState([]);
  const [yearlyDonations, setYearlyDonations] = useState({});
  const [emailCount, setEmailCount] = useState({});
  const [lastQuery, setLastQuery] = useState({
      text: '',
      timestamp: null,
      queryCount: 0
  });
  const [topStates, setTopStates] = useState([]);
  const [topOccupations, setTopOccupations] = useState([]);
  const [fecngpDates, setFecNgpDates] = useState({});

  const { decodeToken, getJWT, configureLambda } = useLambda();
  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);

  const fetchRoles = async () => {
    try {
      const token = await getJWT();
      //setJwtToken(token);
      const decodedToken = decodeToken(token);
      await configureLambda(token);
      const tenantgroup = decodedToken['cognito:groups'];
      let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
      //console.log('filtered groups: ', filteredGroups);
      setTenantGroup(filteredGroups);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
      //logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  

  useEffect(() => {
    fetchUserActivity();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
        
          const attributes = await fetchUserAttributes();
          const lastLoginTime = attributes['custom:loginTime'];
          if (lastLoginTime) {
            setLastLogin(new Date(lastLoginTime));
          }

          
          //await updateLastLogin();

          
          const now = Date.now();
          setCurrentLoginTime(now);
          //localStorage.setItem('loginTime', now.toString());

          
          const interval = setInterval(() => {
            const loginTimeFromStorage = localStorage.getItem('loginTime');
            if (!loginTimeFromStorage) {
              console.error('No login time found in localStorage');
              return;
            }
          
            const loginTime = new Date(loginTimeFromStorage).getTime();
            const currentTime = Date.now();
            const spent = Math.floor((currentTime - loginTime) / 1000); // in seconds
            setTimeSpent(spent);
          }, 1000);

          return () => {
            clearInterval(interval);
            // Optionally save the total time spent when component unmounts
            const totalTimeSpent = Math.floor((Date.now() - now) / 1000);
            //console.log('Total time spent this session:', totalTimeSpent, 'seconds');
          };
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []); // Empty dependency array means this runs once on mount

  const fetchUserActivity = async () => {
    try {
        const attributes = await fetchUserAttributes();
        
        // Check if it's a new day and reset count if necessary
        const today = new Date().toISOString().split('T')[0];
        const lastQueryDate = attributes['custom:lastQueryDate'];
        const queryCount = lastQueryDate === today ? 
            parseInt(attributes['custom:dailyQueryCount'] || '0') : 0;
        //console.log('queryCount is: ' + queryCount);

        setLastQuery({
            text: attributes['custom:lastQuery'] || 'No recent queries',
            timestamp: attributes['custom:lastQueryTime'] ? 
                parseInt(attributes['custom:lastQueryTime']) : null,
            queryCount: queryCount
        });
    } catch (error) {
        console.error('Error fetching user attributes:', error);
    }
};

  const formatLastLogin = (date) => {
    if (!date) return 'N/A';
    return date.toLocaleString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const formatTimeSpent = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };
  

  useEffect(() => {
    const timer = setInterval(() => setCurrentDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleQueryClick = (query) => {
    setSelectedQuery(query);
  };

  const getYearlyDonations = async (tenantGroup) => {
    if (!tenantGroup) return;
    //console.log('tenantGroup is: '+tenantGroup);
    try {
      const lambda = new AWS.Lambda();
      let payload = { 
        schema: tenantGroup[0].toLowerCase(),
      };
      
      const yearlyDonations = await invokeLambda('getYearlyDonations', payload, lambda, lambdaFunctionName);
      //console.log('yearlyDonations is: '+JSON.stringify(yearlyDonations));
      
      // Assuming you want to set the result to state and handle loading
      setYearlyDonations(yearlyDonations);
      //setLoading(false);
      
      return yearlyDonations;
    } catch (error) {
      console.error('Error fetching yearly donations:', error);
      //setLoading(false);
      throw error;
    }
  };

  const getEmailCounts = async (tenantGroup) => {
    if (!tenantGroup) return;
    //console.log('tenantGroup is: ' + tenantGroup);
    
    try {
        const lambda = new AWS.Lambda();
        let payload = {
            schema: tenantGroup[0].toLowerCase(),
        };
        
        const emailCounts = await invokeLambda('getEmailCount', payload, lambda, lambdaFunctionName);
        //console.log('emailCounts is: ' + JSON.stringify(emailCounts));
        
        // Assuming you want to set the result to state
        setEmailCount(emailCounts);
        //setLoading(false);
        
        return emailCounts;
    } catch (error) {
        console.error('Error fetching email counts:', error);
        //setLoading(false);
        throw error;
    }
};

const getTopStates = async (tenantGroup) => {
  if (!tenantGroup) return;
  
  try {
      const lambda = new AWS.Lambda();
      let payload = {
          schema: tenantGroup[0].toLowerCase(),
      };
      
      const topStatesResult = await invokeLambda('getTopStates', payload, lambda, lambdaFunctionName);
      //console.log('Top states result:', JSON.stringify(topStatesResult));
      
      if (Array.isArray(topStatesResult)) {
        const fullStateNames = topStatesResult.map(stateObj => {
          const [[stateCode, count]] = Object.entries(stateObj);
          const fullStateName = getStateNameByStateCode(stateCode) || stateCode;
          return { [fullStateName]: count };
        });
        setTopStates(fullStateNames);
      }
      //return topStatesResult;
  } catch (error) {
      console.error('Error fetching top states:', error);
      setTopStates([]);
      throw error;
  }
};

const getTopOccupations = async (tenantGroup) => {
  if (!tenantGroup) return;
  
  try {
    const lambda = new AWS.Lambda();
    let payload = {
      schema: tenantGroup[0].toLowerCase(),
    };
    
    const topOccupationsResult = await invokeLambda('getTopOccupations', payload, lambda, lambdaFunctionName);
    //console.log('Top occupations result:', JSON.stringify(topOccupationsResult));
    
    if (Array.isArray(topOccupationsResult)) {
      // Assuming the result is already an array of occupation names
      setTopOccupations(topOccupationsResult);
    } else {
      console.error('Unexpected format for top occupations result');
      setTopOccupations([]);
    }
  } catch (error) {
    console.error('Error fetching top occupations:', error);
    setTopOccupations([]);
    throw error;
  }
};

const getFecNgpDates = async (tenantGroup) => {
  if (!tenantGroup) return;

  try {
    const lambda = new AWS.Lambda();
    let payload = {
      schema: tenantGroup[0].toLowerCase(),
    };

    const fecNgpResult = await invokeLambda('getFecNgpDates', payload, lambda, lambdaFunctionName);
    //console.log('FEC and NGP dates result:', JSON.stringify(fecNgpResult));

    if (fecNgpResult && fecNgpResult.fec && fecNgpResult.ngp) {
      // Assuming the result is in the expected format
      setFecNgpDates(fecNgpResult);
    } else {
      console.error('Unexpected format for FEC and NGP dates result');
      setFecNgpDates({});
    }
  } catch (error) {
    console.error('Error fetching FEC and NGP dates:', error);
    setFecNgpDates({});
    throw error;
  }
};

  const formatContributionData = (contributionObject) => {
    const years = Object.keys(contributionObject).sort();
    const contributions = years.map(year => contributionObject[year]);
  
    return {
      labels: years,
      datasets: [
        {
          label: 'Total Contributions',
          data: contributions,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }
      ]
    };
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const donations = await getYearlyDonations(tenantGroup);
        setYearlyDonations(donations);
        await getEmailCounts(tenantGroup);
        await getTopStates(tenantGroup);
        await getTopOccupations(tenantGroup);
        await getFecNgpDates(tenantGroup);
        
      } catch (error) {
        console.error('Failed to fetch yearly donations:', error); 
      }
    };

    if (tenantGroup && tenantGroup.length > 0) {
      fetchDashboardData();
    }
  }, [tenantGroup]);

  const formatEmailData = (emailData) => {
    if (!emailData) return null;
    
    const labels = Object.keys(emailData).sort();
    const values = labels.map(count => emailData[count]);
    
    return {
        labels: ['First time Donors', 'Total Donors'],
        datasets: [
            {
                data: values,
                backgroundColor: ['#EBAE12', '#40C5AF'],  // Updated colors
                borderColor: ['#EBAE12', '#40C5AF'],      // Updated colors
                borderWidth: 1,
            },
        ],
    };
};

const getTimeAgo = (timestamp) => {
  if (!timestamp) return '';
  return moment(parseInt(timestamp)).fromNow();
};

  const contributionData = formatContributionData(yearlyDonations);
  const emailData = formatEmailData(emailCount);
  // const topOccupations = ['Software Dev','Doctor','Student','Engineer','Plumber']
  const formatDonationAmount = (amount) => {
    if (amount >= 1e9) {
      return '$'+(amount / 1e9).toFixed(2) + 'B'; // Billions
    } else if (amount >= 1e6) {
      return '$'+(amount / 1e6).toFixed(2) + 'M'; // Millions
    } else if (amount >= 1e3) {
      return '$'+(amount / 1e3).toFixed(2) + 'K'; // Thousands
    } else {
      return amount.toString(); // Less than 1000
    }
  };

  const EmailLink = ({ email, children }) => {
    return (
      <a href={`mailto:${email}`} className="text-[0.8125rem] font-inter">
        {children || email}
      </a>
    );
  };

  return (
    <div style={{ backgroundColor: '#FFF', color: '#000', minHeight: '100vh', display: "flex", width: "100%" }}>
      <div className='max-w-full w-full' style={{margin: "0 auto"}}>
        <div className="flex h-screen bg-white">
          <div className="flex-1 overflow-hidden">
            <WelcomeHeader />
            <main className="flex flex-col items-start items-center gap-[2.5rem] flex-1 p-10 overflow-y-auto h-[calc(100vh-64px)] self-stretch">

              <div className="flex justify-between items-center self-stretch">
                <div className="flex flex-col items-start gap-[0.375rem] self-stretch">
                  <p className="text-black font-inter text-[1.5625rem] font-bold leading-[120%] self-stretch">Dashboard </p>
                  <p className="text-black font-inter text-base font-normal leading-[120%] w-[37.5rem]">An overview of the system and users will be found here.</p>  
                </div>
              </div>

              <div className="flex flex-col items-start gap-5 pt-10 flex-[1_0_0] border-t border-t-black/10 w-full">

              <div className="flex flex-wrap gap-5 w-full">
                {/* Line Chart container remains the same */}
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '345px',
                    width: '514px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 
                    className="text-[1rem] font-semibold mb-1" 
                    style={{
                      color: 'var(--Foundation-Primary-black-Normal, #000)',
                      fontFamily: 'Inter, sans-serif',
                      
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '120%'
                    }}
                  >
                    Total Donations Over Years
                  </h2>
                  <div className="flex-grow">
                  <Line 
                      data={{
                        ...contributionData,
                        datasets: [{
                          ...contributionData.datasets[0],
                          borderColor: '#40C5AF',
                          backgroundColor: '#40C5AF',
                          pointBackgroundColor: '#40C5AF',
                          pointBorderColor: '#40C5AF',
                          pointHoverBackgroundColor: '#40C5AF',
                          pointHoverBorderColor: '#40C5AF',
                          borderWidth: 2,
                          tension: 0.4,
                          fill: false,
                          pointRadius: 4,
                          pointHoverRadius: 6,
                        }]
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            backgroundColor: '#000',
                            padding: 10,
                            titleColor: '#fff',
                            bodyColor: '#fff',
                            displayColors: false,
                            callbacks: {
                              label: function(context) {
                                return `$${context.parsed.y.toLocaleString()}`;
                              }
                            }
                          }
                        },
                        scales: {
                          y: {
                            beginAtZero: true,
                            grid: {
                              display: true,
                              drawBorder: false,
                            },
                            title: {
                              display: true,
                              text: 'Total Donations ($)',
                              font: {
                                size: 12,
                                weight: 'normal'
                              },
                              padding: {
                                bottom: 10
                              }
                            },
                            ticks: {
                              callback: function(value) {
                                return value.toLocaleString();  // This will show full numbers with commas
                              }
                            }
                          },
                          x: {
                            grid: {
                              display: false,
                            },
                            title: {
                              display: true,
                              text: 'Years',
                              font: {
                                size: 12,
                                weight: 'normal'
                              },
                              padding: {
                                top: 10
                              }
                            }
                          }
                        },
                        interaction: {
                          intersect: false,
                          mode: 'index',
                        }
                      }} 
                    />
                  </div>
                </div>

                {/* Pie Chart */}
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '345px',
                    width: '514px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 
                    className="text-[1rem] font-semibold mb-1" 
                    style={{
                      color: 'var(--Foundation-Primary-black-Normal, #000)',
                      fontFamily: 'Inter, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '120%'
                    }}
                  >
                    Unique Donor Distribution
                  </h2>

                  <p className="text-sm text-gray-600 mb-4">First time donors among total donors</p>
                  <div className="flex justify-center items-center flex-grow">
                    <div 
                      style={{
                        width: '185.999px',
                        height: '185.999px',
                        flexShrink: 0
                      }}
                    >
                      <Pie 
                        data={emailData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              display: false  // Hide the legend since we now show it above
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                {/* Add this new flex container for counts */}
                <div className="flex justify-center items-center h-16 px-4 mb-4">
                  <div className="flex items-center space-x-16">
                    <div className="flex items-center">
                      <div className="w-3 h-3 rounded-full bg-[#EBAE12] mr-2"></div>
                        <div>
                          <p className="text-sm text-gray-600">First time Donors</p>
                        </div>
                      </div>
                        <div className="flex items-center">
                          <div className="w-3 h-3 rounded-full bg-[#40C5AF] mr-2"></div>
                            <div>
                              <p className="text-sm text-gray-600">Total Donors</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                
              {/* <div className="flex flex-wrap gap-[20px] w-full ">
                
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '200px',
                    width: '336px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
                  <div className="flex items-start mb-4">
                    
                    <div className="w-[290px] h-[99px] flex-shrink-0 rounded-2xl border border-[#D9D9D9] bg-[#F2F2F2] p-4">
                      <p className="font-semibold text-gray-500">Last Query</p>
                      <p className="text-black text-sm mt-1">"{lastQuery.text}"</p>
                      <div className="flex items-center mt-2">
                        <div className="flex items-center"> 
                          <span className="text-xs text-black">{lastQuery.queryCount} queries today</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '200px',
                    width: '336px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 className="text-xl font-semibold mb-4">Data Update Overview</h2>
                  
                  <div className="w-[249px] h-[99px] flex-shrink-0">
                    <div className="w-[289px] h-[119px] flex-shrink-0 rounded-2xl border border-[#D9D9D9] bg-[#F2F2F2]">
                      <div className="mt-4">
                        <p className="text-sm text-gray-600 ml-2">{currentDate.toLocaleTimeString()} CST</p>
                      </div>
                      <DateCarousel fecngpDates={fecngpDates}/>
                    </div>
                  </div>
                </div>
  
               
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '200px',
                    width: '336px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 className="text-xl font-semibold mb-4">User Activity</h2>
                  <div className="flex items-center mb-4">
                  <div
                    style={{
                      width: '290px',
                      height: '99px',
                      flexShrink: 0,
                      borderRadius: '16px',
                      border: '1px solid var(--foundation-primary-black-light-hover, #D9D9D9)',
                      background: 'var(--Foundation-Primary-black-Light, #F2F2F2)',
                    }}
                  >
                    <div className="w-[290px] h-[99px] flex-shrink-0 rounded-2xl border border-[#D9D9D9] bg-[#F2F2F2] p-4">
                      <p className="font-semibold text-gray-500">Last Login</p>
                      <p className="text-black text-sm mt-2">{formatLastLogin(lastLogin)}</p>
                      <p className="text-black text-sm mt-2">{formatTimeSpent(timeSpent)} time spent</p>
                    </div>
                  </div>
                  </div>
                </div>                
              </div> */}


               {/* Need to update with New graphs below  */}
              {/* Number of queries and Token consumed per tenant */}


             {/* <div className="flex flex-wrap gap-5 mb-6 w-full" style={{ gap: '20px' }}>
                
                <div 
                 className="bg-white flex flex-col p-4 overflow-hidden"
                 style={{
                   borderRadius: '20px',
                   border: '1px solid #D9D9D9',
                   background: '#FFF',
                   height: '345px',
                   padding: '20px',
                   flexDirection: 'column',
                   alignItems: 'center', // Center align items
                   gap: '20px',
                   width: '400px', // Adjusted width
                   flex: '1 0 0'
                 }}
               >
                 <h2 
                   className="text-lg font-semibold mb-1" 
                   style={{
                     alignSelf: 'stretch',
                     color: 'var(--Foundation-Primary-black-Normal, #000)',
                     fontFamily: 'Inter',
                     fontSize: '20px',
                     fontStyle: 'normal',
                     fontWeight: 700,
                     lineHeight: '120%'
                   }}
                 >
                   Number of queries
                 </h2>
                 
                 <div 
                   className="flex-grow" 
                   style={{
                     width: '100%', // Use full width of the parent
                     flex: '1 0 0'
                   }}
                 >
                   <div 
                     style={{
                       display: 'flex',
                       width: '100%', // Use full width of the parent
                       height: '250px', // Increased height of the bar graph
                       paddingBottom: '8px',
                       justifyContent: 'space-between',
                       alignItems: 'center'
                     }}
                   >
                     <div 
                       style={{
                         display: 'flex',
                         width: '100%', // Use full width for the bars
                         flexDirection: 'column',
                         justifyContent: 'space-between',
                         alignItems: 'flex-end',
                         flexShrink: 0,
                         alignSelf: 'stretch'
                       }}
                     > */}
                       {/* Graph bars layout */}
                       {/* <Bar 
                         data={{
                           labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                           datasets: [
                             {
                               label: 'User 1',
                               data: [25000, 30000, 35000, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                               backgroundColor: '#40C5AF', // User 1 color
                               borderColor: '#40C5AF',
                             },
                             {
                               label: 'User 2',
                               data: [20000, 22000, 28000, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                               backgroundColor: '#EBAE12', // User 2 color
                               borderColor: '#EBAE12',
                             }
                           ]
                         }}
                         options={{
                           responsive: true,
                           maintainAspectRatio: false,
                           plugins: {
                             legend: {
                               display: false,
                             },
                             tooltip: {
                               backgroundColor: '#000',
                               padding: 10,
                               titleColor: '#fff',
                               bodyColor: '#fff',
                               displayColors: false,
                               callbacks: {
                                 label: function(context) {
                                   return `${context.dataset.label}: ${context.parsed.y.toLocaleString()}`;
                                 }
                               }
                             }
                           },
                           scales: {
                             y: {
                               beginAtZero: true,
                               min: 0, // Set minimum value
                               max: 40000, // Set maximum value
                               ticks: {
                                 stepSize: 10000, // Set step size to 10k
                                 callback: function(value) {
                                   return `${value / 1000}k`; // Show in 'k'
                                 }
                               },
                               grid: {
                                 display: true,
                                 drawBorder: false,
                               },
                               title: {
                                 display: true,
                                 font: {
                                   size: 12,
                                   weight: 'normal'
                                 },
                                 padding: {
                                   bottom: 10
                                 }
                               },
                             },
                             x: {
                               grid: {
                                 display: false,
                               },
                               title: {
                                 display: true,
                                 font: {
                                   size: 12,
                                   weight: 'normal'
                                 },
                                 padding: {
                                   top: 10
                                 }
                               },
                               labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] // X-axis labels
                             }
                           },
                           interaction: {
                             intersect: false,
                             mode: 'index',
                           }
                         }} 
                       />
                     </div>
                   </div>
                   
                   <div 
                     style={{
                       display: 'flex',
                       alignItems: 'center',
                       gap: '40px',
                       justifyContent: 'flex-start', // Left align user labels
                       marginBottom: '2px' // Further reduced bottom margin
                     }}
                   >
                     <div 
                       style={{
                         display: 'flex',
                         alignItems: 'center',
                         gap: '12px'
                       }}
                     >
                       <div className="w-3 h-3 rounded-full bg-[#40C5AF] mr-2"></div>
                       <span 
                         style={{
                           color: 'var(--Foundation-Primary-black-Normal, #000)',
                           fontFamily: 'Inter',
                           fontSize: '13px',
                           fontStyle: 'normal',
                           fontWeight: 400,
                           lineHeight: '120%'
                         }}
                       >
                         User 1
                       </span>
                     </div>
                     <div 
                       style={{
                         display: 'flex',
                         alignItems: 'center',
                         gap: '12px'
                       }}
                     >
                       <div className="w-3 h-3 rounded-full bg-[#EBAE12] mr-2"></div>
                       <span 
                         style={{
                           color: 'var(--Foundation-Primary-black-Normal, #000)',
                           fontFamily: 'Inter',
                           fontSize: '13px',
                           fontStyle: 'normal',
                           fontWeight: 400,
                           lineHeight: '120%'
                         }}
                       >
                         User 2
                       </span>
                     </div>
                   </div>
                 </div>
               </div> */}
   
                  {/* Tokens Consumed per tenant */}
   
                 {/* <div 
                   className="bg-white flex flex-col p-4 overflow-hidden"
                   style={{
                     borderRadius: '20px',
                     border: '1px solid #D9D9D9',
                     background: '#FFF',
                     height: '345px',
                     padding: '20px',
                     flexDirection: 'column',
                     alignItems: 'center', 
                     gap: '20px',
                     width: '400px', 
                     flex: '1 0 0'
                   }}
                 >
                   <h2 
                     className="text-lg font-semibold mb-1" 
                     style={{
                       alignSelf: 'stretch',
                       color: 'var(--Foundation-Primary-black-Normal, #000)',
                       fontFamily: 'Inter',
                       fontSize: '20px',
                       fontStyle: 'normal',
                       fontWeight: 700,
                       lineHeight: '120%'
                     }}
                   >
                     Tokens consumed per tenant
                   </h2>
                   
                   <div 
                     className="flex-grow" 
                     style={{
                       width: '100%', 
                       flex: '1 0 0'
                     }}
                   >
                     <div 
                       style={{
                         display: 'flex',
                         width: '100%', 
                         height: '250px', 
                         paddingBottom: '8px',
                         justifyContent: 'space-between',
                         alignItems: 'center'
                       }}
                     >
                       <div 
                         style={{
                           display: 'flex',
                           width: '100%', 
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           alignItems: 'flex-end',
                           flexShrink: 0,
                           alignSelf: 'stretch'
                         }}
                       >
                         {(() => {
                           const contributionData = {
                             datasets: [
                               {
                                 label: 'User 1',
                                 data: [10000, 15000, 12000, 18000, 20000, 22000],
                                 backgroundColor: '#40C5AF',
                                 borderColor: '#40C5AF',
                                 stack: 'combined'
                               },
                               {
                                 label: 'User 2',
                                 data: [8000, 12000, 10000, 15000, 16000, 18000],
                                 backgroundColor: '#EBAE12',
                                 borderColor: '#EBAE12',
                                 stack: 'combined'
                               }
                             ],
                             labels: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6']
                           };
   
                           return (
                             <Bar 
                               data={contributionData}
                               options={{
                                 responsive: true,
                                 maintainAspectRatio: false,
                                 plugins: {
                                   legend: {
                                     display: false,
                                   },
                                   tooltip: {
                                     backgroundColor: '#000',
                                     padding: 10,
                                     titleColor: '#fff',
                                     bodyColor: '#fff',
                                     displayColors: false,
                                     callbacks: {
                                       label: function(context) {
                                         return `${context.dataset.label}: ${context.parsed.y.toLocaleString()}`;
                                       }
                                     }
                                   }
                                 },
                                 scales: {
                                   y: {
                                     beginAtZero: true,
                                     min: 0,
                                     max: 40000,
                                     ticks: {
                                       stepSize: 10000,
                                       callback: function(value) {
                                         return `${value / 1000}k`;
                                       }
                                     },
                                     grid: {
                                       display: true,
                                       drawBorder: false,
                                     },
                                     title: {
                                       display: true,
                                       font: {
                                         size: 12,
                                         weight: 'normal'
                                       },
                                       padding: {
                                         bottom: 10
                                       }
                                     },
                                   },
                                   x: {
                                     stacked: true, 
                                     grid: {
                                       display: false,
                                     },
                                     title: {
                                       display: true,
                                       font: {
                                         size: 12,
                                         weight: 'normal'
                                       },
                                       padding: {
                                         top: 10
                                       }
                                     },
                                   }
                                 },
                                 interaction: {
                                   intersect: false,
                                   mode: 'index',
                                 }
                               }} 
                             />
                           );
                         })()}
                       </div>
                     </div>
                     
                     <div 
                       style={{
                         display: 'flex',
                         alignItems: 'center',
                         gap: '40px',
                         justifyContent: 'flex-start', 
                         marginBottom: '2px' 
                       }}
                     >
                       <div 
                         style={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: '12px'
                         }}
                       >
                         <div className="w-3 h-3 rounded-full bg-[#40C5AF] mr-2"></div>
                         <span 
                           style={{
                             color: 'var(--Foundation-Primary-black-Normal, #000)',
                             fontFamily: 'Inter',
                             fontSize: '13px',
                             fontStyle: 'normal',
                             fontWeight: 400,
                             lineHeight: '120%'
                           }}
                         >
                           User 1
                         </span>
                       </div>
                       <div 
                         style={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: '12px'
                         }}
                       >
                         <div className="w-3 h-3 rounded-full bg-[#EBAE12] mr-2"></div>
                         <span 
                           style={{
                             color: 'var(--Foundation-Primary-black-Normal, #000)',
                             fontFamily: 'Inter',
                             fontSize: '13px',
                             fontStyle: 'normal',
                             fontWeight: 400,
                             lineHeight: '120%'
                           }}
                         >
                           User 2
                         </span>
                       </div>
                     </div>
                   </div>
                 </div>
               </div> */}

              <div className="flex flex-wrap gap-[20px] w-full">
                {/* Last Query Tile */}
                <div 
                  className="bg-white flex flex-col p-4 rounded-2xl border border-gray-300"
                  style={{ height: '200px', width: '336px', flex: '1 0 0' }}
                >
                  <h2 className="text-[1rem] font-semibold mb-4">Recent Activity</h2>
                  <div className="w-full h-[99px] rounded-2xl border border-gray-300 bg-gray-100 p-2">
                    <p className="font-semibold text-gray-500">Last Query</p>
                    <p className="text-black text-sm mt-1">"{lastQuery.text}"</p>
                    <p className="text-xs text-black mt-2">{lastQuery.queryCount} queries today</p>
                  </div>
                </div>

                {/* Data Update Overview Tile */}
                <div 
                  className="bg-white flex flex-col p-4 rounded-2xl border border-gray-300"
                  style={{ height: '200px', width: '336px', flex: '1 0 0' }}
                >
                  <h2 className="text-[1rem] font-semibold mb-4">Data Update Overview</h2>
                  <div className="w-full h-[99px] rounded-2xl border border-gray-300 bg-gray-100 p-2">
                    <DateCarousel fecngpDates={fecngpDates} />
                  </div>
                </div>

                {/* User Activity Tile */}
                <div 
                  className="bg-white flex flex-col p-4 rounded-2xl border border-gray-300"
                  style={{ height: '200px', width: '336px', flex: '1 0 0' }}
                >
                  <h2 className="text-[1rem] font-semibold mb-4">User Activity</h2>
                  <div className="w-full h-[99px] rounded-2xl border border-gray-300 bg-gray-100 p-2">
                    <p className="font-semibold text-gray-500">Last Login</p>
                    <p className="text-black text-sm mt-2">{formatLastLogin(lastLogin)}</p>
                    <p className="text-black text-sm mt-2">{formatTimeSpent(timeSpent)} time spent</p>
                  </div>
                </div>
              </div>


              <div className="flex flex-wrap gap-5  w-full">
                {/* Top States Tile */}
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '345px',
                    width: '514px',
                    flex: '1 0 0'
                  }}
                > 
                  <h2 className="text-[1rem] font-semibold mb-4 flex items-center overflow-hidden">
                    {/* <MapPin className="mr-2 text-blue-500" /> */}
                    Top 5 Donating States
                  </h2>
                  <div className="space-y-4 max-h-[calc(100%-3rem)]">
                    {topStates.map((stateObj, index) => {
                      const [stateName, total_donation_amount] = Object.entries(stateObj)[0];
                      const maxAmount = Math.max(...topStates.map(obj => Object.values(obj)[0]));
                      const percentage = (total_donation_amount / maxAmount) * 100;
                      return (
                        <div key={index} className="flex items-center space-x-2">
                          <div className="w-1/4 text-left">
                            <span className="font-semibold text-sm">{stateName}</span>
                          </div>
                          <div className="flex-grow w-1/2 h-5 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-[#40C5AF]"
                              style={{ width: `${percentage}%` }}
                            ></div>
                          </div>
                          <div className="w-16 text-right text-sm text-gray-600">
                            {formatDonationAmount(total_donation_amount)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* Top Occupations Tile */}
                <div 
                  className="bg-white flex flex-col p-4"
                  style={{
                    borderRadius: '20px',
                    border: '1px solid #D9D9D9',
                    background: '#FFF',
                    height: '345px',
                    width: '514px',
                    flex: '1 0 0'
                  }}
                >
                  <h2 className="text-[1rem] font-semibold mb-4 flex items-center overflow-hidden">
                    {/* <Star className="mr-2 text-green-500" /> */}
                    Top Occupations with most Donations
                  </h2>
                  <div className="space-y-3 overflow-y-auto max-h-[calc(100%-3rem)]"> 
                    {topOccupations.slice(0, 5).map((occupation, index) => (
                      <div key={index} className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center mr-3"> 
                          <span className="text-sm font-semibold text-green-600">{index + 1}</span> 
                        </div>
                        <span className="text-base truncate">{occupation}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

            </div>
              
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;