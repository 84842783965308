import React, { useState, useEffect } from 'react';
import { X, Trash2, ChevronRight, ChevronDown, Search, Plus, AlertCircle, TestTube } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import useLambda from '../hooks/useLambda';
import { useNavigate, Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
//import { logEventsState } from '../../store/atoms';
import config from '../../config';
import { logEvent } from '../../cloudwatchLogger';
import { awsExports } from '../../aws-exports';
import AWS from 'aws-sdk';
import WelcomeHeader from './WelcomeHeader';
import { useMediaQuery } from '@mui/material';

// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  //console.log('action is : '+action);
  const params = {
    FunctionName: lambdaFunctionName,
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    //console.log('result from the lambda is: '+JSON.stringify(result));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

const QueryManagement = () => {
  const [isAvailableExpanded, setIsAvailableExpanded] = useState(true);
  const [isSavedExpanded, setIsSavedExpanded] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newQueryName, setNewQueryName] = useState('');
  const [queryToDelete, setQueryToDelete] = useState(null);
  const [selectedQueries, setSelectedQueries] = useState(new Set());
  const [jwtToken, setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [sub, setSub] = useState('');
  const [tenantGroup, setTenantGroup] = useState([]);
  const [availableQueries, setAvailableQueries] = useState([]);
  const [savedQueries, setSavedQueries] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [availableSearchTerm, setAvailableSearchTerm] = useState('');
  const [savedSearchTerm, setSavedSearchTerm] = useState('');
  const [deleteType, setDeleteType] = useState(''); 
  const [lambdaFunctionName] = useState(awsExports.Lambda.queryManagement);
  const [isSearchVisible, setIsSearchVisible] = useState(false);  // Add this new state
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isFocused, setIsFocused] = useState(false);
 

  const navigate = useNavigate();
  const { decodeToken, getJWT, configureLambda } = useLambda();
  const savedQueryNames = new Set(savedQueries.map(query => query.query_name));

  const fetchRoles = async () => {
    try {
      const token = await getJWT();
      setJwtToken(token);
      const decodedToken = decodeToken(token);
      await configureLambda(token);
      const cognitoRoles = decodedToken['cognito:roles'];
      const subAttribute = decodedToken['sub'];
      if (cognitoRoles) {
        setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
        setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
      }
      const tenantgroup = decodedToken['cognito:groups'];
      let filteredGroups = tenantgroup.includes("Tenants") ? tenantgroup.filter(group => group !== 'Tenants') : [...tenantgroup];
      setTenantGroup(filteredGroups);
      setSub(subAttribute);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
      //logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchQueries = async () => {
    if (!sub) return;
    try {
      const lambda = new AWS.Lambda();
      let payload = {
        isSuperAdmin,
        isTenantAdmin,
        created_by: sub,
      };
      const allAvailableData = await invokeLambda('getGlobalQueries', payload, lambda, lambdaFunctionName);
      const savedData = await invokeLambda('getSavedQueries', { 'created_by': sub }, lambda, lambdaFunctionName);

      // Filter out saved queries from available queries
      const availableOnly = allAvailableData.filter(availableQuery => 
        !savedData.some(savedQuery => savedQuery.query_name === availableQuery.query_name)
      );

      setAvailableQueries(availableOnly);
      setSavedQueries(savedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching queries:', error);
      setLoading(false);
    }
  };

  

  const fetchUserSavedQueries = async () => {
    if (!tenantGroup) return;
    try {
      const lambda = new AWS.Lambda();
      const data = await invokeLambda('getUserSavedQueries', { 'groupname':tenantGroup[0] }, lambda, lambdaFunctionName);
      setSavedQueries(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching saved queries:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    // const fetchQueries = async () => {
    //   if (!sub) return;
    //   try {
    //     const lambda = new AWS.Lambda();
    //     let payload = {
    //       isSuperAdmin,
    //       isTenantAdmin,
    //       created_by: sub,
    //     };
    //     //console.log('payload from fetchQueries: '+JSON.stringify(payload));
    //     const data = await invokeLambda('getGlobalQueries',payload, lambda, lambdaFunctionName);
    //     //console.log('data from fetchQueries: '+JSON.stringify(data));
    //     setAvailableQueries(data);
    //     setLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching queries:', error);
    //     setLoading(false);
    //   }
    // };

    // const fetchSavedQueries = async () => {
    //   if (!sub) return;
    //   try {
    //     const lambda = new AWS.Lambda();
    //     const data = await invokeLambda('getSavedQueries', { 'created_by':sub }, lambda, lambdaFunctionName);
    //     setSavedQueries(data);
    //     setLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching saved queries:', error);
    //     setLoading(false);
    //   }
    // };

    
    if (isSuperAdmin || isTenantAdmin) {
      fetchQueries();
      //fetchSavedQueries();
    } else if (tenantGroup.length > 0) {
      fetchUserSavedQueries();
    }
  }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);

  const handleDeleteQuery = async (queryId, deleteType) => {
    try {
      const lambda = new AWS.Lambda();
      let action, payload;
      
      if (deleteType === 'available') {
        action = 'deleteQuery';
        payload = { id: queryId };
      } else if (deleteType === 'saved') {
        action = 'deleteSavedQuery';
        payload = { id: queryId };
      } else {
        throw new Error('Invalid delete type');
      }
  
      const result = await invokeLambda(action, payload, lambda, lambdaFunctionName);
      
      if (!result) {
        throw new Error(result.error || `Failed to delete ${deleteType} query`);
      }
      
      await fetchQueries();
  
      // Show success message
      if(deleteType === 'available'){
        setIsDeleteModalOpen(false);
      }
      setSuccessMessage(`${deleteType === 'available' ? 'Available' : 'Managed'} query deleted successfully!`);
      setShowSuccessMessage(true);
      
      // Hide message after 6 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 6000);
    } catch (error) {
      // Show error message
      setSuccessMessage(`Error deleting query: ${error.message}`);
      setShowSuccessMessage(true);
      
      // Hide message after 6 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 6000);
    } 
  };

  const handleAddQuery = async () => {
    if (newQueryName.trim()) {
      try {        
        const lambda = new AWS.Lambda();
        let action = "addQuery";
        let payload = {
          query_name: newQueryName,
          created_by: sub,
          query_type: isSuperAdmin ? 'global' : 'tenant',
        };
        const newQuery = await invokeLambda(action, payload, lambda, lambdaFunctionName);
        setAvailableQueries([...availableQueries, { id: newQuery[0].id, query_name: newQuery[0].query_name, query_type: newQuery[0].query_type }]);
        setNewQueryName('');
        setIsAddModalOpen(false);
        
        // Show success message
        setSuccessMessage('Query added successfully!');
        setShowSuccessMessage(true);
        
        // Hide message after 6 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
          setSuccessMessage('');
        }, 6000);
      } catch (error) {
        // Show error message
        setSuccessMessage(`Error adding query: ${error.message}`);
        setShowSuccessMessage(true);
        
        // Hide error message after 6 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
          setSuccessMessage('');
        }, 6000);
      }
    }
  };

  const SuccessMessage = ({ message, onClose }) => {
    // Determine if this is a delete or add action based on the message content
    const isDelete = message.includes('deleted');
    const title = isDelete ? 'Query Deleted Successfully' : 'Query Added Successfully';
    
    return (
      <div
        style={{
          position: 'fixed',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 9999,
          display: 'flex',
          width: '600px',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          borderRadius: '13px',
          border: '2px solid var(--Foundation-Alert-Green-Normal, #40C5AF)',
          background: 'var(--Foundation-Alert-Green-Light, #ECF9F7)',
          boxShadow: '2px 2px 8px 0px rgba(64, 197, 175, 0.10)',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/importsuccess.png" alt="Success" style={{ width: '24px', height: '24px' }} />
            <span style={{ fontWeight: 'bold' }}>{title}</span>
          </div>
          <button 
            onClick={onClose} 
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <X size={16} />
          </button>
        </div>
        <div>{message}</div>
      </div>
    );
  };

  const handleSaveQueries = async (id) => {
    try {
      const lambda = new AWS.Lambda();
      let action = "saveQueries";
      let payload = {
        queries: id,
        created_by: sub,
        groupname: tenantGroup[0],
      };
      
      await invokeLambda(action, payload, lambda, lambdaFunctionName); 
      await fetchQueries();
  
      // Show success message using the same mechanism as delete
      setSuccessMessage('Query added to managed queries successfully!');
      setShowSuccessMessage(true);
      
      // Hide message after 6 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 6000);
      
    } catch (err) {
      console.error('Error saving queries:', err);
      // Show error message
      setSuccessMessage(`Error adding query: ${err.message}`);
      setShowSuccessMessage(true);
      
      // Hide error message after 6 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 6000);
    }
  };

  const toggleQuerySelection = (queryId) => {
    setSelectedQueries((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(queryId)) {
        newSelected.delete(queryId);
      } else {
        newSelected.add(queryId);
      }
      return newSelected;
    });
  };

  const handleQueryClick = (query) => {
    if (dontAskAgain) {
      localStorage.setItem('selectedQuery', query.query_name);
      navigate('/');
    } else {
      setCurrentQuery(query);
      setOpenDialog(true);
    }
  };

  const handleConfirm = (query) => {
    localStorage.setItem('selectedQuery', query.query_name);
    setOpenDialog(false);
    navigate('/');
  };

  useEffect(() => {
    const savedDontAskAgain = localStorage.getItem('dontAskAgain');
    if (savedDontAskAgain) {
      setDontAskAgain(JSON.parse(savedDontAskAgain));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('dontAskAgain', JSON.stringify(dontAskAgain));
  }, [dontAskAgain]);

  // Update the search visibility toggle to also clear search terms
  const toggleSearch = () => {
    if (isSearchVisible) {
      // If we're closing the search, clear the search terms
      setAvailableSearchTerm('');
      setSavedSearchTerm('');
    }
    setIsSearchVisible(!isSearchVisible);
  };

  // Modify the filteredAvailableQueries to show all queries when search is not visible
  const filteredAvailableQueries = availableQueries.length > 0 
    ? isSearchVisible
      ? availableQueries.filter(query => 
          query.query_name.toLowerCase().includes(availableSearchTerm.toLowerCase())
        )
      : availableQueries
    : [];

  //console.log('filtered available queries are: '+JSON.stringify(filteredAvailableQueries));
  // Modify the filteredSavedQueries similarly
  const filteredSavedQueries = savedQueries.length > 0 
    ? isSearchVisible
      ? savedQueries.filter(query => 
          query.query_name.toLowerCase().includes(savedSearchTerm.toLowerCase())
        )
      : savedQueries
    : [];

  //console.log('filtered saved queries are: '+JSON.stringify(filteredSavedQueries));

  const ConfirmDialog = ({ isOpen, onClose, onConfirm, query, dontAskAgain, setDontAskAgain }) => {
    if (!isOpen) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 w-96 rounded-lg">
          <h2 className="text-xl font-inter font-bold mb-4">Confirm Query Run</h2>
          <p>Do you want to run this query?</p>
          <p className="font-bold mt-2">{query.query_name}</p>
          <div className="mt-4 flex items-center">
            <input
              type="checkbox"
              id="dontAskAgain"
              checked={dontAskAgain}
              onChange={(e) => setDontAskAgain(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="dontAskAgain">Don't ask again</label>
          </div>
          <div className="flex justify-between mt-4">
            <button onClick={onClose} className="bg-gray-300 text-black font-inter px-4 py-2 rounded">
              Cancel
            </button>
            <button onClick={() => onConfirm(query)} className="bg-yellow-400 text-white font-inter px-4 py-2 rounded">
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Modify the handleSearchSubmit function
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // After search results are shown
    setAvailableSearchTerm('');
    setIsSearchVisible(false);
  };
  

  return (
    <div style={{ backgroundColor: '#FFF', color: '#000', minHeight: '100vh', display: "flex", width: "100%" }}>
      {/* <Sidebar_v2 /> */}
      <div className={`${isMobile ? 'w-full' : 'max-w-full w-full'}`} style={{ margin: "0 auto" }}>
        <div className="flex h-screen">
          <div className='flex-1 flex flex-col overflow-hidden h-screen md:max-w-none '>
            <WelcomeHeader />
            
            <main className={`${isMobile ? 'p-5': 'p-10'} flex flex-col items-start flex-1 overflow-y-auto h-full gap-[2.5rem]`}>
              
              {/* Header section */}
  
              {/* Query Management section */}
              <div className="flex justify-between items-center self-stretch">
                <div className="flex flex-col items-start gap-1.5 flex-1">
                  <h1 className="text-[1.5625rem] font-inter font-bold leading-[120%] text-black self-stretch">
                    Query Management
                  </h1>
                  <p className={`${isMobile ? 'w-full' : 'w-[37.5rem]'} text-base font-inter font-normal leading-[120%] text-black`}>
                    Add query or add to managed queries
                  </p>
                </div>
              </div>

              <div className={`flex flex-col items-start gap-10 w-full`}>
                {/* Available Queries Section */}
                {(isSuperAdmin || isTenantAdmin) && (
                  <div className={`${
                    isMobile 
                      ? "flex h-[24.8125rem] py-5 flex-col items-start gap-5 flex-shrink-0 self-stretch w-full"
                      : "flex w-[55rem] flex-col items-start gap-5"
                  }`}>
                    <div className={`${
                      isMobile 
                        ? "flex flex-col justify-center items-start gap-2.5 self-stretch w-full"
                        : "flex h-[18.875rem] py-5 flex-col items-start gap-5 self-stretch border-t border-[#D9D9D9]"
                    }`}>
                      <div className={`${
                        isMobile 
                          ? "flex flex-col justify-center items-start gap-2.5 self-stretch w-full"
                          : "flex items-center self-stretch"
                      }`}>
                        <div className={`${
                          isMobile 
                            ? "flex flex-col justify-center items-start gap-2.5 self-stretch"
                            : "flex flex-col justify-center items-start gap-2.5 flex-1"
                        }`}>
                          <h2 className="self-stretch text-black font-inter text-base font-bold leading-[120%]">Available Queries</h2>
                          <p className="self-stretch text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">Select from available query listed below or create new query if needed</p>
                        </div>
                        <div className="flex items-center gap-5">
                          <button 
                            onClick={toggleSearch}
                            className="hover:opacity-80 transition-opacity duration-200"
                          >
                            <Search size={20} className="text-black" />
                          </button>
                          <button
                            onClick={() => setIsAddModalOpen(true)}
                            className="flex items-center justify-center gap-1 px-3 py-1.5 border border-[#EBAE12] rounded-[0.4375rem] bg-white hover:bg-gray-50 transition duration-300"
                          >
                            <Plus size={16} className="text-[#EBAE12]" />
                            <span className="text-[#EBAE12] font-inter text-sm font-medium">Add New Query</span>
                          </button>
                        </div>
                      </div>

                      <div className="flex h-[13.5625rem] flex-col items-start flex-shrink-0 self-stretch w-full">
                        {isSearchVisible && (
                          <form onSubmit={handleSearchSubmit} className="w-full">
                            <div className="flex p-2.5 items-center gap-5 self-stretch rounded-[14px] bg-[#F2F2F2]">
                              <div className="flex px-3.5 py-2.5 items-center gap-3.5 flex-1 rounded-[14px] bg-white">
                                <Search size={20} className="text-black font-inter" />
                                <input
                                  type="text"
                                  placeholder="Search"
                                  value={availableSearchTerm}
                                  onChange={(e) => setAvailableSearchTerm(e.target.value)}
                                  className="w-full bg-transparent focus:outline-none font-inter text-sm"
                                />
                              </div>
                            </div>
                          </form>
                        )}
                        <div className={`flex ${isSearchVisible ? 'h-[9.5625rem]': 'h-full'} flex-col items-start flex-shrink-0 self-stretch overflow-y-auto w-full`}>
                          {loading ? (
                            <p>Loading queries...</p>
                          ) : filteredAvailableQueries.length > 0 ? (
                            filteredAvailableQueries.map((query) => (
                              <div 
                                key={query.id} 
                                className="flex p-1.5 justify-between items-center border-b border-gray-200 group w-full"
                              >
                                <div className="flex justify-between items-center flex-1 w-full p-2.5 rounded-[7px] transition-all duration-200 group-hover:bg-[#F2F2F2]">
                                  <span className="text-black text-base font-normal leading-[120%]">{query.query_name}</span>
                                  
                                  <div className={`flex items-center gap-10 ${isMobile ? 'opacity-100' : 'w-[116px] opacity-0 group-hover:opacity-100'} transition-opacity duration-200`}>
                                    {(isSuperAdmin || isTenantAdmin) && ( 
                                      <button
                                        onClick={() => handleSaveQueries(query.id)}
                                        className={`
                                          text-[#EBAE12] font-inter text-[13px] font-bold leading-[120%]
                                          ${savedQueryNames.has(query.query_name) 
                                            ? 'opacity-50 filter blur-[0.5px] cursor-not-allowed' 
                                            : 'hover:opacity-80 transition-opacity duration-200'}
                                        `}
                                        disabled={savedQueryNames.has(query.query_name)}
                                      >
                                        Add
                                      </button>
                                    )}
                                    {(isSuperAdmin || query.query_type === 'tenant') && (
                                      <button
                                        onClick={() => {
                                          setQueryToDelete(query.id);
                                          setDeleteType('available');
                                          setIsDeleteModalOpen(true);
                                        }}
                                        className="text-[#EF6950] font-inter text-[13px] font-bold leading-[120%]"
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No available queries.</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Managed Queries Section */}
                <div className={`${
                    isMobile 
                      ? "flex h-[24.8125rem] py-5 flex-col items-start gap-5 flex-shrink-0 self-stretch w-full"
                      : "flex w-[55rem] flex-col items-start gap-5"
                  }`}>
                  <div className="flex pt-5 justify-between items-center self-stretch border-t border-[#D9D9D9]">
                    <h2 className="text-black text-base font-bold leading-[120%]">Managed Queries</h2>
                  </div>
                  <div className="flex flex-col items-start h-[11rem] gap-5 self-stretch w-full">
                    <div className="flex flex-col items-start self-stretch overflow-y-auto w-full">
                    {loading ? (
                        <p>Loading queries...</p>
                      ) : filteredSavedQueries.length > 0 ? (
                        filteredSavedQueries.map((query) => (
                          <div 
                            key={query.id} 
                            className="flex p-1.5 justify-between items-center self-stretch border-b border-gray-200 group w-full"
                          >
                            <div className="flex p-2.5 justify-between items-center flex-1 rounded-[7px] transition-all duration-200 group-hover:bg-[#F2F2F2]">
                              <span className="text-black font-inter text-base font-normal leading-[120%]">{query.query_name}</span>
                              <div className={`flex items-center gap-10 ${isMobile ? 'opacity-100' : 'w-[116px] opacity-0 group-hover:opacity-100'} transition-opacity duration-200`}>
                                <span></span>
                                {(isSuperAdmin || isTenantAdmin) && (
                                  <button
                                    className="text-[#EF6950] font-inter text-[13px] font-bold leading-[120%]"
                                    onClick={() => handleDeleteQuery(query.id, 'saved')}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>
                          {isSuperAdmin || isTenantAdmin ? (
                            "No queries, add them from the Available Queries section."
                          ) : (
                            "No queries added. Contact your tenant admin."
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* Add Query Modal */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className={`inline-flex flex-col items-start p-5 gap-5 rounded-[0.875rem] border border-[#F2F2F2] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.10)] ${isMobile ? 'w-[90%]' : 'w-[475px]'}`}>
            {/* main container */}
            <div className="flex flex-col items-start pb-5 gap-5 border-b border-[#D9D9D9] self-stretch">
              {/* child container 1 */}
              <div className="flex items-center gap-2.5 self-stretch">
                <div className="flex items-center gap-2.5 flex-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p className="text-[#000] font-inter text-[1.25rem] font-bold leading-[120%]">Add New Query</p>
                </div>
                <button onClick={() => setIsAddModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className={`relative flex items-center gap-4 self-stretch px-4 rounded-[0.4375rem] border ${isFocused ? 'border-black' : 'border-[#D9D9D9]'} bg-white transition-colors duration-200`}>
                <div className="flex flex-col justify-center items-start gap-1 flex-1 self-stretch">
                  <input
                    type="text"
                    value={newQueryName}
                    onChange={(e) => setNewQueryName(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Enter query"
                    className={`w-full pt-6 pb-2 font-inter text-base font-normal leading-[120%] focus:outline-none bg-transparent
                      ${isFocused ? 'text-black placeholder-black' : 'text-[#8E8E8E] placeholder-[#8E8E8E]'}
                      transition-colors duration-200`}
                  />
                  <label 
                    className={`absolute font-inter text-[0.8125rem] font-normal leading-[120%] transition-all duration-200 left-4 top-2 text-[#8E8E8E]
                     
                      ${newQueryName ? 'transform-none' : 'cursor-text'}`}
                  >
                    Enter query name
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end pt-5 gap-2.5 self-stretch border-t border-[#D9D9D9]">
              <button
                onClick={handleAddQuery}
                className="flex flex-col items-start justify-center p-[0.625rem] px-[0.875rem] gap-1 rounded-[0.4375rem] bg-[#EBAE12] text-white font-inter font-bold leading-[120%] hover:bg-[#D49D10]"
              >
                <span>Add</span>
              </button>
            </div>
          </div>
          {/* <div className={`bg-white rounded-lg ${isMobile ? 'w-[90%]' : 'w-[475px]'}`}>
            <div className="flex justify-between items-center p-5 border-b border-[#D9D9D9] mx-4">
              <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2 className="text-xl font-inter font-bold">Add New Query</h2>
              </div>
              <button onClick={() => setIsAddModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            
            <div className="flex flex-col items-start gap-5 p-5">
              <div className="flex flex-col items-start gap-2 w-full">
                <input
                  type="text"
                  value={newQueryName}
                  onChange={(e) => setNewQueryName(e.target.value)}
                  className="border p-2 w-full rounded-md"
                  placeholder="Enter query"
                />
              </div>
            </div>
            
            <div className="flex justify-end p-5 border-t border-[#D9D9D9] mx-4">
              <button
                onClick={handleAddQuery}
                className="flex items-center justify-center px-3.5 py-2.5 rounded-[7px] bg-[#EBAE12] text-white font-inter font-medium"
              >
                Add
              </button>
            </div>
          </div> */}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg w-[475px]">
            <div className="flex justify-between items-center p-5 border-b border-[#D9D9D9]">
              <div className="flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8V12" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 16H12.01" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <h2 className="text-[20px] font-bold text-[#EF6950] font-inter leading-[120%]">Confirm Deletion</h2>
              </div>
              <button onClick={() => setIsDeleteModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            
            <div className="flex flex-col items-start gap-2.5 p-5">
              <p className="text-black font-inter text-base font-bold leading-[120%] w-full">
                Are you sure you want to delete this {deleteType} query?
              </p>
              <p className="text-[#8E8E8E] font-inter text-base font-normal leading-[120%] w-full">
                {deleteType === 'available'
                  ? "This will remove the query from the available queries list."
                  : "The query will be removed from the managed queries for all users, do you want to proceed?"}
              </p>
            </div>
            
            <div className="flex justify-end p-5 border-t border-[#D9D9D9]">
              <button
                onClick={() => handleDeleteQuery(queryToDelete, deleteType)}
                className="flex items-center justify-center px-3.5 py-2.5 rounded-[7px] bg-[#EBAE12] text-white font-inter font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )} 

      {/* Confirm Dialog */}
      <ConfirmDialog
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
        query={currentQuery}
        dontAskAgain={dontAskAgain}
        setDontAskAgain={setDontAskAgain}
      />
      {showSuccessMessage && (
        <SuccessMessage 
          message={successMessage} 
          onClose={() => {
            setShowSuccessMessage(false);
            setSuccessMessage('');
          }}
        />
      )}
    </div>
);
};

export default QueryManagement;




{/* Available Queries Section
            {(isSuperAdmin || isTenantAdmin) && (
              <div className="mb-8 w-[775px]">
                <div className="flex items-end justify-between mb-4">
                  <div className="flex-1">
                    <h2 className="text-lg font-inter font-semibold mb-2">Available Queries</h2>
                    <p className="text-sm font-inter text-gray-500">Select from available query listed below or create new query if needed</p>
                  </div>
                  
                  {(isSuperAdmin || isTenantAdmin) && (
                    <div className="flex items-center gap-4 ml-4">
                      <button 
                        onClick={toggleSearch}
                        className="hover:opacity-80 transition-opacity duration-200"
                      >
                        <Search size={20} className="text-black" />
                      </button>
                      <button
                        onClick={() => setIsAddModalOpen(true)}
                        className="flex items-center justify-center gap-1 px-3 py-1.5 border border-[#EBAE12] rounded-[7px] bg-white hover:bg-gray-50 transition duration-300"
                      >
                        <Plus size={16} className="text-[#EBAE12]" />
                        <span className="text-[#EBAE12] font-inter text-sm font-medium">Add New Query</span>
                      </button>
                    </div>
                  )}
                </div>
              
                <div>
                  {isSearchVisible && (
                    <form onSubmit={handleSearchSubmit} className="mb-4">
                      <div className="flex p-2.5 items-center gap-5 self-stretch rounded-[14px] bg-[#F2F2F2]">
                        <div className="flex px-3.5 py-2.5 items-center gap-3.5 flex-1 rounded-[14px] bg-white">
                          <Search size={20} className="text-black font-inter" />
                          <input
                            type="text"
                            placeholder="Search"
                            value={availableSearchTerm}
                            onChange={(e) => setAvailableSearchTerm(e.target.value)}
                            className="w-full bg-transparent focus:outline-none font-inter text-sm"
                          />
                        </div>
                      </div>
                    </form>
                  )}
                  <div className="space-y-1 max-h-64 mt-5 overflow-y-auto">
                    {loading ? (
                      <p>Loading queries...</p>
                    ) : filteredAvailableQueries.length > 0 ? (
                      filteredAvailableQueries.map((query) => (
                        <div 
                          key={query.id} 
                          className="flex px-1.5 py-1.5 justify-between items-center border-b border-gray-200 group"
                        >
                          <div className="flex justify-between items-center w-full p-2.5 rounded-[7px] transition-all duration-200 group-hover:bg-[#F2F2F2]">
                            <span>{query.query_name}</span>
                            
                            <div className="flex w-[116px] items-center gap-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                              {(isSuperAdmin || isTenantAdmin) && ( 
                                <button
                                  onClick={() => handleSaveQueries(query.id)}
                                  className={`
                                    text-[#EBAE12] font-inter text-[13px] font-bold leading-[120%]
                                    ${savedQueryNames.has(query.query_name) 
                                      ? 'opacity-50 filter blur-[0.5px] cursor-not-allowed' 
                                      : 'hover:opacity-80 transition-opacity duration-200'}
                                  `}
                                  disabled={savedQueryNames.has(query.query_name)}
                                >
                                  Add
                                </button>
                              )}
                              {(isSuperAdmin || query.query_type === 'tenant') && (
                                <button
                                  onClick={() => {
                                    setQueryToDelete(query.id);
                                    setDeleteType('available');
                                    setIsDeleteModalOpen(true);
                                  }}
                                  className="text-[#EF6950] font-inter text-[13px] font-bold leading-[120%]"
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No available queries.</p>
                    )}
                  </div>
                </div>
              </div>
            )} */}
            {/* Managed Queries Section */}
              {/* <div className="mb-8 w-[775px]">
                <h2 className="text-lg font-inter font-semibold mb-4">Managed Queries</h2>
                
                <div >
                
                  <div className="space-y-1 max-h-64 mt-5 overflow-y-auto">
                  {loading ? (
                        <p>Loading queries...</p>
                      ) : filteredSavedQueries.length > 0 ? (
                        filteredSavedQueries.map((query) => (
                          <div 
                            key={query.id} 
                            className="flex w-[777px] px-1.5 py-1.5 justify-between items-center border-b border-gray-200 group"
                          >
                            <div className="flex justify-between items-center w-full p-2.5 rounded-[7px] transition-all duration-200 group-hover:bg-[#F2F2F2]">
                              <span>{query.query_name}</span>
                              <div className="flex w-[116px] items-center gap-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                <span></span>
                                {(isSuperAdmin || isTenantAdmin) && (
                                  <button
                                    className="text-[#EF6950] font-inter text-[13px] font-bold leading-[120%]"
                                    onClick={() => handleDeleteQuery(query.id, 'saved')}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>
                          {isSuperAdmin || isTenantAdmin ? (
                            "No queries, add them from the Available Queries section."
                          ) : (
                            "No queries added. Contact your tenant admin."
                          )}
                        </p>
                      )}
                    </div>
                  </div>
              </div> */}




{/* Delete Confirmation Modal */}
      //  {isDeleteModalOpen && (
        
      //   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      //     <div className="bg-white rounded-lg w-[475px]">
      //       <div className="flex justify-between items-center p-5 border-b border-[#D9D9D9]">
      //         <div className="flex items-center gap-2">
      //           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      //             <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      //             <path d="M12 8V12" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      //             <path d="M12 16H12.01" stroke="#EF6950" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      //           </svg>
      //           <h2 className="text-[20px] font-bold text-[#EF6950] font-inter leading-[120%]">Confirm Deletion</h2>
      //         </div>
      //         <button onClick={() => setIsDeleteModalOpen(false)} className="text-gray-500 hover:text-gray-700">
      //           <X size={24} />
      //         </button>
      //       </div>
            
      //       <div className="flex flex-col items-start gap-2.5 p-5">
      //         <p className="text-black font-inter text-base font-bold leading-[120%] w-full">
      //           Are you sure you want to delete this {deleteType} query?
      //         </p>
      //         <p className="text-[#8E8E8E] font-inter text-base font-normal leading-[120%] w-full">
      //           {deleteType === 'available'
      //             ? "This will remove the query from the available queries list."
      //             : "The query will be removed from the managed queries for all users, do you want to proceed?"}
      //         </p>
      //       </div>
            
      //       <div className="flex justify-end p-5 border-t border-[#D9D9D9]">
      //         <button
      //           onClick={handleDeleteQuery}
      //           className="flex items-center justify-center px-3.5 py-2.5 rounded-[7px] bg-[#EBAE12] text-white font-inter font-medium"
      //         >
      //           Delete
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // )} 