import React, {useEffect, useState} from 'react';
import { FileUp, AlertCircle, FileText, FileSpreadsheet, File, Loader2 } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import { useRecoilState, useRecoilValue } from 'recoil';
import { importNotificationsState, notificationsEnabledState } from '../../store/atoms';
import WelcomeHeader from './WelcomeHeader';
import { read } from 'xlsx';

const Notifications = () => {
  
  const [importNotification,setImportNotifications] = useRecoilState(importNotificationsState);
  const [notificationsEnabled,setNotificationsEnabled] = useRecoilState(notificationsEnabledState);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
 
  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  const handleClearAll = () => {
    setImportNotifications([]);
    localStorage.removeItem('importNotifications');
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  useEffect(() => {
    // Simulating API call
    setTimeout(() => {
      setNotifications([
        { id: 1, title: 'Title of notification', description: 'Here it would be description of notification to explain details', read:false },
        { id: 2, title: 'Title of notification', description: 'Here it would be description of notification to explain details', read:false },
        { id: 3, title: 'Title of notification', description: 'Here it would be description of notification to explain details',read:false },
        { id: 4, title: 'Title of notification', description: 'Here it would be description of notification to explain details', read: true },
      ]);
      const storedNotifications = localStorage.getItem('importNotifications');
        if (storedNotifications) {
          setImportNotifications(JSON.parse(storedNotifications));
        }
      setLoading(false);
      const storedValue = localStorage.getItem('notificationsEnabled');
      if (storedValue !== null) {
        setNotificationsEnabled(JSON.parse(storedValue));
      }
    }, 1000);
  }, []);



  // if (notifications.length === 0) {
  //   return (
      // <div className="flex flex-col items-center justify-center h-screen">
      //   <img src="/api/placeholder/200/200" alt="No notifications" className="mb-4" />
      //   <h2 className="text-2xl font-bold mb-2">No notifications yet</h2>
      //   <p className="text-gray-600">We'll notify you when something comes up.</p>
      // </div>
  //   );
  // }

  const getIcon = (type) => {
    switch (type) {
      case 'csv':
        return <FileText className="text-green-500 mr-3 flex-shrink-0" />;
      case 'excel':
        return <FileSpreadsheet className="text-green-500 mr-3 flex-shrink-0" />;
      case 'pdf':
        return <File className="text-red-500 mr-3 flex-shrink-0" />;
      case 'word':
        return <File className="text-blue-500 mr-3 flex-shrink-0" />;
      case 'import':
      default:
        return <FileUp className="text-green-500 mr-3 flex-shrink-0" />;
    }
  };

  const getNotificationType = (type) => {
    return type ? type.charAt(0).toUpperCase() + type.slice(1) : 'File';
  };

  return (
    <div style={themeStyles}>
      {/* <Sidebar_v2 /> */}
      {loading ? (
        <div className="flex-1 flex items-center justify-start w-full h-screen p-8">
          <img src="/NotificationLoading.svg" alt="Loading..." className="h-full w-auto object-contain"/>
        </div>
      ) : (
        <div className='flex-1 flex flex-col overflow-hidden h-screen md:max-w-none max-w-[440px]'> {/* Added responsive max-width */}
          <WelcomeHeader />
          {notificationsEnabled ? (
            <main className="flex flex-col gap-10 flex-1 overflow-y-auto p-10">
              {/* <main className="flex flex-col h-screen items-start gap-10 md:p-5 p-0 overflow-y-auto"> */}
              <div className="md:h-auto h-[74px] flex flex-col md:gap-normal gap-[40px]"> {/* Added responsive height and gap */}
                <div className="flex md:px-0 px-5 justify-between items-center w-full">
                  <div className="flex flex-col md:gap-normal gap-[6px] flex-1">
                    <h1 className="text-black font-inter text-[1.5625rem] font-bold leading-[120%]">
                      Notifications
                    </h1>
                    <p className="text-black font-inter text-base font-normal leading-[120%]">
                      Various notifications listed here for up to date tracking
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-[55rem] w-full space-y-4"> {/* Added responsive width */}
            <div className="flex flex-col items-start gap-5">
              <div className="flex items-center justify-between self-stretch pt-5 border-t border-[#D9D9D9]">
                <p className="text-black font-inter text-base font-bold leading-[120%]">
                  Notifications
                </p>
                <div className="flex justify-center items-center gap-2.5 px-2 py-1 rounded-[0.4375rem]">
                  <button 
                    className="text-[#EBAE12] font-inter text-[0.8125rem] font-bold leading-[120%]"
                  onClick={handleClearAll}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
            {importNotification.length === 0 && (
              <div className="flex flex-col items-end justify-end p-5 gap-5 self-stretch rounded-[0.875rem] border border-[#D9D9D9] bg-[#F2F2F2]">
                <div className="flex items-start gap-[1.0625rem] self-stretch">
                  <div className="flex py-1 items-start gap-2.5 self-stretch">
                    <div className="w-2.5 h-2.5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none" className="stroke-[#D9D9D9] stroke-[1px]">
                        <circle cx="5" cy="5" r="4.5" />
                      </svg>
                    </div>
                  </div>
                  <p className="text-[#8E8E8E] font-inter text-[0.8125rem] font-normal leading-[120%]">
                    No new notifications
                  </p>
                </div>
              </div>
            )}
            {importNotification.map((notification) => (
              <div 
                key={notification.id} 
                className="flex flex-col justify-end items-end gap-5 p-5 self-stretch rounded-[0.875rem] border border-[#D9D9D9] bg-white"
              >
                <div className="flex flex-col items-start gap-[1.0625rem] self-stretch">
                  <div className="flex items-start gap-2.5 self-stretch py-1">
                  <div className="w-2.5 h-2.5 rounded-full bg-[#40C5AF] border border-[#F2F2F2] mt-1"></div>
                    <div className="flex flex-col items-start gap-2.5 flex-1">
                      <h3 className="self-stretch text-black font-inter text-base font-bold leading-[120%]">
                        {notification.title}
                      </h3>
                      <p className="self-stretch text-black font-inter text-[0.8125rem] font-normal leading-[120%]">
                        {notification.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
        ) : (
          <main className="flex flex-col gap-10 flex-1 overflow-y-auto p-8">
            <div className="flex justify-between items-center self-stretch">
              <div className="flex flex-col gap-2">
                <h1 className="self-stretch text-black font-inter text-[1.5625rem] font-bold leading-[120%]">
                  Notifications
                </h1>
                <p className="text-black font-inter text-base font-normal leading-[120%]">
                Notifications are disabled. Enable notifications in the Settings page.
                </p>
              </div>
            </div>
          </main>
        )}
      </div>
      )}
      
    </div>
  );

  {/* <h1 className="text-2xl font-semibold mb-6">File Notifications</h1>
              {notificationsEnabled ? (
                <>
                  {importNotifications.length > 0 ? (
                    <ul className="space-y-4">
                      {importNotifications.map((notification, index) => (
                        <li key={index} className="bg-white p-4 rounded-lg shadow flex items-start">
                          {notification.success ? (
                            getIcon(notification.type)
                          ) : (
                            <AlertCircle className="text-red-500 mr-3 flex-shrink-0" />
                          )}
                          <div>
                            <p className="font-medium">
                              {notification.success
                                ? `${getNotificationType(notification.type)} Managed Successful`
                                : `${getNotificationType(notification.type)} Managed Failed`}
                            </p>
                            <p className="text-sm text-gray-600">{notification.message}</p>
                            <p className="text-xs text-gray-400 mt-1">
                              {formatTime(notification.timestamp)}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-gray-600">No notifications yet.</p>
                  )}
                </>
              ) : (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 rounded">
                  <p className="text-yellow-700">
                    Notifications are disabled. Enable notifications in the Settings page to view your import history.
                  </p>
                </div>
              )} */}
 

  // return (
  //   <div className="p-6">
  //     <WelcomeHeader />
  //     <h1 className="text-2xl font-bold mb-4">Notifications</h1>
  //     <p className="text-gray-600 mb-6">Various notification listed here for up to date tracking</p>
  //     <div className="space-y-4">
  //       {notifications.map((notification) => (
  //         <div key={notification.id} className="bg-white p-4 rounded-lg shadow">
  //           <h3 className="text-lg font-semibold mb-2">{notification.title}</h3>
  //           <p className="text-gray-600 mb-4">{notification.description}</p>
  //           <div className="flex justify-end space-x-2">
  //             <button className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600">
  //               Approve
  //             </button>
  //             <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300">
  //               Cancel
  //             </button>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default Notifications;


