import React, { useState, useEffect } from 'react';
import { resetPassword, confirmResetPassword, AuthError, fetchAuthSession } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Lock, EyeOff, Eye } from 'lucide-react';

// Custom Alert component
const Alert = ({ children, type = 'success' }) => (
  <div className={`w-full p-4 rounded-[13px] ${type === 'success' ? 'bg-[#ECF9F7] border-[#34D399]' : 'bg-[#FEF2F2] border-[#F87171]'} border flex items-start space-x-2 mb-4`}>
    <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${type === 'success' ? 'text-[#34D399]' : 'text-[#F87171]'} flex-shrink-0`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
      {type === 'success' ? (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      ) : (
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      )}
    </svg>
    <div className={`${type === 'success' ? 'text-[#065F46]' : 'text-[#991B1B]'} text-sm`}>{children}</div>
  </div>
);

const ForgotPassword = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState('request');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [loginStage, setLoginStage] = useState('initial');
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isVerificationCodeFocused, setIsVerificationCodeFocused] = useState(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkAuthStatus() {
      try {
        const session = await fetchAuthSession();
        if (session && session.tokens && session.tokens.accessToken) {
          setIsAuthenticated(true);
          navigate('/');
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        // console.log("Error checking authentication:", error);
        setIsAuthenticated(false);
      }
    }
    checkAuthStatus();
  }, [navigate, setIsAuthenticated]);

  const passwordInstructions = [
    "Password must be more than 8 characters long",
    "Password must include numbers and one capital letter"
  ];

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setError('');
    setLoginStage('loading');
    try {
      await resetPassword({ username: email });
      setIsCodeSent(true);
      setLoginStage('success');
      setShowSuccessAlert(true);
      setTimeout(() => {
        setStage('reset');
        setShowSuccessAlert(false);
        setLoginStage('initial');
      }, 3000);
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setError(error instanceof AuthError ? error.message : 'An error occurred while requesting password reset. Please try again.');
      setLoginStage('initial');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setLoginStage('loading');
    try {
      await confirmResetPassword({ username: email, newPassword, confirmationCode: code });
      setLoginStage('success');
      setShowSuccessAlert(true);
      setIsPasswordReset(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
        setLoginStage('initial');
      }, 3000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error instanceof AuthError ? error.message : 'An error occurred while resetting password. Please try again.');
      setLoginStage('initial');
    }
  };

  return (
    // <div className="flex h-screen overflow-hidden">
    <div className="flex flex-col md:flex-row w-full">
      {/* Left side - Forgot Password form */}
      {/* <div className="w-1/2 bg-gray-100 flex items-center justify-center">
        <div className="flex flex-col items-center w-[364px]"> */}
        <div className="w-full md:w-1/2 bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center w-[22.75rem] gap-[3.75rem] flex-shrink-0">
          <div className="flex flex-col items-start gap-2.5 self-stretch">
            <h1 className="self-stretch text-[#000] font-inter text-[3.8125rem] font-extrabold leading-[120%]">
              {isPasswordReset ? 'Password Reset' : (stage === 'request' ? 'Forgot Password?' : 'Reset Password?')}
            </h1>
            <p className="self-stretch text-[#000] font-inter text-[1.25rem] font-normal leading-[120%]">
              {isPasswordReset 
                ? "Your password has been successfully reset."
                : (stage === 'request' 
                  ? "Please enter your email. We'll send you a password reset code to your email."
                  : "Please setup your new secured password to keep account protected")}
            </p>
          </div>
          
          {showSuccessAlert && (
            <Alert>
              {stage === 'request' 
                ? 'A 6 digit code was sent to the email you provided to reset password.'
                : 'Password updated! Your password is successfully updated. Please login again with new password.'}
            </Alert>
          )}
          
          {error && <Alert type="error">{error}</Alert>}

          {!isPasswordReset && (
          <form onSubmit={stage === 'request' ? handleRequestReset : handleResetPassword} className="w-full space-y-4 flex flex-col gap-[3.75rem]">
            <div>
            {stage === 'request' ? (
              <div className="flex flex-col items-start gap-5 self-stretch">
                <div className="flex flex-col items-start gap-7 self-stretch">
                  <div className={`flex items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white p-3.5 border border-solid  ${isEmailFocused ? 'border-black' : 'border-transparent'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M22 6L12 13L2 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <input
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setIsEmailFocused(true)}
                      onBlur={() => setIsEmailFocused(false)}
                      required
                      className={`w-full font-inter text-[16px] font-normal leading-[120%] focus:outline-none ${isEmailFocused ? 'placeholder-black' : 'placeholder-[#D9D9D9]'}`}
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        color: email ? 'black' : '#D9D9D9'
                      }}
                    />
                  </div>
                  
                </div>
              </div>
              
            ) : (
              <>
                <div className={`flex items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white p-3.5 border border-solid  ${isVerificationCodeFocused ? 'border-black' : 'border-transparent'}`}>
                  <Lock size={24} />
                  <input
                    type="text"
                    placeholder="Verification Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    onFocus={() => setIsVerificationCodeFocused(true)}
                    onBlur={() => setIsVerificationCodeFocused(false)}
                    required
                    className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: code ? 'black' : '#D9D9D9'
                    }}
                  />
                </div>
                <br />
                <div className={`flex items-center gap-3.5 self-stretch rounded-[0.875rem] bg-white p-3.5 border border-solid  ${isNewPasswordFocused ? 'border-black' : 'border-transparent'}`}>
                  <Lock size={24} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onFocus={() => setIsNewPasswordFocused(true)}
                    onBlur={() => setIsNewPasswordFocused(false)}
                    required
                    className="w-full text-[16px] font-normal leading-[120%] focus:outline-none"
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      color: newPassword ? 'black' : '#D9D9D9'
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none"
                  >
                    {showPassword ? <EyeOff size={24} /> : <Eye size={24} />}
                  </button>
                </div>
                <div className="bg-gray-200 p-3 rounded-md mt-4">
                  <h4 className="font-inter font-bold mb-2">Password instructions:</h4>
                  <ul className="list-disc pl-5 text-sm">
                    {passwordInstructions.map((instruction, index) => (
                      <li key={index}>{instruction}</li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            </div>
            
            <div className='flex flex-col items-end gap-5 self-stretch'>
              <button
                  type="submit"
                  className={`flex w-[22.6875rem] py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-[#EBAE12] text-white font-bold text-[16px] hover:bg-[#D49D10] transition duration-300 relative ${
                    loginStage !== 'initial' ? 'cursor-not-allowed' : ''
                  }`}
                  disabled={loginStage !== 'initial'}
                >
                  <span className="flex items-center justify-center">
                    {loginStage === 'success' ? (
                      <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    ) : (
                      stage === 'request' ? 'Send code' : 'Create new password'
                    )}
                    {loginStage === 'loading' && (
                  <svg className="animate-spin-fast ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <g>
                      <line x1="12" y1="2" x2="12" y2="6" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="12" y1="18" x2="12" y2="22" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="2" y1="12" x2="6" y2="12" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="18" y1="12" x2="22" y2="12" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                      <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                    </g>
                  </svg>
                )}
                  </span>
              </button>
              <button
                onClick={() => navigate('/signin')}
                className="flex w-[22.6875rem] py-[1.25rem] px-[0.625rem] justify-center items-center gap-2.5 rounded-[0.875rem] bg-white text-[#EBAE12] font-bold text-[16px] hover:bg-[#FDF7E7] transition duration-300 border border-[#EBAE12]"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Login
              </button>
            </div>
          </form>
          )}
          
          
        </div>
      </div>

      <div className="hidden md:flex md:w-1/2 bg-white justify-center items-center relative overflow-hidden">
        {/* Logo container */}
        <div className="flex w-[23rem] flex-col items-start gap-5 absolute left-[6.5rem] top-[5rem]">
          <img
            src="Frame8.png"
            alt="FINDRAISER Logo"
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Branding image container */}
        <div className="absolute bottom-0 right-0 w-[250px] h-[250px]">
          <img
            src="Branding.png"
            alt="FINDRAISER Branding"
            className="w-full h-full object-contain md:scale-90 sm:scale-75 lg:scale-100"
            style={{
              maxWidth: '100%',
              maxHeight: '100%'
            }}
          />
        </div>
      </div>
    </div>
  );
};


export default ForgotPassword;