//TEST CONFIGURATIONS(US-EAST2)
// export const awsExports = {
//   Auth: {
//     Cognito: {
//       region: "us-east-2",
//       userPoolId: "us-east-2_Z8QTB4p2b", 
//       userPoolClientId: "6lqmtvf4n85kr50mjethh8etl8", 
//       identityPoolId: "us-east-2:6d42f6ef-bac8-4862-a226-9a971f0dbd63",
//       signUpVerificationMethod: "code",
//     },
//   },
//   Lambda: {
//     userManagement: "fr-tst-tenantmgmt-001",
//     queryManagement: "fr-tst-fe-qm-001",
//   },
//   S3: {
//     accessKeyId: 'AKIA2EJNKTWOV7X2F4VC',
//     secretAccessKey: '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',
//     region: 'us-east-2',
//     bucket: 'fr-tst-s3-dex-001' 
//   },
//   API:{
//     apiurl: "https://d9mhngi9d8.execute-api.us-east-2.amazonaws.com/text2sql/text2sql"
//   }
// };


//PRODUCTION CONFIGURATION(US-EAST1)

export const awsExports = {
  Auth: {
    Cognito: {
      region: "us-east-1",
      userPoolId: "us-east-1_DJvIrwjw1",
      userPoolClientId: "4fbec049jl269rcjm48phnovt6",
      identityPoolId: "us-east-1:6de6cfa3-40b0-4861-9946-530d64decc0b",
      signUpVerificationMethod: "code",
    },
  },
  Lambda: {
    userManagement: "fr-prd-fe-tenantmgmt",
    //userManagement: "createTenant",
    queryManagement: "fr-prd-fe-qm-001",
  },
  S3: {
    accessKeyId: 'AKIA2EJNKTWOV7X2F4VC',
    secretAccessKey: '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',
    region: 'us-east-1',
    bucket: 'fr-prd-s3-dex-001'
  },
  API:{
    apiurl: "https://kjizki5vga.execute-api.us-east-1.amazonaws.com/text2sql/text2sql"
  }
};
