

//database connection using lambda
import React, { useState, useEffect } from 'react';
import { Users, ChevronRight, ChevronDown } from 'lucide-react';
import Sidebar_v2 from '../sidebar/Sidebar_v2';
import { useNavigate } from 'react-router-dom';
import useLambda from '../hooks/useLambda';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// import { logEventsState } from '../../store/atoms';
import AWS from 'aws-sdk';
import { awsExports } from '../../aws-exports';

// AWS Configuration
// AWS.config.update({
//   region: awsExports.Auth.Cognito.region,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: awsExports.Auth.Cognito.identityPoolId
//   })
// });

// const lambda = new AWS.Lambda();
//const LAMBDA_FUNCTION_NAME = 'fr-fe-qm-lbd-npd-001'; //createTenant

// Lambda invoker utility
const invokeLambda = async (action, payload, lambda, lambdaFunctionName) => {
  const params = {
    FunctionName: lambdaFunctionName,
    Payload: JSON.stringify({ action, payload })
  };

  try {
    const response = await lambda.invoke(params).promise();
    const result = JSON.parse(response.Payload);
    // console.log('result from the lambda is: '+JSON.stringify(result));
    if (!result.success) throw new Error(result.error);
    return result.data;
  } catch (error) {
    console.error(`Error invoking Lambda (${action}):`, error);
    throw error;
  }
};

const SavedQuery = ({ text, onClick }) => (
  <div 
    className="p-4 rounded shadow flex items-center justify-between transition-colors duration-200 mb-2 bg-white hover:bg-yellow-50 cursor-pointer"
    onClick={onClick}
  >
    <div className="flex items-center space-x-4">
      <div className="bg-yellow-100 p-2 rounded-full">
        <Users size={20} className="text-yellow-600" />
      </div>
      <span>{text}</span>
    </div>
  </div>
);

const ConfirmDialog = ({ isOpen, onClose, onConfirm, query, dontAskAgain, setDontAskAgain }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="text-center mb-4">
          <h2 className="text-xl font-semibold">Run Query</h2>
          <p className="mt-2">Do you want to run this query?</p>
        </div>
        <div className="flex items-center justify-center mb-6">
          <input
            type="checkbox"
            id="dontAskAgain"
            checked={dontAskAgain}
            onChange={(e) => setDontAskAgain(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="dontAskAgain" className="text-sm text-gray-500">
            Don't ask me again
          </label>
        </div>
        <div className="flex justify-center space-x-4">
          <button onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
            Cancel
          </button>
          <button onClick={() => onConfirm(query)} className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600">
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

const MySavedQueries = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [jwtToken, setJwtToken] = useState('');
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const [sub, setSub] = useState('');
  const [tenantGroup, setTenantGroup] = useState([]);
  const [savedQueries, setSavedQueries] = useState([]);
  const [userSavedQueries, setUserSavedQueries] = useState([]);
  const [loading, setLoading] = useState(true);
 // const [logEvents, setLogEvents] = useRecoilState(logEventsState);
  const [lambdaFunctionName, setLambdaFunctionName] = useState('fr-fe-qm-lbd-npd-001');

  const navigate = useNavigate();
  const { decodeToken, getJWT, configureLambda } = useLambda();

  // const logEvent = (event) => {
  //   setLogEvents((prevEvents) => [...prevEvents, { ...event, timestamp: new Date().toISOString() }]);
  // };

  const fetchRoles = async () => {
    try {
      const token = await getJWT();
      setJwtToken(token);
      await configureLambda(token);
      const decodedToken = decodeToken(token);
      const cognitoRoles = decodedToken['cognito:roles'];
      const subAttribute = decodedToken['sub'];
      if (cognitoRoles) {
        setIsSuperAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/superAdminRole'));
        setIsTenantAdmin(cognitoRoles.includes('arn:aws:iam::696416640413:role/tenantAdminRole'));
      }
      const tenantgroup = decodedToken['cognito:groups'];
      let filteredGroups = tenantgroup.includes("Tenants") 
        ? tenantgroup.filter(group => group !== 'Tenants')
        : [...tenantgroup];
      setTenantGroup(filteredGroups);
      setSub(subAttribute);
    } catch (error) {
      console.error('Failed to fetch roles:', error);
      //logEvent({ eventType: 'fetchRolesError', details: `Error fetching roles: ${error.message}` });
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchSavedQueries = async () => {
      if (!sub) return;
      try {
        const lambda = new AWS.Lambda();
        const data = await invokeLambda('getSavedQueries', { sub }, lambda, lambdaFunctionName);
        // console.log('data inside saved queries is: '+typeof data);
        // const transformedData = data.map((query) => ({
        //   text: query.query_name,
        // }));
        const transformedData = data.map((queryArray) => ({
            text: queryArray[0],  // Access the first element in each nested array
          }));
        // console.log('transformed data is: '+JSON.stringify(transformedData));
        setSavedQueries(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching saved queries:', error);
        setLoading(false);
      }
    };

    const fetchUserSavedQueries = async () => {
      if (!tenantGroup.length) return;
      try {
        const data = await invokeLambda('getUserSavedQueries', { groupname: tenantGroup[0] });
        // const transformedData = data.map((query) => ({
        //   text: query.query_name,
        // }));
        const transformedData = data.map((queryArray) => ({
            text: queryArray[0],  // Access the first element in each nested array
          }));
        setUserSavedQueries(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user saved queries:', error);
        setLoading(false);
      }
    };

    if (isSuperAdmin || isTenantAdmin) {
      fetchSavedQueries();
    } else if (tenantGroup.length > 0) {
      fetchUserSavedQueries();
    }
  }, [sub, isSuperAdmin, isTenantAdmin, tenantGroup]);

  useEffect(() => {
    const savedDontAskAgain = localStorage.getItem('dontAskAgain');
    if (savedDontAskAgain) {
      setDontAskAgain(JSON.parse(savedDontAskAgain));
    }
  }, []);

  const handleQueryClick = (query) => {
    if (dontAskAgain) {
      localStorage.setItem('selectedQuery', query.text);
      navigate('/');
    } else {
      setCurrentQuery(query);
      setOpenDialog(true);
    }
  };

  const handleConfirm = (query) => {
    localStorage.setItem('selectedQuery', query.text);
    setOpenDialog(false);
    navigate('/');
  };

  useEffect(() => {
    localStorage.setItem('dontAskAgain', JSON.stringify(dontAskAgain));
  }, [dontAskAgain]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const themeStyles = {
    backgroundColor: '#FFF',
    color: '#000',
    minHeight: '100vh',
    display: "flex",
    width: "100%"
  };

  return (
    <div style={themeStyles}>
      <Sidebar_v2 />
      <div className='max-w-full w-full' style={{margin: "0 auto"}}>
        <div className="flex h-screen bg-gray-100">
          <div className="flex-1 overflow-auto p-8">
            <h1 className="text-2xl font-semibold mb-6">My Saved Queries</h1>
            {loading ? (
              <div className="flex flex-col items-center justify-center h-full">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <p className="text-lg font-medium">Loading queries...</p>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow">
                <div
                  className="flex items-center p-4 cursor-pointer"
                  onClick={toggleExpand}
                >
                  {isExpanded ? (
                    <ChevronDown className="mr-2" />
                  ) : (
                    <ChevronRight className="mr-2" />
                  )}
                  <span className="font-semibold">Available Queries</span>
                </div>
                {isExpanded && (
                  <div className="max-h-96 overflow-y-auto p-4">
                    {(isSuperAdmin || isTenantAdmin ? savedQueries : userSavedQueries).length > 0 ? (
                      (isSuperAdmin || isTenantAdmin ? savedQueries : userSavedQueries).map((query, index) => (
                        <SavedQuery
                          key={index}
                          text={query.text}
                          onClick={() => handleQueryClick(query)}
                        />
                      ))
                    ) : (
                      <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center justify-center text-center">
                        <p className="text-lg font-medium mb-4">
                          {isSuperAdmin || isTenantAdmin
                            ? "No saved queries, save them in the Query Management page."
                            : "No saved queries. Contact your tenant admin."}
                        </p>
                        {(isSuperAdmin || isTenantAdmin) && (
                          <Link to="/queryManagement" className="text-blue-500 hover:text-blue-700 font-semibold">
                            Go to Query Management
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          
          <ConfirmDialog
            isOpen={openDialog}
            onClose={() => setOpenDialog(false)}
            onConfirm={handleConfirm}
            query={currentQuery}
            dontAskAgain={dontAskAgain}
            setDontAskAgain={setDontAskAgain}
          />
        </div>
      </div>
    </div>
  );
};

export default MySavedQueries;